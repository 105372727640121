/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../hooks';
import {
  Section,
  Carousel,
  Loading,
  HomeCard,
  ContinueWatchingSection,
  FeaturedList,
  ChannelPlayer,
  Metadata
} from '..';
import analytic, { analyticTypes } from '../../service/analytic';

const HOME_CARD_INDEX = 3;

const metadata = {
  Home: {
    description:
      'Tonton online streaming anime HD dengan subtitle Indonesia dan Inggris di Sushiroll.'
  },
  Movie: {
    description:
      'Saksikan film anime terbaru dengan subtitle Indonesia di Sushiroll.'
  },
  Series: {
    description:
      'Nikmati serial anime musim terbaru dengan subtitle Indonesia di Sushiroll.'
  }
};

const MediaPageView = ({ data }) => {
  const navigate = useNavigate();
  const isHomePage = useMemo(() => data && data.friendlyId === 'home', [data]);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    data && analytic(analyticTypes.trackPage, data.titleLocalized, { user });
  }, [data, user]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  if (!data || !data.items) {
    return <Loading height='80vh' />;
  }

  const carouselData = data.items[0];
  const homeCardIndex = isHomePage ? HOME_CARD_INDEX + 1 : HOME_CARD_INDEX;
  const homeCardData = data.items[homeCardIndex];
  const topSectionsData = data.items.slice(
    isHomePage ? 2 : 1, // if home page, 1 is featured list
    homeCardIndex
  );
  const botSectionsData = data.items.slice(homeCardIndex + 1);
  const featuredListData = isHomePage && data.items[1];

  const handleSelectChannel = (item) => {
    item && navigate(`/live/${item.id}`);
  };

  const dismissPlayer = () => {
    setOpenPlayer(false);
    setOpenIframe(false);
    return navigate(-1);
  };

  function SectionRails({ item, topSection, itemType }) {
    return (
      <Section
        id={item.id}
        name={item.titleLocalized}
        sectionData={item.items}
        topSection={topSection}
        onChannelSelected={handleSelectChannel}
        itemType={itemType}
      />
    );
  }
  return (
    <>
      <Metadata
        name='Sushiroll'
        title='Sushiroll - Streaming Anime Online Subtitle Indonesia Terbaik'
        description={data && metadata[data?.titleLocalized]?.description}
        url={window.location.href}
      />
      <div>
        <Carousel
          data={carouselData}
          onChannelSelected={handleSelectChannel}
          isHomePage={isHomePage}
        />
        {featuredListData &&
          (isMobile ? (
            <SectionRails item={featuredListData} itemType='featured-list' />
          ) : (
            <FeaturedList
              id={featuredListData.id}
              data={featuredListData.items}
            />
          ))}
        {isHomePage && <ContinueWatchingSection />}
        {topSectionsData.map((item, index) => {
          const topSection = index === 0 && !isHomePage;
          return (
            <SectionRails item={item} topSection={topSection} key={index} />
          );
        })}
        <HomeCard
          data={homeCardData && homeCardData.items && homeCardData.items[0]}
          isMobile={isMobile}
        />
        {botSectionsData.map((item, index) => {
          return <SectionRails item={item} key={index} />;
        })}
        <ChannelPlayer
          openPlayer={openPlayer}
          openIframe={openIframe}
          dismissPlayer={dismissPlayer}
        />
      </div>
    </>
  );
};

export default withRouter(MediaPageView);
