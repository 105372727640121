import React from 'react';
import rewindIcon from '../../../assets/rewind_icon.svg';

function RewindButton() {
  return <img className='vjs-custom-icon' src={rewindIcon} alt='Rewind' />;
}

RewindButton.handleClick = (player) => {
  const seekTime = player.currentTime() - 15;
  player.currentTime(seekTime);
};

export default RewindButton;
