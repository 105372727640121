import React from 'react';
import { useLocation } from 'react-router-dom';
import ItemDetail from '../MediaDetail/ItemDetail';

import styles from './CollectionItem.module.css';
import LiveTVCollectionItem from './LiveTVCollectionItem';
import { detectAgent } from '../../utils';

function CollectionItem({ data, empty, onChannelClick, isInteractive }) {
  const location = useLocation();
  const thumbnail =
    data && data.attachments && data.attachments.thumbnail_portrait
      ? `url(${data.attachments.thumbnail_portrait.fileUrl})`
      : 'white';
  const style = data && {
    background: `${thumbnail} no-repeat center center`,
    backgroundSize: 'cover'
  };
  const isMobile = detectAgent('mobile');
  // eslint-disable-next-line react/no-unstable-nested-components
  function MediaItem({ item }) {
    return (
      <div
        title={item.titleLocalized || item.nameLocalized}
        className={styles['item-image']}
        style={style}
      />
    );
  }
  return (
    <div
      className={`${styles['collection-item']} ${empty ? styles['empty'] : ''}`}
    >
      {!empty && (
        <ItemDetail
          item={data}
          onChannelSelected={onChannelClick}
          component={
            location.pathname === '/live' && isMobile && !isInteractive
              ? LiveTVCollectionItem
              : MediaItem
          }
        />
      )}
    </div>
  );
}

export default CollectionItem;
