import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addComment } from '../../redux/actions/interactive';

import styles from './CommentInput.module.css';

const emojis = [
  {
    label: 'Thumbs up',
    code: 0x1f44d
  },
  {
    label: 'High five',
    code: 0x1f64f
  },
  {
    label: 'Love',
    code: 0x2764
  },
  {
    label: 'Huging face',
    code: 0x1f917
  },
  {
    label: 'Wafing hand',
    code: 0x1f44b
  },
  {
    label: 'Face with hand over mouth',
    code: 0x1f92d
  },
  {
    label: 'Squinting face',
    code: 0x1f61d
  },
  {
    label: 'Face savoring food',
    code: 0x1f60b
  },
  {
    label: 'Love-you gesture',
    code: 0x1f91f
  },
  {
    label: 'Clapping hands',
    code: 0x1f44f
  },
  {
    label: 'Raising hands',
    code: 0x1f64c
  },
  {
    label: 'Handshake',
    code: 0x1f91d
  }
];

function CommentInput({ socket }) {
  const comment = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [showIcon, setShowIcon] = useState(false);

  const sendComment = (e) => {
    if (e.keyCode === 13) {
      comment.current.value &&
        dispatch(addComment(socket, comment.current.value));
      comment.current.value = null;
      setShowIcon(false);
    }
  };

  const sendIcon = useCallback(
    (code) => {
      dispatch(addComment(socket, code));
    },
    [dispatch, socket]
  );

  return (
    <div className={styles['commentInput']}>
      {showIcon && (
        <div className={styles['emotInput']}>
          <div className={styles['emotList']}>
            {emojis.map((emoji, key) => {
              const code = String.fromCodePoint(emoji.code);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <span onClick={() => sendIcon(code)} key={key}>
                  {code}
                </span>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles['commentForm']}>
        {token ? (
          <>
            <span
              className={styles['emotToggle']}
              onClick={() => setShowIcon(!showIcon)}
            />
            <input
              type='text'
              ref={comment}
              placeholder='Post a comment ....'
              onKeyUp={sendComment}
            />
          </>
        ) : (
          <h4>Please login to comment..</h4>
        )}
      </div>
    </div>
  );
}

export default CommentInput;
