import { combineReducers } from 'redux';
import auth from './auth';
import page from './page';
import playlist from './playlist';
import media from './media';
import search from './search';
import channel from './channel';
import history from './history';
import interactive from './interactive';
import packageGroups from './packageGroup';
import appSettings from './settings';
import notification from './notification';
import campaign from './campaign';
import pack from './pack';
import promo from './promo';
import myList from './myList';

export default combineReducers({
  auth,
  page,
  playlist,
  media,
  channel,
  search,
  history,
  interactive,
  packageGroups,
  appSettings,
  notification,
  campaign,
  pack,
  promo,
  myList
});
