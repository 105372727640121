/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import styles from './TextTruncated.module.css';

function TextTruncated({ content, maxLength, readMore = true }) {
  const [showMore, setShowMore] = useState(
    content && content.length > maxLength
  );
  const hideReadMore = () => {
    setShowMore(false);
  };

  if (!content) return '';
  return (
    <>
      {showMore ? (
        <div>
          {`${content.substring(0, maxLength)}... `}
          {readMore && (
            <span className={styles['read-more']} onClick={hideReadMore}>
              Read More
            </span>
          )}
        </div>
      ) : (
        <div>{content}</div>
      )}
    </>
  );
}

export default TextTruncated;
