/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { InView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import styles from './PlaylistPageView.module.css';
import Loading from '../Loading/Loading';
import CollectionItem from '../CollectionItem/CollectionItem';
import Slider from '../Slider/Slider';
import { detectAgent } from '../../utils';

function PlaylistViewContent({
  data,
  title,
  searchView,
  allowSeeAll,
  handleViewAll,
  completed,
  fetchNextData,
  handleSelectChannel,
  maxItemsPerRow,
  noDataMessage
}) {
  const isInteractive = data?.[0]?.interactive;
  const isMobile = detectAgent('mobile');
  const location = useLocation();
  const generateDataItems = () => {
    const results = [];

    data.forEach((item, key) => {
      results.push(
        <CollectionItem
          onChannelClick={handleSelectChannel}
          index={item.id}
          key={key}
          data={item}
          isInteractive={item.interactive}
        />
      );
    });

    for (let i = 1; i <= maxItemsPerRow - (data.length % maxItemsPerRow); i++) {
      results.push(<CollectionItem empty key={`empty_${i}`} />);
    }

    return results;
  };
  return (
    <>
      <div className={styles['title-wrapper']}>
        <h1>{title}</h1>
        {!!searchView && allowSeeAll && <p onClick={handleViewAll}>See all</p>}
      </div>

      <div className={styles['collection']}>
        {isMobile && (
          <div className={styles['section-container']}>
            <div className={styles['section-info']}>
              <div className={styles['section-name']}>{title}</div>
            </div>
            {location.pathname === '/live' &&
            data &&
            data.length &&
            !isInteractive ? (
              <Slider
                hasArrows
                slidesToShow='auto'
                slidesToScroll='auto'
                skipTrack
                exactWidth
                itemWidth={130}
                /* fetchMore={fetchMore}
            fetchComplete={fetchComplete} */
                sliderId='slider-section-live-tv'
                itemType='live-tv'
              >
                {' '}
                {generateDataItems()}
              </Slider>
            ) : (
              <div
                className={styles['list-container']}
                style={{ marginTop: '10px' }}
              >
                {generateDataItems()}
              </div>
            )}
            {!searchView && completed && !data.length && (
              <div className={styles['no-data']}>
                {noDataMessage || 'No data'}
              </div>
            )}
          </div>
        )}
        {!isMobile && (
          <div className={styles['list-container']}>{generateDataItems()}</div>
        )}
        {!isMobile && !searchView && completed && !data.length && (
          <div className={styles['no-data']}>{noDataMessage || 'No data'}</div>
        )}
        {!searchView && !completed && (
          <div className={styles['loader-wrapper']}>
            <InView
              onChange={(inView) => {
                inView && data.length && fetchNextData();
              }}
            >
              <Loading height='100px' />
            </InView>
          </div>
        )}
      </div>
    </>
  );
}

export default PlaylistViewContent;
