import React from 'react';
import styles from './Button.module.css';

const generateButtonColor = (color) => {
  switch (color) {
    case 'primary':
      return '#F49832';
    case 'light':
      return '#FFF';
    case 'dark':
      return '#F5F5F5';
    case 'sushiroll':
      return '#F7CC45';
    default:
      return color || '#F49832';
  }
};

const generateTextColor = (color) => {
  switch (color) {
    case 'primary':
      return '#FFF';
    case 'light':
      return '#FFF';
    case 'dark':
      return '#545454';
    default:
      return color || '#000';
  }
};

function Button({
  onClick,
  onSubmit,
  children,
  className,
  icon,
  iconSize,
  iconStyle,
  bgColor,
  width,
  textColor,
  outlined,
  fontSize,
  type = 'primary', // primary, outlined, text
  size, // default, small, large
  shape = 'default',
  textWrap,
  style,
  onHover = 'darken',
  disabled = false,
  buttonType = 'button', // button, submit
  textStyle,
  ...otherButtonProps
}) {
  const background = bgColor || '#F49832';
  const customStyle = {
    width: width || 'initial',
    backgroundColor: generateButtonColor(bgColor),
    color: disabled ? '#A9A9A9' : generateTextColor(textColor), // Greyed out when disabled
    border: `1px solid ${background}`,
    fontSize: fontSize || 14,
    ...style
  };

  const handleClick = (e) => {
    if (disabled) return;

    onClick && onClick(e);
  };

  const generateClassName = () => {
    let result = `${styles['button']} ${className || ''} `;

    if (icon) {
      result += `${styles['icon']} `;
    }

    if (size === 'small') {
      result += `${styles['small']} `;
    }
    if (size === 'large') {
      result += `${styles['large']} `;
    }

    if (type === 'primary') {
      result += `${styles['primary']} `;
    }
    if (type === 'outlined' || outlined) {
      result += `${styles['outlined']} `;
    }
    if (type === 'text') {
      result += `${styles['text']} `;
    }

    if (shape === 'default') {
      result += `${styles['default-shape']} `;
    }
    if (shape === 'rounded') {
      result += `${styles['round-shape']} `;
    }

    if (onHover === 'darken' && !disabled) {
      result += `${styles['darken-hover']} `;
    }
    if (onHover === 'scale' && !disabled) {
      result += `${styles['scale-hover']} `;
    }

    return result;
  };

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      className={generateClassName()}
      onClick={handleClick}
      style={customStyle}
      data-disabled={disabled}
      disabled={disabled}
      {...otherButtonProps}
    >
      {!!icon &&
        (iconStyle ? (
          <div style={iconStyle}>
            <img src={icon} alt='icon' width={iconSize} />
          </div>
        ) : (
          <img
            src={icon}
            alt='icon'
            width={iconSize}
            style={{ marginRight: '0.5rem' }}
          />
        ))}
      <span
        className={styles['text']}
        style={{
          ...(textWrap
            ? {
                display: '-webkit-box',
                whiteSpace: 'wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: textWrap?.maxLine,
                WebkitBoxOrient: 'vertical'
              }
            : {
                whiteSpace: 'nowrap'
              }),
          ...textStyle
        }}
      >
        {children}
      </span>
      {disabled && <div className={styles['disabled']} />}
    </button>
  );
}

export default Button;
