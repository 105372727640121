const initialState = {
  paymentGatewayPackages: [],
  loading: false,
  err: null
};

export default function PackageReducer(state = initialState, action) {
  const { type, payload, err } = action;
  switch (type) {
    case 'GET_PAYMENT_GATEWAY_PACKAGES':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS':
      return {
        ...state,
        paymentGatewayPackages: payload,
        loading: false,
        err: null
      };

    case 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        err: null
      };

    case 'GET_PAYMENT_GATEWAY_PACKAGES_ERROR':
      return {
        ...state,
        loading: false,
        err
      };
    default:
      return state;
  }
}
