import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

const URL_PLAYLIST = '/catalog/v1.1/playlist/';

export const getPlaylistItemsConfig =
  (id, currentPage) => (dispatch, getState) => {
    const state = getState();

    return {
      url: `${
        BASE_URL + URL_PLAYLIST + id
      }/items?locale=en&page=${currentPage}`,
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    };
  };

export const getPlaylistDetailItems =
  // eslint-disable-next-line consistent-return
  (id, titleLocalized, page) => async (dispatch, getState) => {
    const state = getState();
    const { loading, dataCategoryPlaylist } = state.playlist;

    if (loading) {
      return null;
    }

    dispatch({
      type: 'GET_PLAYLIST_DETAIL',
      page
    });

    try {
      let res;
      const categoryTitle = titleLocalized?.toLowerCase();
      if (page === 1) {
        if (dataCategoryPlaylist?.[categoryTitle]?.data) {
          return dispatch({
            type: 'GET_PLAYLIST_DETAIL_SUCCESS_FROM_STATE',
            payload: dataCategoryPlaylist?.[categoryTitle]
          });
        }
        const [playlistDetail, playlistItem] = await Promise.all([
          axios.get(`${BASE_URL + URL_PLAYLIST + id}?page=${page}&locale=en`, {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }),
          axios.get(
            `${BASE_URL + URL_PLAYLIST + id}/items?page=${page}&locale=en`,
            {
              headers: {
                Authorization: state.auth.token,
                'Cloudfront-JWT-AppId': JWT_APP_ID
              }
            }
          )
        ]);

        playlistDetail.data.data.items = playlistItem.data.data;
        playlistDetail.data.meta = playlistItem.data.meta;
        res = playlistDetail;
      } else {
        res = await axios.get(
          `${BASE_URL + URL_PLAYLIST + id}/items?page=${page}&locale=en`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }
        );
      }
      dispatch({
        type: 'GET_PLAYLIST_DETAIL_SUCCESS',
        payload: {
          ...res.data
        },
        page
      });
      return res;
    } catch (err) {
      dispatch({
        type: 'GET_PLAYLIST_DETAIL_ERROR',
        err
      });
    }
  };

export const getPlaylist =
  (id, page = 1) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_PLAYLIST_DETAIL',
      page
    });

    try {
      const res = await axios.get(
        `${BASE_URL + URL_PLAYLIST + id}?page=${page}&locale=en`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_PLAYLIST_DETAIL_SUCCESS',
        payload: {
          ...res.data
        },
        page
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_PLAYLIST_DETAIL_ERROR',
        err: errorData
      });
    }
  };

export const fetchPlaylist = (id) => async (dispatch, getState) => {
  const state = getState();

  const res = await axios.get(`${BASE_URL + URL_PLAYLIST + id}?locale=en`, {
    headers: {
      Authorization: state.auth.token,
      'Cloudfront-JWT-AppId': JWT_APP_ID
    }
  });
  return res.data;
};

export const getPlaylistItems =
  (id, currentPage, perPage) => async (dispatch, getState) => {
    const state = getState();
    let url = `${BASE_URL + URL_PLAYLIST + id}/items?locale=en`;
    if (currentPage) {
      url += `&page=${currentPage}&perPage=${perPage}`;
    }

    const res = await axios.get(url, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    return res.data;
  };

export const resetPlaylistDetail = () => (dispatch) => {
  dispatch({
    type: 'RESET_PLAYLIST_DETAIL'
  });
};
