import { generateDeviceInfo } from '../utils';

const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

const formatObject = (object, customKeyName) => {
  const newData = {};

  for (const key in object) {
    if (object[key] && key !== 'topicArn') {
      const newKeyName = customKeyName
        ? `${customKeyName}${capitalizeWord(key)}`
        : key;

      if (object[key] instanceof Array || object[key] instanceof Object) {
        newData[newKeyName] = JSON.stringify(object[key]);
      } else {
        newData[newKeyName] = object[key];
      }
    }
  }

  return newData;
};

const generateUserData = (user) => {
  const deviceInfo = generateDeviceInfo();
  const formatDeviceInfo = formatObject(deviceInfo, 'device');
  const userData = {
    ...formatDeviceInfo
  };

  if (user) {
    const { account, profile, telco } = user;

    if (telco) {
      userData.telcoOperator = telco.msisdn;
      userData.telcoMsisdn = telco.operator;
    }

    Object.assign(userData, formatObject(account, 'account'));
    Object.assign(userData, formatObject(profile, 'profile'));
  }

  return userData;
};

export const clevertapEvent = (name, options = { params: {}, user: null }) => {
  const { params, user } = options;
  const userData = generateUserData(user);
  const time = new Date().getTime();
  const property = formatObject(params);

  const payload = {
    ...property,
    ...userData,
    time
  };

  // console.log(name, payload)

  window.clevertap.event.push(name, payload);
};

export const clevertapScreenView = (screenName, user) => {
  const userData = generateUserData(user);
  const time = new Date().getTime();
  const payload = {
    ...userData,
    screenName,
    time
  };

  // console.log(screenName, payload)

  window.clevertap.event.push('screenViews', payload);
};

export const clevertapUserLogin = (user) => {
  const { account } = user;
  const { profile } = user;

  const userInfo = {
    UserID: account.userId,
    ProfileID: profile.id,
    Name: profile.name,
    Photo: profile.avatar,
    // 'DOB': new Date().getTime(),
    Diamond: account.diamonds,
    Gencash: account.gencash
  };

  if (account.email) userInfo.Email = account.email;
  if (account.username) userInfo.Username = account.username;
  if (account.mobile) userInfo.Phone = account.mobile;
  if (account.subscription)
    userInfo.Subscription = JSON.stringify(account.subscription);
  if (account.subscriptions && account.subscriptions.length)
    userInfo.Subscriptions = JSON.stringify(account.subscriptions);

  const userLocation = localStorage.getItem('location');
  if (userLocation) userInfo.Location = userLocation;

  window.clevertap.onUserLogin.push({
    Site: userInfo
  });
};
