import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import phoneCodes from '../../../config/phoneCodes';
import { useOutsideHandle } from '../../../hooks';
import styles from './StartAddOn.module.css';

import downArrow from '../../../assets/down-arrow.svg';
import { replacePhoneCountryCode } from '../../../utils';

function StartAddOn({ startAddOn, inputValue, onChange }, ref) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(startAddOn?.defaultValue || '+62');
  const dropDownRef = useRef();

  const handleClickOutsideOption = (target) => {
    const optionEl = dropDownRef.current;
    if (
      optionEl?.parentElement?.contains(target) &&
      target.nodeName !== 'LABEL'
    ) {
      return;
    }
    if (showDropDown) {
      setShowDropDown(!showDropDown);
    }
  };
  useOutsideHandle(dropDownRef, handleClickOutsideOption);

  const handleSelectCountryCode = useCallback(
    (code) => {
      setSelected(code);
      onChange &&
        onChange((val) => {
          return code + replacePhoneCountryCode(val);
        });
    },
    [onChange]
  );

  useEffect(() => {
    if (!inputValue?.startsWith('+')) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputValue = `+${inputValue}`;
    } // append input value if not start with +, autoselect for overseas code without 8
    const findPhoneCode = phoneCodes.find(({ code }) =>
      inputValue?.startsWith(code)
    );
    if (findPhoneCode?.code) {
      setSelected(findPhoneCode?.code);
    }
  }, [inputValue]);

  useLayoutEffect(() => {
    handleSelectCountryCode(selected);
  }, [selected, handleSelectCountryCode]);

  useLayoutEffect(() => {
    const parentEl = dropDownRef.current;
    const dropdown = dropDownRef.current?.getElementsByClassName(
      `.${styles['addonDropdown']}`
    )?.[0];
    const dropdownlist = dropDownRef.current?.getElementsByClassName(
      `.${styles['dropDownList']}`
    )?.[0];

    if (dropdown) {
      dropdown.style.top = `${parentEl.offsetHeight + 1}px`;

      const selectedTop = document.querySelector(
        '[data-selected="true"]'
      ).offsetTop;
      dropdownlist.scrollTop = selectedTop - 50;
    }
  }, [showDropDown, selected]);

  useImperativeHandle(
    ref,
    () => ({
      selected
    }),
    [selected]
  );

  if (startAddOn instanceof Object) {
    if (startAddOn?.type === 'phone-code') {
      return (
        <div
          ref={dropDownRef}
          className={styles['addonOption']}
          onClick={() => setShowDropDown(!showDropDown)}
        >
          <span>{selected}</span>
          <img src={downArrow} alt='down-arrow' />
          {showDropDown && (
            <div className={styles['addonDropdown']}>
              <div className={styles['dropDownList']}>
                {phoneCodes.map(({ country, code }, i) => {
                  return (
                    <span
                      key={i}
                      data-selected={code === selected}
                      onClick={() => handleSelectCountryCode(code)}
                    >
                      <span>{country}</span>
                      <span>{code}</span>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  return startAddOn;
}

export default forwardRef(StartAddOn);
