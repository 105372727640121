/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import plusIcn from '../../assets/btn-plus-circle.svg';
import checkIcn from '../../assets/ic-check-circle.svg';

function AddToListButton({ onAddList, onRemoveList }) {
  const addedToMyList = useSelector(
    (state) => state.media?.detailData?.addedToMyList
  );

  return (
    <>
      {addedToMyList ? (
        <img
          src={checkIcn}
          alt='remove from list'
          style={{ cursor: 'pointer' }}
          onClick={() => onRemoveList()}
        />
      ) : (
        <img
          src={plusIcn}
          alt='add to list'
          style={{ cursor: 'pointer' }}
          onClick={() => onAddList()}
        />
      )}
    </>
  );
}

export default AddToListButton;
