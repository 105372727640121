/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TextTruncated from '../TextTruncated/TextTruncated';
import {
  getDetailPoster,
  generateGenres,
  generateCasts,
  formatDuration,
  detectAgent,
  generateErrorDetail
} from '../../utils';
import styles from './MediaInfo.module.css';
import {
  addToMyList,
  getListStatus,
  removeFromMyList
} from '../../redux/actions/myList';
import AddToListButton from './AddToListButton';
import shareIcn from '../../assets/ic-share-circle.svg';
import { authPopup } from '../../redux/actions/auth';
import { showToastNotif } from '../../redux/actions/notification';
import ShareModal from '../ShareModal/ShareModal';

function MediaInfo({ detail, access, onWatchClicked }) {
  const poster = getDetailPoster(detail);
  const duration = detail.duration && formatDuration(detail.duration);
  const { tags } = detail;
  const genres = generateGenres(tags);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const location = useLocation();

  const [showShareModal, setShowShareModal] = useState(false);

  const casts = generateCasts(tags);
  const subscribed =
    !access || access.error ? styles['not-subscribed-button'] : '';

  const isMobile = detectAgent('mobile');

  useEffect(() => {
    if (userToken && detail.id) {
      dispatch(getListStatus(detail.id, detail.mediaType));
    }
  }, [detail.id, detail.mediaType, dispatch, userToken]); // check if it's in get list already

  const addToList = useCallback(() => {
    const doAddList = async () => {
      if (userToken) {
        try {
          await dispatch(addToMyList(detail.id, detail.type));
          return dispatch(showToastNotif('success', 'Added to My List.'));
        } catch (err) {
          const errorData = generateErrorDetail(err);
          return dispatch(showToastNotif('error', 'Error adding to My list.'));
        }
      } else {
        dispatch(authPopup.show());
      }
    };
    doAddList();
  }, [detail.id, detail.type, dispatch, userToken]);
  // fire 'add to list, if not logged in, show popup login/register

  const removeFromList = useCallback(() => {
    const doRemoveList = async () => {
      try {
        await dispatch(removeFromMyList(detail.id));
        return dispatch(showToastNotif('success', 'Removed from My List.'));
      } catch (err) {
        const errorData = generateErrorDetail(err);
        return dispatch(
          showToastNotif('error', 'Error removing from My list.')
        );
      }
    };
    doRemoveList();
  }, [detail.id, dispatch]);
  return (
    <div>
      <div className={styles['media-info-wrapper']}>
        <div className={styles['general-info-wrapper']}>
          {!isMobile && <div className={styles['gradient-top']} />}
          <img
            src={poster}
            alt='detail poster'
            className={styles['thumbnail']}
          />
          <div className={styles['media-metadata-wrapper']}>
            <div className={styles['media-title']}>{detail.titleLocalized}</div>
            <div className={styles['media-metadata']}>
              {!!detail.year && (
                <span className={styles['year']}>{detail.year}</span>
              )}
              {!!genres && <span className={styles['genre']}>{genres}</span>}
              {!!duration && (
                <span className={styles['duration']}>{duration}</span>
              )}
              {!!detail?.rating && (
                <span className={styles['rating']}>{detail?.rating}</span>
              )}
            </div>
            <div className={styles['btn-wrapper']}>
              <button
                type='button'
                className={subscribed}
                onClick={onWatchClicked}
              >
                Watch Now
              </button>
              <div className={styles['wrapper-btn-circle']}>
                {detail.id && !location.pathname.includes('episode') && (
                  <>
                    <AddToListButton
                      addedToMyList={detail.addedToMyList}
                      onAddList={addToList}
                      onRemoveList={removeFromList}
                    />

                    <img
                      src={shareIcn}
                      alt='share'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowShareModal(!showShareModal)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {!isMobile && <div className={styles['gradient-bottom']} />}
        </div>

        <div className={styles['description']}>
          <TextTruncated
            content={detail.descriptionLongLocalized || ''}
            maxLength={250}
          />
        </div>
        {casts && (
          <div className={styles['casting']}>
            <h3>Cast</h3>
            <span>{casts}</span>
          </div>
        )}
      </div>
      <ShareModal
        onCloseModal={() => setShowShareModal(!showShareModal)}
        type={detail.type}
        id={detail.id}
        showShareModal={showShareModal}
        open={showShareModal}
      />
    </div>
  );
}

export default MediaInfo;
