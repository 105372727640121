import React from 'react';
import { social } from '../../constants';

const privacyPolicyList = {
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'Sushiroll Privacy Policy',
    contentList: [
      {
        contents: [
          'This privacy policy sets out how PT Festival Digital Nusantara (“Sushiroll”), as the manager of Sushiroll web and application (“App”), collects, stores, uses, processes, transfers, discloses and protects User data and information (”Sushiroll Privacy Policy”). Sushiroll Privacy Policy shall apply for all use of services that are available in the App, including in the form of mobile applications, software and other forms, that are used by an App User that is registered and verified by Sushiroll (hereinafter referred to as the “User”). The User must read, comprehend, and approve Sushiroll Privacy Policy as the User data will be used as a basis for Sushiroll to provide higher quality services for the User.',
          'In the event that the User does not permit processing of its data or information as set out under this Sushiroll Privacy Policy, Sushiroll does not recommend the User to use App services.'
        ]
      },
      {
        title: '1. DATA COLLECTION',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              title:
                'User Data are collected by Sushiroll in several ways, namely:',
              items: [
                'Through forms or applications by a potential User. Data collected at this stage are personal identities of the potential User that consist of, among others, full name, valid phone number, email address, and profile picture',
                {
                  list: {
                    order: 'ol',
                    type: 'a',
                    title: 'User activities such as:',
                    items: [
                      'What content does the User watch? when? how long?',
                      'Does the content get casted to other device?',
                      'What screen does the User open? when?',
                      'What keyword does the User search? when?',
                      'What method does the User use to login and register? when?',
                      'Which playlist does the User open? when?',
                      'Which content does the User share? when?',
                      'Which subscription does the User select and purchase? when?',
                      'What voucher does the User use? when?',
                      'Bookmark or save activities, and other activities conducted by the User on the App'
                    ]
                  }
                },
                'Location data delivered by the system when reading App usages at a certain location or area;',
                'Technical data obtained when User is using the App. Such technical data include Internet Protocol (IP) address, website information, duration of App usages or website visits, gadget when using the internet, advertisements, and other relevant matters;',
                'Interaction data with our customer service;',
                'Other data collection mechanism performed by Sushiroll (including via the App);',
                'Other sources in cooperation with Sushiroll, where the User has permitted the transfer of User data.'
              ]
            }
          },
          'Before a User may use the App and services provided by Sushiroll, the User shall firstly be requested to approve this Privacy Policy (including on the collection of personal data as mentioned above). Specifically on personal data collection via local data access and system data that are in the User gadget, Sushiroll shall request prior approval before having access to the same, including access to photos, voices, cameras, and other features of gadgets used by the User.'
        ]
      },
      {
        title: '2. USE OF DATA',
        contents: [
          {
            list: {
              order: 'ol',
              title:
                'Sushiroll is entitled to use Users data once the User agrees to Sushiroll Privacy Policy Data collected by Sushiroll shall be used for the following matters:',
              items: [
                'To identify the User, activation or non activation of User account, and other matters in connection with the User account.',
                'To register and grant access to the User data, to parties in cooperation with Sushiroll in providing products offered on the app (Vendor), to register the User on the system provided by the Vendor.',
                'To ease communication between Sushiroll and the User via and for the usage of the App;',
                'To provide updates on the latest information, whether the App systems, App features, change in any policies whether required by Sushiroll or the government that are affecting the App, and other information that may be shown or delivered to the User;',
                'To process and respond questions and feedbacks from the User;',
                'To update, maintain, develop, and personalize the App to conform with User preferences;',
                'To monitor, supervise and search activities, genre and/or category preference and in using the services provided by the App;',
                'To offer vouchers, rewards, bonuses, surveys, promotions, and advertisements to the User.'
              ]
            }
          }
        ]
      },
      {
        title: '3. DATA SHARING',
        contents: [
          {
            list: {
              order: 'ul',
              title:
                'Distribution or transfer of data performed by Sushiroll shall only be for the purpose of using the App and to support the services and features offered by Sushiroll on the App. Sushiroll shall only distribute or transfer data in the event that the User has granted an approval to Sushiroll to perform the same. Sushiroll shall only provide User data and information to the following parties:',
              items: [
                'A Party in cooperation with Sushiroll to provide services on the App (“Vendor”), and',
                'Government agencies and officials, law enforcers, being the performance by Sushiroll of its statutory obligations to make periodical reports and to submit a report on certain requirement.'
              ]
            }
          },
          'Sushiroll shall not sell or lease User data and information to third parties. Sushiroll also has a policy that Sushiroll shall only provide data to the foregoing parties to the extent that such data and information are requested by it. Sushiroll shall use reasonable endeavors to reject any request for User data and information that are deemed to be irrelevant with the requests from the foregoing parties.'
        ]
      },
      {
        title: '4. DATA STORAGE',
        contents:
          'User data and information on the App shall be stored as long as the same are being used for the purpose of data collection, and as long as the same is governed by the applicable laws and regulations. Sushiroll shall cease to store any User data and information immediately in the event we are of the view that the data we collect no longer in accordance with the objective of data collection, and the storage of such data is no longer required for business purposes and is not permitted or required by the applicable laws.'
      },
      {
        title: '5. DATA AMENDMENT AND ACCESS',
        contents: [
          'Subject to the provisions of the applicable laws, the User may request to Sushiroll, and Sushiroll may also grant access to the User, to amend personal data and information other than information of their phone number & email address, by firstly contacting the customer service. Prior to granting access to the User, Sushiroll is entitled to seek the underlying reasons for such change of User personal data and information.',
          'Sushiroll is entitled to reject any User request to access, or correct, any or all User personal information that we hold or control as may be permitted or required by the applicable laws. This may include circumstances where such personal information may contain references to other individuals or where the request for such access or correction are deemed by us to be trivial, outrageous, inappropriate, and unreasonable.',
          'The User may contact us via contacts as set out under point 10 of this Privacy Policy.'
        ]
      },
      {
        title: '6. DATA DELETION',
        contents: [
          'To the extent permitted by the applicable laws, the User is entitled to request us to delete User personal data that are stored in Sushiroll systems. Upon the User request, we will attempt to delete the User’s personal data from our system, unless permitted or required otherwise by the applicable laws. Sushiroll shall also cease to collect, use, or disclose User personal data, unless permitted or required by the applicable laws. Please note that by requesting us to delete the User’s personal data, we may not be able to continue providing our services to the User.',
          'In relation to this Data Deletion, the User may contact us via contacts as set out under point 10 of this Privacy Policy.'
        ]
      },
      {
        title: '7. DATA SECURITY',
        contents: [
          'Confidentiality of User data and information is the utmost importance of Sushiroll. Sushiroll shall use all reasonable endeavours to protect and secure User data and information from any access, collection, usage, or disclosure by an authorized person and against any process in violation of the laws, unintentional losses, destruction and damages or any similar risks. However, it should be known that transmission of information through the Internet is not completely secured and prone of hacking risks. While we will do our best in protecting User data and information, the User acknowledges that Sushiroll may not be able to guarantee the integrity and accuracy of any data and information that the User sent through the Internet, or guarantee that such User data and information will not be intercepted, accessed, disclosed, modified, or destroyed by an authorized third party, due to reasons outside of Sushiroll control. Sushiroll shall be responsible to maintain the confidentiality of the User account and the User shall not share the details of the User account, including the password and the One Time Password (OTP), to any person and the User shall also secure and fully responsible over the security of the device being used by the User.',
          'In implementing the App, Sushiroll has also complied with the applicable laws in relation to the storage of data in an advanced Data Centre and Data Recovery Centre in accordance with the provisions of the laws and regulations, as well as has equipped safety systems on the App that are in accordance with the laws and regulations.'
        ]
      },
      {
        title: '8. AMENDMENTS TO THE PRIVACY POLICY',
        contents:
          'Sushiroll, in our own discretion, from time to time will perform evaluations to ensure that this Sushiroll Privacy Policy is consistent with the current development and development of the applicable laws. Sushiroll shall notify to the User of any amendment to Sushiroll Privacy Policy, via public news that may be seen and accessed by the User on the App.  The User agrees that it is the personal responsibility of the User to read and review any amendment to Sushiroll Privacy Policy. In the event of any questions in relation to the amendment of this privacy policy, the User may contact Sushiroll customer service via the App or to the contact available on the App and this Privacy Policy.'
      },
      {
        title: '9. APPROVAL AND ACKNOWLEDGEMENT',
        contents: [
          'By using this platform, the User acknowledges that the User has read and understood this Privacy Policy and the User has agreed to all terms of Sushiroll Privacy Policy. Specifically, the User agrees and permits any actions undertaken by Sushiroll in accordance with Sushiroll Privacy Policy. Continuous use of this platform following a notice on amendment to this policy shall be deemed your acceptance of such amendment.',
          'The User may withdraw its approval for any or all User data and information collection, use, or disclosure or distribution at any time by providing reasonable written notification to us via contact details as set out below. The User may also withdraw its approval to us to send certain communication and information to the User via “opt-out” or “unsubscribe” facilities that are available in our message to You. Subject to the circumstances and nature of the approval that the User withdraws, the User shall understand that acknowledge that upon such withdrawal of approval, the User may no longer be able to use the App as well as the services attached thereto. Withdrawal of approval by the User may result in discontinuation of User account or User contractual relationship with us and all Sushiroll Affiliates, with prejudice to any outstanding rights and obligations. Upon receiving User notice to withdraw the approval to collect, use or disclose or distribute User data and information, we will notify the User of any consequences of such withdrawal so that the User may decide such intention to withdraw.'
        ]
      },
      {
        title: '10. CONTACT US',
        contents: [
          'Should the User have any questions in relation to the User Privacy Policy or should the User intend to gain any access of its personal data and information, or in connection with the withdrawal (in the event that opt-out” or “unsubscribe” facilities is not available),  please contact the following:',
          {
            contentUrl: {
              label: 'Email',
              url: (
                <a target='_top' href={`mailto:${social.SUSHIROLL_EMAIL}`}>
                  {social.SUSHIROLL_EMAIL}
                </a>
              )
            }
          }
        ]
      }
    ]
  }
};

export default privacyPolicyList;
