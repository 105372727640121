import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import appStoreBtn from '../../assets/btn-app-store.svg';
import ggPlayBtn from '../../assets/btn-gg-play.svg';
import icFb from '../../assets/ic-facebook.svg';
import icInsta from '../../assets/ic-insta.svg';
import icTwitter from '../../assets/ic-twitter.svg';
import { appURL, social } from '../../constants';
import logo from '../../assets/sushiroll_logo.svg';
import { detectAgent } from '../../utils';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(detectAgent('mobile'));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const token = useSelector((state) => state.auth.token);

  const menuSection = (
    <>
      <div className={styles['grow1']}>
        <Link to='/about-us'>About Us</Link>
      </div>

      <div className={styles['grow1']}>
        <Link to='/faqs'>FAQs</Link>
      </div>
      {token && (
        <div className={styles['grow1']}>
          <Link to='/subscription-history/buy'>Subscribe</Link>
        </div>
      )}
      <div className={styles['grow1']}>
        <Link to='/form'>Contact Us</Link>
      </div>
      <div className={styles['grow1']}>
        <Link to='/promo'>Promo</Link>
      </div>
    </>
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // Attach event listener on component mount
    window.addEventListener('resize', handleResize);

    // Detach event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // The empty dependency array ensures that the effect runs only on mount and unmount

  useEffect(() => {
    setIsMobile(windowWidth < 960);
  }, [windowWidth, windowHeight]);

  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footerContent']}>
        <div className={styles['footer']}>
          <section className={styles['layout']}>
            <div className={!isMobile && styles['grow1']}>
              <Link to='/'>
                <img src={logo} alt='logo' className={styles['logo']} />
              </Link>
            </div>
            {!isMobile && (
              <div className={styles['grow1']}>
                <section className={styles['layout']}>{menuSection}</section>
              </div>
            )}
            <div className={!isMobile && styles['grow1']}>
              <div className={styles['iconsWrapper']}>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.SUSHIROLL_INSTAGRAM}
                >
                  <img src={icInsta} alt='icon-instagram' />
                </a>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.SUSHIROLL_FACEBOOK}
                >
                  <img src={icFb} alt='icon-facebook' />
                </a>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.SUSHIROLL_TWITTER}
                >
                  <img src={icTwitter} alt='icon-twitter' />
                </a>
              </div>
            </div>
          </section>
          {isMobile && (
            <section className={styles['layout']}>
              <div className={styles['grow1']}>
                <div className={styles['menu']}>{menuSection}</div>
              </div>
            </section>
          )}
          <section className={styles['layout']} style={{ marginTop: '30px' }}>
            <div className={styles['grow1']}>
              <div className={styles['head']}>Download Sushiroll App</div>
              <Link to={appURL.APP_STORE} target='_blank'>
                <img alt='app-store-button' src={appStoreBtn} />
              </Link>
              <Link to={appURL.GOOGLE_PLAY} target='_blank'>
                <img alt='google-play-button' src={ggPlayBtn} />
              </Link>
            </div>
          </section>
          <section className={styles['copyrightEmail']}>
            Copyright © {new Date().getFullYear()} Sushiroll All Rights
            Reserved {isMobile ? <br /> : ' | '}
            <Link to='/terms' style={{ paddingInline: '1rem' }}>
              {' '}
              Terms and Conditions{' '}
            </Link>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </section>
          <section className={styles['appVersion']}>
            Application Version 1.0.0
          </section>
        </div>
      </div>
    </div>
  );
};

export default Footer;
