const gcpPaths = [
  'user',
  'loyalty',
  'screen',
  'recommendation',
  'catalog',
  'subscription',
  'heartbeat'
];

export default gcpPaths;
