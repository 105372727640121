import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import getPages from '../../redux/actions/page';
import { getAuthInfo, logout } from '../../redux/actions/auth';

const AppWrapper = (props) => {
  const dispatch = useDispatch();
  const logoutWhenClearStorage = useCallback(
    (e) => {
      if (e.key === 'gfToken' && e.oldValue && !e.newValue) {
        dispatch(logout());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', logoutWhenClearStorage);

    return () => window.removeEventListener('storage', logoutWhenClearStorage);
  }, [logoutWhenClearStorage]);

  useEffect(() => {
    dispatch(getAuthInfo());
    dispatch(getPages());
  }, [dispatch]);

  return props.children;
};

export default AppWrapper;
