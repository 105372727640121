import React, { useLayoutEffect, useRef } from 'react';
import Glider from 'glider-js';

import 'glider-js/glider.min.css';
import styles from './Slider.module.css';

function Slider({ sliderId, fetchComplete, fetchMore, itemType, ...props }) {
  const glider = useRef();
  const containerRef = useRef();
  const wrapperRef = useRef();

  /* enable if want to support load more feature */
  /* --start--
  const handleSlideVisible = useCallback(
    async e => {
      const nextBtn = containerRef.current.children[2]
      const currentNextBtnClassName = nextBtn.className
      if (currentNextBtnClassName.includes('disabled')) {
        if (!fetchComplete) {
          await fetchMore()
          nextBtn.className = currentNextBtnClassName.replace('disabled', '')
        }
      }
    },
    [fetchMore, fetchComplete]
  ) 

  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return
    }

    const addEventListener = (event, fn) => {
      if (typeof fn === 'function' && wrapperRef.current) {
        wrapperRef.current.addEventListener(event, fn)
      }
    }

    addEventListener('glider-slide-visible', handleSlideVisible)

    const refs = wrapperRef.current
    return () => {
      const removeEventListener = (event, fn) => {
        if (typeof fn === 'function' && refs) {
          refs.removeEventListener(event, fn)
        }
      }

      removeEventListener('glider-slide-visible', handleSlideVisible)
    }
  }, [handleSlideVisible])
  --end-- */

  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    glider.current = new Glider(wrapperRef.current, {
      ...props,
      arrows: props.hasArrows
        ? {
            next: `.glider-next.${sliderId}`,
            prev: `.glider-prev.${sliderId}`
          }
        : undefined,
      dots: props.hasDots ? '#dots' : undefined
    });

    glider.current.refresh(true);
  }, [props, sliderId]);

  useLayoutEffect(() => {
    return () => {
      if (glider && glider.current) {
        glider.current.destroy();
      }
    };
  }, []);

  return (
    <div className='glider-contain' ref={containerRef}>
      {props.hasArrows && (
        <button
          type='button'
          className={`glider-prev ${sliderId} ${
            itemType === 'live-tv' && 'small-button'
          }`}
          id='glider-prev'
        >
          {props.iconLeft || '❮'}
        </button>
      )}
      <div className={props.className} ref={wrapperRef}>
        <div
          className={`glider-track ${styles['gliderItem']}  ${
            itemType === 'live-tv' && styles['gliderItemTV']
          } ${itemType}`}
        >
          {props.children}
        </div>
      </div>
      {props.hasDots && <div id='dots' className='glider-dots' />}
      {props.hasArrows && (
        <button
          type='button'
          className={`glider-next ${sliderId} ${
            itemType === 'live-tv' && 'small-button'
          }`}
          id='glider-next'
        >
          {props.iconRight || '❯'}
        </button>
      )}
    </div>
  );
}

export default Slider;
