import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import styles from './FeaturedList.module.css';

function FeaturedList({ data }) {
  return (
    <div className={styles['wrapper']}>
      {data.map((item) => {
        const thumbnail = get(item, 'attachments.thumbnail_portrait.fileUrl');

        return (
          <Link
            to={{ pathname: `/playlists/${item.id}`, state: item }}
            key={item.id}
            className={styles['featuredItem']}
          >
            <div className={styles['imgWrapper']}>
              {!!thumbnail && <img alt={item.id} src={thumbnail} />}
            </div>
            <div className={styles['featureName']}>{item.titleLocalized}</div>
          </Link>
        );
      })}
    </div>
  );
}

export default FeaturedList;
