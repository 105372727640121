import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './redux/store';
import NetworkService from './network-service';
import { isFirebaseMessagingSupported } from './utils';
import firebaseConfig from './service/firebaseConfig';
import { messaging } from './service/firebase';

const store = configureStore();
NetworkService.setupInterceptors(store);

if ('serviceWorker' in navigator && isFirebaseMessagingSupported()) {
  const queries = [];

  // eslint-disable-next-line guard-for-in
  for (const prop in firebaseConfig) {
    queries.push(`${prop}=${firebaseConfig[prop]}`);
  }

  navigator.serviceWorker
    .register(`./firebase-messaging-sw.js?${queries.join('&')}`)
    // eslint-disable-next-line func-names
    .then(function (registration) {
      messaging.useServiceWorker(registration);
    })
    // eslint-disable-next-line func-names
    .catch(function (err) {
      return err;
    });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
