import React from 'react';
import { Link } from 'react-router-dom';

function ItemDetail({
  item,
  component: Component,
  onChannelSelected,
  preloadDetail,
  clearPreLoadDetail,
  isMobile
}) {
  switch (item.type) {
    case 'webview':
    case 'stream':
      return (
        <div onClick={() => onChannelSelected(item)}>
          <Component item={item} />
        </div>
      );
    case 'featured':
      return (
        <Link
          onMouseEnter={preloadDetail && preloadDetail(item)}
          onMouseLeave={clearPreLoadDetail}
          key={item.id}
          to={`/playlists/${item.id}`}
          style={{ minWidth: isMobile ? '10px' : '15px' }}
        >
          <Component item={item} isMobile={isMobile} />
        </Link>
      );
    case 'category':
      return (
        <Link
          onMouseEnter={preloadDetail && preloadDetail(item)}
          onMouseLeave={clearPreLoadDetail}
          key={item.id}
          to={`/playlists/${item.id}`}
          style={isMobile && { minWidth: '10px' }}
        >
          <Component item={item} isMobile={isMobile} />
        </Link>
      );
    default:
      return (
        <Link
          onMouseEnter={preloadDetail && preloadDetail(item)}
          onMouseLeave={clearPreLoadDetail}
          key={item.id}
          to={`/${item.type}/${item.id}`}
          style={isMobile && { minWidth: '105px' }}
        >
          <Component item={item} isMobile={isMobile} />
        </Link>
      );
  }
}

export default ItemDetail;
