/* eslint-disable import/extensions */
import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "home" */ './home'));

const History = lazy(
  () => import(/* webpackChunkName: "history" */ './history')
);

const TransactionHistory = lazy(
  () =>
    import(/* webpackChunkName: "transaction_history" */ './transactionHistory')
);

const SubscriptionHistory = lazy(
  () =>
    import(
      /* webpackChunkName: "subscription_history" */ './subscriptionHistory'
    )
);

const Login = lazy(() => import(/* webpackChunkName: "login" */ './login'));
const EmailLogin = lazy(
  () => import(/* webpackChunkName: "email_login" */ './login/email')
);
const PhoneLogin = lazy(
  () => import(/* webpackChunkName: "phone_login" */ './login/phone')
);
const ProfileRoutes = lazy(
  () => import(/* webpackChunkName: "user_profile" */ './profile')
);
const Register = lazy(
  () => import(/* webpackChunkName: "register" */ './register')
);
const EmailRegister = lazy(
  () => import(/* webpackChunkName: "email_register" */ './register/email')
);
const PhoneRegister = lazy(
  () => import(/* webpackChunkName: "phone_register" */ './register/phone')
);
const CampaignDetail = lazy(
  () => import(/* webpackChunkName: "phone_register" */ './campaign/index')
);

const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgot_password" */ './forgot-password')
);

const ResetPassword = lazy(
  () => import(/* webpackChunkName: "reset_password" */ './reset-password')
);

const Playlist = lazy(
  () => import(/* webpackChunkName: "playlist" */ './playlist')
);
const MediaPageWrapper = lazy(
  () =>
    import(/* webpackChunkName: "media_page_wrapper" */ './mediaPageWrapper')
);
const Detail = lazy(() => import(/* webpackChunkName: "detail" */ './detail'));

const Subscription = lazy(
  () => import(/* webpackChunkName: "subscription" */ './subscription')
);
const Voucher = lazy(
  () => import(/* webpackChunkName: "voucher" */ './voucher')
);
const Search = lazy(() => import(/* webpackChunkName: "search" */ './search'));
const Payment = lazy(
  () => import(/* webpackChunkName: "payment" */ './payment')
);
const PaymentComplete = lazy(
  () => import(/* webpackChunkName: "payment-complete" */ './payment-complete')
);
const AboutUs = lazy(
  () => import(/* webpackChunkName: "about_us" */ './about-us')
);
const Terms = lazy(() => import(/* webpackChunkName: "terms" */ './terms'));
const PrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "terms" */ './privacyPolicy')
);
const Faqs = lazy(() => import(/* webpackChunkName: "faqs" */ './faqs'));
const ContactUs = lazy(
  () => import(/* webpackChunkName: "contact_us" */ './contact-us')
);
const EmailVerification = lazy(
  () =>
    import(/* webpackChunkName: "email_verification" */ './email-verification')
);
const LiveTV = lazy(() => import(/* webpackChunkName: "live_tv" */ './live'));
const Stream = lazy(() => import(/* webpackChunkName: "stream" */ './stream'));
const NotFound = lazy(
  () => import(/* webpackChunkName: "not_found" */ './not-found')
);
const MaintenancePage = lazy(
  () => import(/* webpackChunkName: "maintenance" */ './maintenance')
);
const UserLicenseAgreement = lazy(
  () =>
    import(
      /* webpackChunkName: "user_license_agreement" */ './userLicenseAgreement'
    )
);
const Promo = lazy(() => import(/* webpackChunkName: "promo" */ './promo'));

const Privacy = lazy(
  () => import(/* webpackChunkName: "privacy" */ './privacy')
);

const PromoDetail = lazy(
  () => import(/* webpackChunkName: "promo" */ './promo/PromoDetail')
);

const FormPage = lazy(() => import(/* webpackChunkName: "promo" */ './form'));

const MyList = lazy(() => import(/* webpackChunkName: "myList" */ './myList'));
export { default as AppWrapper } from './appWrapper';
export {
  Home,
  History,
  Login,
  EmailLogin,
  PhoneLogin,
  Register,
  EmailRegister,
  PhoneRegister,
  MediaPageWrapper,
  Playlist,
  Detail,
  Subscription,
  Voucher,
  Search,
  Payment,
  PaymentComplete,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AboutUs,
  Terms,
  ProfileRoutes,
  PrivacyPolicy,
  Privacy,
  Faqs,
  ContactUs,
  EmailVerification,
  LiveTV,
  Stream,
  MaintenancePage,
  UserLicenseAgreement,
  CampaignDetail,
  TransactionHistory,
  SubscriptionHistory,
  Promo,
  PromoDetail,
  MyList,
  FormPage
};
