import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '..';
import styles from './UserConsent.module.css';
import ConsentCheckbox from './ConsentCheckbox';
import { postConsent } from '../../redux/actions/auth';

function UserConsent() {
  const dispatch = useDispatch();
  const consentSelection = useSelector((state) => state.auth.consentSelection);

  const handleClickConsent = () => {
    dispatch(postConsent());
  };

  return (
    <div className={`${styles['container']} ${styles['containerDefault']}`}>
      <h2>Selamat Datang di Sushiroll</h2>
      <h3>
        Sushiroll meminta persetujuan Anda untuk menggunakan data pribadi Anda
        untuk:
      </h3>
      <div className={styles['buttonWrapperDefault']}>
        <ConsentCheckbox language='id' />
        <Button
          onClick={() => {
            handleClickConsent();
          }}
          shape='rounded'
          size='large'
          width='100%'
          disabled={consentSelection === null}
          bgColor='sushiroll'
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default UserConsent;
