export const formatOptions = {
  MAIN_MENU: 'Main Menu',
  FONT_SIZE: 'Font Size',
  FONT_COLOR: 'Font Color',
  FONT_EDGES: 'Font Edges',
  BG_TRANSPARANCY: 'Background Transparancy'
};

export const Menu = [
  {
    name: formatOptions.FONT_SIZE,
    data: ['50%', '75%', '100%', '150%', '200%'],
    key: 'fontSize'
  },
  {
    name: formatOptions.FONT_COLOR,
    data: ['White', 'Yellow'],
    key: 'fontColor'
  },
  {
    name: formatOptions.FONT_EDGES,
    data: ['Shadow', 'Border'],
    key: 'fontEdges'
  },
  {
    name: formatOptions.BG_TRANSPARANCY,
    data: ['0%', '50%', '75%', '100%'],
    key: 'bgTransparancy'
  },

  {
    name: 'Reset'
  }
];

export const fontSizes = {
  '50%': {
    fontSize: '14px',
    lineHeight: '21px'
  },
  '75%': {
    fontSize: '21px',
    lineHeight: '32px'
  },
  '100%': {
    fontSize: '28px',
    lineHeight: '42px'
  },
  '150%': {
    fontSize: '42px',
    lineHeight: '63px'
  },
  '200%': {
    fontSize: '56px',
    lineHeight: '84px'
  }
};
