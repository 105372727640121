import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomeCard.module.css';
import TextTruncated from '../TextTruncated/TextTruncated';
import {
  formatDuration,
  generateGenres,
  getDetailPoster,
  generateDetailUrl
} from '../../utils';

function HomeCard({ data, isMobile }) {
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const style = {
    background: `url(${getDetailPoster(data)}) no-repeat center / cover`
  };
  const { rating } = data;
  const { year } = data;
  const genres = generateGenres(data.tags);
  const { duration } = data;
  const handleClick = () => {
    navigate(generateDetailUrl(data));
  };

  if (!isMobile) {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['cardTitle']}>Available Now</div>
        <div className={styles['homeCard']}>
          <div className={styles['pictureWrapper']}>
            <div
              className={styles['picture']}
              style={style}
              onClick={handleClick}
            />
          </div>

          <div className={styles['info']}>
            <div className={styles['subtitle']}>New In Movies</div>
            <div className={styles['title']}>{data.titleLocalized}</div>
            <div className={styles['brief']}>
              {!!year && <span>{year}</span>}
              {!!genres && <span>{genres}</span>}
              {!!duration && <span>{formatDuration(duration)}</span>}
              {!!rating && <span className='rating'>{rating}</span>}
            </div>
            <div className={styles['description']}>
              <TextTruncated
                content={data.descriptionLongLocalized}
                maxLength={250}
              />
            </div>
            <button
              type='button'
              className={styles['btn']}
              onClick={handleClick}
            >
              <span>Watch Now</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      key={data.id}
      className='carousel-wrapper'
      onClick={handleClick}
      style={{ padding: '10px' }}
    >
      <div className='carousel-item' style={style}>
        <div className='content'>
          <div className='watch'>Watch Now</div>
          <div className='title'>{data.titleLocalized}</div>
          <div className='description'>
            {!!year && <span>{year}</span>}
            {!!genres && <span>{genres}</span>}
            {!!duration && <span>{formatDuration(data.duration)}</span>}
            {!!rating && <span className='rating'>{rating}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCard;
