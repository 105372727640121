import React from 'react';
import { SimpleImg as LazyImage } from 'react-simple-img';
import { detectAgent, getThumbnail } from '../../utils';
import { SLIDER_ITEM_THUMBNAIL } from '../../config/Thumbnail';
import styles from './Item.module.css';

function ItemComponent({ item }) {
  const thumbnail = getThumbnail(item, SLIDER_ITEM_THUMBNAIL);
  const isMobile = detectAgent('mobile');
  return (
    <div className={styles['slider-item-wrapper']}>
      <div className={styles['slider-item']} title={item.titleLocalized}>
        <LazyImage
          src={thumbnail}
          height={isMobile ? 135 : 220}
          alt='thumbnail'
          className='lazy-slider-item-img'
          placeholder='#DCDCDC'
        />
      </div>
    </div>
  );
}

export default ItemComponent;
