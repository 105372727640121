import { formatObject, generateUserData } from '../utils';

export const initFbPixelUserData = async (user) => {
  const userData = generateUserData(user, {
    separateSensitiveData: true
  });

  if (window?.fbq) {
    await window.fbq(
      'init',
      process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      userData?.sensitiveData
    );
  }
};

const fbPixel = (event, options = { params: {}, user: null }) => {
  const { params = {}, user = null } = options;
  const userData = generateUserData(user, { separateSensitiveData: true });
  const time = new Date().getTime();
  const property = formatObject(params);

  const payload = {
    ...userData?.regularData,
    ...property,
    time
  };

  window.fbq('trackCustom', event, payload);
};

export default fbPixel;
