/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

export const getPaymentGatewayPackages = () => async (dispatch, getState) => {
  const state = getState();
  const { user } = state.auth;
  const memberId = user && user.account.id;
  const { paymentGatewayPackages } = state.packageGroups;

  dispatch({
    type: 'GET_PAYMENT_GATEWAY_PACKAGES'
  });

  try {
    if (paymentGatewayPackages?.data?.length > 1) {
      dispatch({
        type: 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS_FROM_STATE'
      });
    } else {
      const res = await axios.get(
        `${BASE_URL}/subscription/v1.1/paymentGateways/getSubscriberPlan?accountId=${memberId}`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS',
        payload: res.data.data
      });
    }
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PAYMENT_GATEWAY_PACKAGES_ERROR',
      err: errorData
    });
  }
};
