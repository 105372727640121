import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutAllDevice } from '../../redux/actions/auth';
import Button from '../Button/Button';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

import styles from './ValidateSessionNotification.module.css';

function ValidateSessionNotification({ open }) {
  const dispatch = useDispatch();

  const handleLogoutAllDevice = () => {
    dispatch(logoutAllDevice());
  };

  return (
    <GeneralNotifModal open={open}>
      <div className={styles['modalContainer']}>
        <div className={styles['header']}>Login Error</div>
        <p>Oops, something went wrong. Please try again later.</p>
        <Button
          style={{
            marginTop: '1rem',
            marginLeft: 'auto',
            padding: '1rem',
            fontWeight: 'bold',
            fontSize: 16,
            backgroundColor: '#F49832'
          }}
          type='outlined'
          textColor='#F49832'
          onClick={handleLogoutAllDevice}
        >
          Logout All Device
        </Button>
      </div>
    </GeneralNotifModal>
  );
}

export default ValidateSessionNotification;
