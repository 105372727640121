import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

export default function configureStore(initialState, reducer = rootReducer) {
  const middlewares = [thunk];
  const isDevelopment = process.env.NODE_ENV === 'development';
  const finalCreateStore = applyMiddleware(...middlewares)(createStore);
  const store = finalCreateStore(
    reducer,
    initialState,
    isDevelopment
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      : compose
  );

  return store;
}
