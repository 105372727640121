import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContinueWatching } from '../../redux/actions/history';
import Section from '../Section/Section';

function ContinueWatchingSection() {
  const dispatch = useDispatch();
  const continueWatchingData = useSelector(
    (state) => state.history.continueWatching
  );
  const authState = useSelector((state) => state.auth);
  const { token } = authState;
  useEffect(() => {
    if (token) {
      dispatch(getContinueWatching());
    }
  }, [dispatch, token]);

  if (!continueWatchingData || !continueWatchingData.length) return null;
  return (
    <Section
      name='Continue Watching'
      sectionData={continueWatchingData.filter(
        (el) => el.id !== '00000000-0000-0000-0000-000000000000'
      )}
      allowSeeAll={Boolean(true)}
      topSection
      id='continue-watching'
    />
  );
}

export default ContinueWatchingSection;
