import React from 'react';
import styles from './LoginDialog.module.css';

function TNCFooter({ onClick, dialogstate, type }) {
  const buttonText =
    // eslint-disable-next-line no-nested-ternary
    type
      ? 'Agree'
      : dialogstate?.toLowerCase() === 'login'
        ? 'Login'
        : 'Register';

  return (
    <div className={`${styles['footerWrapper']}`}>
      By clicking {buttonText}, you're accepting the <br />
      <span
        className={styles['link']}
        onClick={() => {
          onClick('ugc');
        }}
        role='button'
        tabIndex='0'
      >
        Terms & Conditions
      </span>
      {' and '}
      <span
        className={styles['link']}
        onClick={() => {
          onClick('privacy');
        }}
        role='button'
        tabIndex='0'
      >
        Privacy Policy
      </span>
      .
    </div>
  );
}

export default TNCFooter;
