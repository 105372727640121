import React from 'react';
import fastForwardIcon from '../../../assets/fast_forward_icon.svg';

function FastForwardButton() {
  return (
    <img className='vjs-custom-icon' src={fastForwardIcon} alt='Fast Forward' />
  );
}

FastForwardButton.handleClick = (player) => {
  const seekTime = player.currentTime() + 15;
  player.currentTime(seekTime);
};

export default FastForwardButton;
