import React from 'react';
import { Link } from 'react-router-dom';

import styles from './tab.module.css';

function ProfileNavigation({ links, activeTab, onTabChange, activeStyle }) {
  return (
    <div className={styles['navWrapper']}>
      <ul className={styles['navigator']}>
        {links.map((link, key) => {
          return (
            <li key={key} className={activeTab === key ? activeStyle : ''}>
              {link.link && (
                <Link to={`${link.link}?tab=${key}`}>
                  <span>{link.label}</span>
                </Link>
              )}
              {!link.link && (
                <div onClick={() => onTabChange(key)}>
                  <span>{link.label}</span>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ProfileNavigation;
