import React from 'react';
import styles from './generalNotifModal.module.css';

const GeneralNotifModal = ({
  children,
  open,
  className = '',
  contentClass = ''
}) => {
  if (!open) {
    return null;
  }

  return (
    <div
      className={`${styles['modal']} ${className} ${
        open ? styles['show'] : ''
      }`}
    >
      <section className={styles['modalWrapper']}>
        <div className={`${styles['modalContent']} ${contentClass}`}>
          {children}
        </div>
      </section>
    </div>
  );
};

export default GeneralNotifModal;
