import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { getCampaignCarousel } from './campaign';

// eslint-disable-next-line no-unused-vars
const getPages = () => async (dispatch, getState) => {
  // const state = getState()
  const response = await axios.get(`${BASE_URL}/catalog/v1.1/pages?locale=en`, {
    headers: {
      // Authorization: state.auth.token,
      'Cloudfront-JWT-AppId': JWT_APP_ID
    }
  });

  const pageData = response.data.data;
  const formatPageData = pageData.map((data) => ({
    ...data,
    friendlyId: data.friendlyId.toLowerCase()
  }));
  dispatch({
    type: 'GET_PAGES_SUCCESS',
    payload: formatPageData
  });
  dispatch(getCampaignCarousel());
};

export default getPages;
