import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';
import styles from './Header.module.css';
import icSeries from '../../assets/ic-series.svg';
import icMovie from '../../assets/ic-movie.svg';
import icLive from '../../assets/ic-live.svg';
import icHome from '../../assets/ic-home.svg';

function BottomBar() {
  const location = useLocation();
  const { authPopupStatus } = useSelector((state) => state?.auth);
  const menu = [
    {
      name: 'Home',
      path: '/',
      icon: icHome
    },
    {
      name: 'Series',
      path: '/series',
      icon: icSeries
    },
    {
      name: 'Movies',
      path: '/movie',
      icon: icMovie
    },
    {
      name: 'Live',
      path: '/live',
      icon: icLive
    }
  ];
  return (
    !authPopupStatus && (
      <div className={`${styles['bottomBar']} show-mobile`}>
        {menu?.map((item, index) => {
          const selected = location?.pathname === item.path;
          return (
            <div className={styles['menuIcon']} key={index}>
              <img
                className={selected ? styles['selected'] : ''}
                src={item.icon}
                alt={item.name}
              />
              <div className={selected ? styles['selected'] : ''}>
                <Link to={item.path}>{item.name}</Link>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
}

export default BottomBar;
