import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  AboutUs,
  Terms,
  Faqs,
  ContactUs,
  PrivacyPolicy,
  UserLicenseAgreement,
  FormPage
} from '../pages';

export default function MobileRoutes() {
  return (
    <Routes>
      <Route key='/contact-us' path='/contact-us' element={<ContactUs />} />
      <Route key='/faqs' path='/faqs' element={<Faqs />} />
      <Route key='/about-us' path='/about-us' element={<AboutUs />} />
      <Route
        key='/privacy-policy'
        path='/privacy-policy'
        element={<PrivacyPolicy />}
      />
      <Route
        key='/end-user-license-agreement'
        path='/end-user-license-agreement'
        element={<UserLicenseAgreement />}
      />
      <Route key='/terms' path='/terms' element={<Terms />} />
      <Route key='/form' path='/form' element={<FormPage />} />
      <Route
        key='/mobile'
        path='/*'
        element={<Navigate to='/mobile/terms' />}
      />
    </Routes>
  );
}
