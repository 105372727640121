const initialState = {
  data: null,
  meta: null,
  error: null,
  loading: false,
  dataComplete: false,
  dataMustUpdate: false
};

export default function myListReducer(state = initialState, action) {
  const { type, payload, page } = action;
  switch (type) {
    case 'GET_MY_LIST_PROCESS':
      return {
        ...state,
        loading: true,
        error: null
      };

    case 'GET_MY_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page,
        dataMustUpdate: false
      };

    case 'GET_MY_LIST_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload.data,
        meta: payload.meta,
        page,
        dataMustUpdate: false
      };

    // get my list from state after complete
    case 'GET_MY_LIST_COMPLETE':
      return {
        ...state,
        loading: false,
        dataComplete: true,
        dataMustUpdate: false
      };

    case 'RESET_MY_LIST_STATE':
      return {
        ...initialState
      };

    case 'GET_MY_LIST_FAILED':
      return {
        ...state,
        loading: false,
        error: payload ?? true
      };

    case 'UPDATE_MY_LIST':
      return {
        ...state,
        dataMustUpdate: true
      };

    default:
      return state;
  }
}
