const initialState = {
  user: JSON.parse(localStorage.getItem('gfUser')),
  token: localStorage.getItem('gfToken'),
  refreshToken: localStorage.getItem('gfRefreshToken'),
  err: null,
  loading: false,
  success: false,
  sessionStatus: localStorage.getItem('sessionStatus') || null,
  whatsappLink: null,
  authPopupStatus: false,
  authDialogConfigs: {
    showClose: true,
    state: null,
    type: null,
    showFooter: true,
    onCloseCallback: null
  },
  showConsent: false,
  consentSelection: null,
  consentStatus: null
};

export default function authReducer(state = initialState, action) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(action);
  }
  const { payload, type, err, data, configs = {} } = action;

  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'SHOW_AUTH_POPUP':
      return {
        ...state,
        authPopupStatus: true,
        authDialogConfigs: { ...state.authDialogConfigs, ...configs }
      };
    case 'CLOSE_AUTH_POPUP':
      state.authDialogConfigs.onCloseCallback?.();

      return {
        ...state,
        authPopupStatus: false,
        authDialogConfigs: {
          showClose: true,
          state: null,
          type: null,
          showFooter: true,
          onCloseCallback: null
        }
      };
    case 'OPEN_CONSENT_POPUP':
      return {
        ...state,
        showConsent: true
      };
    case 'CLOSE_CONSENT_POPUP':
      return {
        ...state,
        showConsent: false,
        consentSelection: null
      };
    case 'CONSENT_SELECT':
      return {
        ...state,
        consentSelection: payload
      };
    case 'GET_CONSENT_SUCCESS':
      return {
        ...state,
        consentStatus: payload
      };

    case 'SET_TOKEN':
      localStorage.setItem('gfToken', action.token);
      localStorage.setItem('gfRefreshToken', action.refreshToken);
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken
      };

    case 'LOGOUT':
      localStorage.removeItem('gfToken');
      localStorage.removeItem('gfRefreshToken');
      localStorage.removeItem('gfUser');
      localStorage.removeItem('sessionStatus');
      return {
        ...initialState,
        user: null,
        token: null,
        refreshToken: null
      };

    case 'REGISTER_USER':
    case 'LOGIN':
    case 'FORGOT_PASSWORD':
    case 'UPDATE_PROFILE':
    case 'RESET_PASSWORD':
    case 'GET_ACCOUNT_INFO':
      return {
        ...state,
        loading: true,
        err: null,
        success: false
      };

    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        err: null
      };
    case 'UPDATE_PASSWORD_SUCCESS':
    case 'FORGOT_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        loading: false,
        err: null,
        success: true
      };

    case 'GET_ACCOUNT_INFO_SUCCESS':
      const accProfile = {
        ...data.profile,
        name: data.profile.name || '',
        avatar: data.profile.avatar || '',
        bio: data.profile.bio || ''
      };
      const userAccount = {
        ...state.user,
        profile: accProfile,
        account: data.account,
        telco: data.telco || null
      };
      localStorage.setItem('gfUser', JSON.stringify(userAccount));
      return {
        ...state,
        user: userAccount,
        loading: false,
        err: null
      };

    case 'GET_ACCOUNT_INFO_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        err: null
      };

    case 'LOGIN_SUCCESS':
      const user = {
        account: data.account,
        profile: data.profile,
        telco: data.telco
      };
      const token = data.accessToken;
      const { refreshToken } = data;

      localStorage.setItem('gfToken', token);
      localStorage.setItem('gfRefreshToken', refreshToken);
      localStorage.setItem('gfUser', JSON.stringify(user));

      return {
        ...state,
        loading: false,
        err: null,
        user,
        token,
        refreshToken
      };

    case 'REGISTER_USER_FAILURE':
    case 'GET_WHATSAPP_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
    case 'UPDATE_PASSWORD_FAILURE':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'LOGIN_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'GET_ACCOUNT_INFO_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'RESET_AUTH_STATE':
      return {
        ...state,
        loading: false,
        err: null,
        success: false
      };

    case 'VALIDATE_SESSION':
      localStorage.setItem('sessionStatus', 'validating');

      return {
        ...state,
        sessionStatus: 'validating',
        loading: true
      };
    case 'VALIDATE_SESSION_SUCCESS':
      localStorage.setItem('sessionStatus', 'success');

      return {
        ...state,
        sessionStatus: 'success',
        loading: false
      };
    case 'VALIDATE_SESSION_FAILED':
      localStorage.setItem('sessionStatus', 'failed');
      localStorage.setItem('logoutToken', data);

      return {
        ...state,
        sessionStatus: 'failed',
        loading: false
      };

    case 'GET_WHATSAPP':
      return {
        ...state
      };
    case 'GET_WHATSAPP_SUCCESS':
      return {
        ...state,
        whatsappLink: payload.data
      };

    default:
      return state;
  }
}
