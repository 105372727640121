import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHistory } from '../../redux/actions/history';
import { AnimatedModal, LivePlayer, IframePlayer } from '..';

const channelDuration = 86400;
const positionInSecond = 1000;

const ChannelPlayer = ({
  openPlayer,
  openIframe,
  dismissPlayer,
  sessionId
}) => {
  const position = useRef(0);
  const interval = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    interval.current = setInterval(() => position.current++, positionInSecond);
    return () => {
      clearInterval(interval.current);
    };
  }, [openIframe]);

  const handleDismissPlayer = useCallback(() => {
    clearInterval(interval.current);
    position.current = 0;
    dismissPlayer();
  }, [dismissPlayer]);

  const handleUpdateHistory = (currentTime) => {
    const channel = openPlayer || openIframe;
    // handle current time 0 when user play for the first time
    // if the channel type is "webview" then send "position" from interval
    const timeToSend =
      currentTime >= 0 && channel.type !== 'webview'
        ? currentTime
        : position.current;
    try {
      const historyData = {
        mediaType: 'channel',
        mediaSubType: channel.type,
        mediaId: channel.id,
        position: timeToSend,
        length: channelDuration
      };

      dispatch(updateHistory(historyData, sessionId));
    } catch (err) {
      return err;
    }
  };

  return (
    <AnimatedModal
      duration={300}
      open={!!openPlayer || !!openIframe}
      onClose={dismissPlayer}
    >
      <div className='video-player-wrapper'>
        {openPlayer && (
          <LivePlayer
            handleUpdateHistory={handleUpdateHistory}
            channelID={openPlayer.id}
            channelDetail={openPlayer}
            stream={openPlayer && openPlayer.url}
            onDismissPlayer={handleDismissPlayer}
            user={user}
          />
        )}

        {openIframe && (
          <IframePlayer
            handleUpdateHistory={handleUpdateHistory}
            src={openIframe.url}
            title={openIframe.titleLocalized}
            onDismissPlayer={handleDismissPlayer}
          />
        )}
      </div>
    </AnimatedModal>
  );
};

export default ChannelPlayer;
