import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

const URL_CHANNELS = `${BASE_URL}/catalog/v1.1/liveChannels`;
const URL_CHANNEL = `${BASE_URL}/catalog/v1.1/liveChannel`;

export const getChannel =
  (page = 1, perPage = 20) =>
  async (dispatch, getState) => {
    const state = getState();
    const { data } = state.channel;

    dispatch({
      type: 'GET_CHANNEL'
    });

    try {
      if (data < 1) {
        const res = await axios.get(
          `${URL_CHANNELS}?interactive=false&page=${page}&perPage=${perPage}&locale=en`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }
        );

        dispatch({
          type: 'GET_CHANNEL_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      } else {
        dispatch({
          type: 'GET_CHANNEL_SUCCESS_FROM_STATE',
          payload: {
            ...state.channel
          },
          page
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(showToastNotif('error', errorData.message));
      dispatch({
        type: 'GET_CHANNEL_FAILURE',
        err: errorData
      });
    }
  };

export const getChannelDetail = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await axios.get(`${URL_CHANNEL}/${id}`, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });
    return res.data && res.data.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch(showToastNotif('error', errorData.message));
    return errorData;
  }
};

export const getChannelUrl = (item) => async (dispatch, getState) => {
  const state = getState();
  switch (item.contentRights) {
    case 'premium':
      try {
        const res = await axios.get(`${URL_CHANNEL}/${item.id}/access`, {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        });
        return res.data && res.data.data.url;
      } catch (err) {
        const errorData = generateErrorDetail(err);
        dispatch(showToastNotif('error', errorData.message));
        return errorData;
      }
    default:
      return item.url;
  }
};
