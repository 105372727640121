import React, { useRef, useEffect } from 'react';
import { useInterval } from '../../hooks';

const intervalTime = 60000;
const VideoInterval = ({ updateHistoryCallback }) => {
  const updateHistoryInterval = useRef();
  updateHistoryInterval.current = useInterval(
    updateHistoryCallback,
    intervalTime
  );
  useEffect(() => {
    return () => {
      clearInterval(updateHistoryInterval.current);
    };
  }, []);
  return null;
};

export default React.memo(VideoInterval);
