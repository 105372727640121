/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken, onMessage } from 'firebase/messaging';
import { isFirebaseMessagingSupported } from '../../utils';
import {
  hideNotification,
  requestFCMToken,
  showNotification,
  showToastNotif
} from '../../redux/actions/notification';
import NotificationPermission from './NotificationPermission';
import styles from './Notification.module.css';
import icClose from '../../assets/ic-close.svg';
import { getRefreshToken } from '../../redux/actions/auth';

function FirebasePushNotif() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, user } = useSelector((state) => state.auth);
  const { showNotifications, fcmToken } = useSelector(
    (state) => state.notification
  );

  const [subscriptionNotifData, setSubscriptionNotifData] = useState(null);
  const [showNotifPermission, setShowNotifPermission] = useState(false);

  const handleAllowNotification = useCallback(() => {
    setShowNotifPermission(false);
    localStorage.setItem('isNotifPermissionEnabled', 'true');

    if (isFirebaseMessagingSupported()) {
      getToken()
        .then(async function (currentToken) {
          if (currentToken) {
            await dispatch(requestFCMToken());
          } else {
            console.log(
              'No Instance ID token available. Request permission to generate one.'
            );
          }
        })
        .catch(function (err) {
          console.log('An error occurred while retrieving token. ', err);
        });
    } else {
      dispatch(showToastNotif('failed', 'Push Notification Is Not Supported'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirebaseMessagingSupported()) {
      if (Notification.permission === 'default') {
        const isNotifPermissionEnabled = localStorage.getItem(
          'isNotifPermissionEnabled'
        );
        const isEnabled = isNotifPermissionEnabled === 'true';

        if (isNotifPermissionEnabled) {
          isEnabled && handleAllowNotification();
        } else {
          setShowNotifPermission(true);
        }
      }

      if (Notification.permission === 'granted') {
        // eslint-disable-next-line no-shadow
        const fcmToken = localStorage.getItem('fireMessagingToken');

        if (!fcmToken) {
          handleAllowNotification();
        }
      }
    }
  }, [handleAllowNotification]);

  useEffect(() => {
    const sendFCMToken = async () => {
      if (!token) return;

      const fcmTokenStatus = localStorage.getItem('isFcmTokenSent');
      if (fcmTokenStatus === 'false') {
        try {
          await dispatch(getRefreshToken());
        } catch (err) {
          return err;
        }
      }
    };

    if (fcmToken) {
      sendFCMToken();
    }
  }, [dispatch, fcmToken, token]);

  useEffect(() => {
    if (isFirebaseMessagingSupported()) {
      onMessage((payload) => {
        dispatch(showNotification(payload));
      });
    }
  }, [dispatch]);

  const handleSubscriptionRedirect = useCallback(
    (data) => {
      if (!token) {
        setSubscriptionNotifData(data);
        navigate('/login');
        return;
      }
      setSubscriptionNotifData(null);
      if (data && data?.profileId === user?.profile?.id) {
        navigate('/subscription');
      }
    },
    [navigate, token, user]
  );
  useEffect(() => {
    if (token && subscriptionNotifData) {
      handleSubscriptionRedirect(subscriptionNotifData);
    }
  }, [token, subscriptionNotifData, handleSubscriptionRedirect]);

  const handleCloseNotif = (data) => {
    dispatch(hideNotification(data));
  };
  return (
    <>
      {showNotifPermission && (
        <NotificationPermission
          onAllow={handleAllowNotification}
          onDeny={() => setShowNotifPermission(false)}
        />
      )}
      <div className={styles['notif-container']}>
        {showNotifications.map((item) => {
          const { data, notification, fcmMessageId } = item;

          const handleClickNotif = () => {
            switch (data.content_type) {
              // case 'liveshow':
              //   navigate(`/live-show/${data.content_id}`);
              //   break;
              // case 'transaction':
              //   navigate(
              //     `/profile/transactions/cashout/${data.content_id}`
              //   );
              //   break;
              case 'subscription':
                handleSubscriptionRedirect(data);
                break;
              default:
                navigate(`/${data.content_type}/${data.content_id}`);
                break;
            }
            handleCloseNotif(item);
          };

          return (
            <div key={fcmMessageId} className={styles['notif-card']}>
              {notification.image && (
                <img
                  className={styles['card-thumbnail']}
                  src={notification.image}
                  alt='notif-thumbnail'
                  onClick={handleClickNotif}
                />
              )}
              <div className={styles['card-detail']}>
                <div className={styles['card-header']}>
                  <p onClick={handleClickNotif}>{notification.title}</p>
                  <img
                    src={icClose}
                    alt='close'
                    onClick={() => handleCloseNotif(item)}
                    className={styles['filter']}
                  />
                </div>
                <span onClick={handleClickNotif}>{notification.body}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default FirebasePushNotif;
