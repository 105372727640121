const initialState = {
  // campain banner list state
  data: [],
  loading: false,
  err: null,

  // campaign detail state
  detailData: null,
  detailLoading: false,
  detailError: null
};

export default function campaignReducer(state = initialState, action) {
  const { type, payload, err } = action;

  switch (type) {
    case 'GET_CAMPAIGN_CAROUSEL':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_CAMPAIGN_CAROUSEL_SUCCESS':
      return {
        ...state,
        data: payload,
        loading: false,
        err: null
      };

    case 'GET_CAMPAIGN_CAROUSEL_FAILED':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_CAMPAIGN_DETAIL':
      return {
        ...state,
        detailLoading: true,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_SUCCESS':
      return {
        ...state,
        detailData: payload?.props,
        detailLoading: false,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        detailData: payload,
        detailLoading: false,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_FAILED':
      return {
        ...state,
        detailLoading: false,
        detailError: err
      };

    default:
      return {
        ...state
      };
  }
}
