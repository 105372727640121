import { formatObject, generateUserData } from '../utils';

export const initAirbridgeUserData = async (user) => {
  const userData = generateUserData(user, {
    separateSensitiveData: true
  });

  if (window?.airbridge) {
    await window.airbridge.setUserAttributes(userData?.sensitiveData);
  }
};

const airBridge = (event, options = { params: {}, user: null }) => {
  const { params = {}, user = null } = options;
  const userData = generateUserData(user, { separateSensitiveData: true });
  const time = new Date().getTime();
  const property = formatObject(params);

  const payload = {
    ...userData?.regularData,
    ...property,
    time
  };

  window.airbridge.events.send(event, {
    customAttributes: payload
  });
};

export default airBridge;
