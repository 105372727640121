import { logEvent, setUserProperties } from 'firebase/analytics';
import { firebaseAnalytic } from './firebase';

export default function trackGa(event, options = { params: {}, user: null }) {
  const { params = {}, user = null } = options;
  const account = user && user.account;
  const telco = user && user.telco;
  const eventParams = {
    ...params,
    ...(account && { userId: account.userId }),
    ...(telco && { telco: telco.operator })
  };

  if (telco) {
    setUserProperties(firebaseAnalytic, { telco: telco.operator });
  }
  logEvent(firebaseAnalytic, event, eventParams);
  window.gtag('event', event, eventParams);
}

export function trackPage(pageName) {
  const param = {
    screen_name: pageName
  };
  logEvent(firebaseAnalytic, 'screen_view', param);
  window.gtag('event', 'screen_view', param);
}
