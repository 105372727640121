import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import userPic from '../../assets/user.svg';
import styles from './Comments.module.css';

function CommentItem({ avatar, name, comment }) {
  return (
    <div className={styles['commentItem']}>
      {avatar.length > 0 && (
        <img className={styles['commentAvatar']} src={avatar} alt={name} />
      )}
      {avatar.length === 0 && (
        <img
          className={`${styles['commentAvatar']} ${styles['defaultAvatar']}`}
          src={userPic}
          alt={name}
        />
      )}
      <div>
        <span className={styles['commentName']}>{name}</span>
        <span className={styles['commentContent']}>{comment}</span>
      </div>
    </div>
  );
}

function CommentList() {
  const listWrapper = useRef();
  const list = useRef();
  const [overflow, setOverflow] = useState(false);
  const comments = useSelector((state) => state.interactive.comments.comments);

  useEffect(() => {
    setOverflow(list.current.offsetHeight > listWrapper.current.offsetHeight);
  }, [listWrapper, list, comments]);

  return (
    <div
      ref={listWrapper}
      className={`${styles['commentListWrapper']} ${
        overflow && styles['overflow']
      }`}
    >
      <ul ref={list} className={styles['commentList']}>
        {comments &&
          comments.length !== 0 &&
          comments.map((com, index) => {
            return (
              <li key={index}>
                <CommentItem
                  avatar={com.profile.avatar}
                  name={com.profile.name}
                  comment={com.data}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default CommentList;
