/* eslint-disable no-unsafe-optional-chaining */
const initialState = {
  subscriptionTransactions: {
    data: [],
    meta: null,
    noData: false
  },
  loading: false,
  err: false
};

export default function PackReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_SUBSCRIPTION_TRANSACTION':
      return {
        ...state,
        loading: true,
        err: false
      };

    case 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS':
      // eslint-disable-next-line no-case-declarations
      const { subscriptionTransactions } = state;
      return {
        ...state,
        subscriptionTransactions: {
          data:
            payload?.meta?.page === 1
              ? payload?.data
              : [...subscriptionTransactions.data, ...payload?.data],
          meta: payload.meta,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS_FROM_STATE':
      return {
        ...state,
        subscriptionTransactions: {
          ...state.subscriptionTransactions,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_SUBSCRIPTION_TRANSACTION_ERROR':
      return {
        ...state,
        loading: false,
        err: payload.err
      };

    case 'RESET_SUBSCRIPTION_TRANSACTION_STATE':
      return {
        ...initialState
      };

    default:
      return state;
  }
}
