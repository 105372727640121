import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const intervalID = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args);

    if (delay !== null) {
      intervalID.current = setInterval(handler, delay);
      return () => {
        clearInterval(intervalID.current);
      };
    }
  }, [delay]);

  return intervalID.current;
};

export default useInterval;
