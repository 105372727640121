/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SubscriptionIntro from '../SubscriptionIntro/SubscriptionIntro';
import bigLogo from '../../assets/sushiroll_logo.svg';
import styles from './VideoPlayer.module.css';
import { errorCode } from '../../constants';

export default function PlayerSubscription({
  onDismissPlayer,
  otherProps,
  error
}) {
  const navigate = useNavigate();
  const goSubscribe = () => {
    navigate('/subscription');
  };

  const goRedeem = () => {
    navigate('/voucher');
  };
  let content = (
    <p
      className={styles.errorMessage}
    >{`${error.message} (# ${error.code})`}</p>
  );
  // does not have video asset
  if (error.code === errorCode.MISSING_VIDEO) {
    content = <p className={styles.errorMessage}>{`${error.message}`}</p>;
  }

  // not subscribed
  if (error.code === errorCode.NO_SUBSCRIPTION) {
    content = (
      <div className={styles.subscriptionError}>
        <img src={bigLogo} alt='big-logo' />
        <p className={styles.subtitle}>
          You need to subscribe to watch this premium content
        </p>
        <SubscriptionIntro />

        <button
          type='button'
          className={styles.subscribeButton}
          onClick={goSubscribe}
        >
          Pick subscription plan
        </button>

        <span className={styles.redeemtion} onClick={goRedeem}>
          Have a voucher? Redeem now.
        </span>
      </div>
    );
  }
  return (
    <div className={styles.errorWrapper}>
      <div onClick={onDismissPlayer}>
        <i className='vjs-dismiss-player-icon' />
      </div>
      {content}
    </div>
  );
}
