/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import styles from './Tooltip.module.css';

function Tooltip({ children, content }) {
  const [show, setShow] = useState(false);
  const handleMouseIn = () => {
    setShow(true);
  };

  const handleMouseOut = () => {
    setShow(false);
  };

  return (
    <div
      className={styles['tooltip']}
      onMouseOver={handleMouseIn}
      onMouseLeave={handleMouseOut}
    >
      {!!show && <div className={styles['content']}>{content}</div>}
      {children}
    </div>
  );
}

export default Tooltip;
