import React from 'react';

import styles from './TextPage.module.css';

function TextPage({ title, children }) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-wrapper']}>
        <h1>{title}</h1>
      </div>

      <div className={styles['content']}>{children}</div>
    </div>
  );
}

export default TextPage;
