import React, { Suspense, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BottomBar, Loading } from '../components';
import {
  Home,
  History,
  Login,
  EmailLogin,
  PhoneLogin,
  Register,
  EmailRegister,
  PhoneRegister,
  MediaPageWrapper,
  Playlist,
  Detail,
  // Subscription,
  ProfileRoutes,
  Voucher,
  Search,
  Payment,
  PaymentComplete,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AboutUs,
  Terms,
  PrivacyPolicy,
  Faqs,
  ContactUs,
  EmailVerification,
  LiveTV,
  Stream,
  UserLicenseAgreement,
  CampaignDetail,
  TransactionHistory,
  SubscriptionHistory,
  Promo,
  PromoDetail,
  Privacy,
  MyList,
  FormPage
} from '../pages';
import { authPopup } from '../redux/actions/auth';

const router = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/mobile/contact-us',
    element: <ContactUs />
  },
  {
    path: '/promo/:promoId',
    element: <PromoDetail />
  },
  {
    path: '/form',
    element: <FormPage />
  },
  {
    path: '/promo',
    element: <Promo />
  },
  {
    path: '/login/email',
    element: <EmailLogin />,
    noLogin: true
  },
  {
    path: '/login/phone',
    element: <PhoneLogin />,
    noLogin: true
  },
  {
    path: '/login/*',
    element: <Login />,
    noLogin: true
  },
  {
    path: '/register/email',
    element: <EmailRegister />,
    noLogin: true
  },
  {
    path: '/register/phone',
    element: <PhoneRegister />,
    noLogin: true
  },
  {
    path: '/register/*',
    element: <Register />,
    noLogin: true
  },
  {
    path: '/playlists/:playlistId',
    element: <Playlist />
  },
  {
    path: '/playlists/continue-watching',
    element: <Playlist />
  },
  {
    path: '/history',
    element: <History />,
    loggedIn: true
  },
  {
    path: '/my-list',
    element: <MyList />,
    loggedIn: true
  },
  {
    path: '/transaction-history',
    element: <TransactionHistory />,
    loggedIn: true
  },
  {
    path: '/subscription-history/*',
    element: <SubscriptionHistory />,
    loggedIn: true
  },
  {
    path: '/subscription',
    element: <Navigate to='/subscription-history/buy' />,
    loggedIn: true
  },
  {
    path: '/voucher',
    element: <Voucher />,
    loggedIn: true,
    standby: true
  },
  {
    path: '/redeem',
    element: <Voucher />,
    loggedIn: true
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/payment',
    element: <Payment />
  },
  {
    path: '/payment/:planId',
    element: <Payment />
  },
  {
    path: '/payment/:planId/:method',
    element: <Payment />
  },
  {
    path: '/payment/:planId/:method/:promoCode',
    element: <Payment />
  },
  {
    path: '/stream/:channelID?',
    element: <Stream />
  },
  {
    path: '/live/:channelID?',
    element: <LiveTV />
  },
  {
    path: '/campaign/:campaignId?',
    element: <CampaignDetail />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/email-verification',
    element: <EmailVerification />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/terms',
    element: <Terms />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/faqs',
    element: <Faqs />
  },
  {
    path: '/end-user-license-agreement',
    element: <UserLicenseAgreement />
  },
  {
    path: '/not-found',
    element: <NotFound />
  },
  {
    path: '/payment/:payType/success',
    element: <PaymentComplete />,
    loggedIn: true
  },
  {
    path: '/profile/privacy',
    element: <Privacy />,
    loggedIn: true
  },
  {
    path: '/profile',
    element: <ProfileRoutes />,
    loggedIn: true
  },
  {
    path: '/payment',
    element: <Payment />
  },
  {
    path: '/:programType/:mediaId',
    element: <Detail />
  },
  {
    path: '/:pageName/*',
    element: <MediaPageWrapper />
  }
];

// This StandByLoader component and all related standby loader will show loading and login popup in the required logged in page to hold the user on log in process
export const withStandbyLoader = router
  .filter((item) => item.standby)
  .map((item) => item.path);

const StandByLoader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authPopupStatus } = useSelector((state) => state.auth);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    dispatch(authPopup.show());
    setStatus('show');
  }, [dispatch]);

  useEffect(() => {
    if (!authPopupStatus && status === 'show') {
      navigate('/');
    }
  }, [navigate, authPopupStatus, status]);

  return <Loading height='70vh' />;
};

const BaseComponentWrapper = ({ element, noLogin, loggedIn, standby }) => {
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  if (noLogin) {
    // Save click_id value of query params sent from Showtime to local storage
    // This click_id only exists in the login and register paths
    const pathName = location.pathname;
    if (pathName.includes('login') || pathName.includes('register')) {
      const query = new URLSearchParams(location.search);
      const clickId = query.get('click_id');
      clickId && localStorage.setItem('clickId', clickId);
    }
  }

  if (loggedIn && !authState?.token) return <Navigate to='/' />;

  if (standby && !authState?.token) return <StandByLoader />;

  return (
    <>
      <BottomBar />
      {element}
    </>
  );
};

export default function IndexRoute() {
  return (
    <Suspense fallback={<Loading height='100vh' />}>
      <Routes>
        {router.map(({ path, element, noLogin, loggedIn, standby }) => (
          <Route
            key={path}
            path={path}
            element={
              <BaseComponentWrapper
                element={element}
                noLogin={noLogin}
                loggedIn={loggedIn}
                standby={standby}
              />
            }
          />
        ))}
        <Route path='*' element={<Navigate to='/not-found' />} />
      </Routes>
    </Suspense>
  );
}

export { default as MobileRoutes } from './mobile';
