const initialState = {
  data: [],
  campaign: []
};

export default function pageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_PAGES_SUCCESS':
      return {
        data: payload
      };
    case 'GET_CAMPAIGNS_SUCCESS':
      return {
        ...state,
        campaign: payload
      };
    default:
      return state;
  }
}
