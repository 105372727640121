import React, { Suspense, useLayoutEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { Header, Footer, Loading } from './components/index.js';
import { AppWrapper, MaintenancePage } from './pages/index.js';
import ScrollToTop from './ScrollToTop.js';
import IndexRoute, { MobileRoutes } from './routes/index.js';
import { configKeys, remoteConfig } from './service/firebase.js';
import FirebasePushNotif from './components/Notification/FirebasePushNotif.js';
import { pruneSession } from './redux/actions/auth.js';
import SmartBanner from './components/SmartBanner/SmartBanner.js';

function App() {
  const [loading, setLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState();
  const dispatch = useDispatch();

  const getRemoteConfigValue = async () => {
    setLoading(true);
    try {
      await fetchAndActivate(remoteConfig);
    } catch (err) {
      throw new Error(err);
    } finally {
      setLoading(false);

      const maintenance = getValue(remoteConfig, configKeys.isMaintenance);
      setIsMaintenance(maintenance.asBoolean());
    }
  };

  useLayoutEffect(() => {
    getRemoteConfigValue();
    dispatch(pruneSession());
  }, [dispatch]);

  if (loading || typeof isMaintenance !== 'boolean') {
    return <Loading height='100vh' />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Routes>
        <Route
          path='*'
          element={
            <ScrollToTop>
              <Suspense fallback={<Loading height='100vh' />}>
                {isMaintenance ? (
                  <MaintenancePage />
                ) : (
                  <Routes>
                    <Route path='/mobile/*' Component={MobileRoutes} />
                    <Route
                      path='/*'
                      // eslint-disable-next-line react/no-unstable-nested-components
                      Component={() => (
                        <AppWrapper>
                          <SmartBanner />
                          <FirebasePushNotif />
                          <Header />
                          <IndexRoute />
                          <Footer />
                        </AppWrapper>
                      )}
                    />
                  </Routes>
                )}
              </Suspense>
            </ScrollToTop>
          }
        />
      </Routes>
    </GoogleOAuthProvider>
  );
}

export default App;
