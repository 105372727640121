import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../Loading/Loading';

import styles from './RecentItem.module.css';

function RecentItem() {
  const [data, setData] = useState();
  const pageData = useSelector((state) => state.page.data);
  const homePage = useMemo(() => {
    return pageData.find((page) => page.friendlyId === 'home');
  }, [pageData]);

  useEffect(() => {
    if (homePage && homePage.id) {
      const carouselData = homePage.items[0];
      const recentData = [];

      let index = 0;
      while (recentData?.length < 3) {
        const item = carouselData.items[index];

        if (item) {
          const { attachments } = item;
          const thumbnail = attachments?.thumbnail_portrait?.fileUrl;

          if (thumbnail) {
            recentData.push(thumbnail);

            index++;
          }
        } else {
          index = 0;
        }
      }

      setData(recentData);
    }
  }, [homePage]);

  if (!data) return <Loading />;

  return (
    <div className={styles['recent-item-wrapper']}>
      {data.map((item, index) => {
        const isCenter = index === 1;
        const className = isCenter
          ? `${styles['recent-item']} ${styles['centered']}`
          : styles['recent-item'];
        return (
          <img
            src={item}
            key={index}
            className={className}
            alt='recent media thumbnail'
          />
        );
      })}
    </div>
  );
}

export default RecentItem;
