import React from 'react';
import TextTruncated from '../TextTruncated/TextTruncated';
import { detectAgent, getDetailPoster } from '../../utils';

import styles from './EpisodeItem.module.css';

function EpisodeItem({ item, onClickItem }) {
  const {
    titleLocalized,
    // ordinal: episodeNumber,
    duration,
    descriptionLongLocalized: description
  } = item;
  const thumbnail = getDetailPoster(item);
  const isMobile = detectAgent('mobile');
  return (
    <div className={styles['episode-item-wrapper']} onClick={onClickItem}>
      <div style={{ display: isMobile && 'flex' }}>
        <img
          className={styles['episode-thumbnail']}
          src={thumbnail}
          alt='episode thumbnail'
        />
        <div className={isMobile ? styles['description-wrapper'] : ''}>
          <div className={styles['episode-title']}>{titleLocalized}</div>
          <p className={styles['episode-duration']}>
            {duration} {isMobile ? 'min' : ''}
          </p>
        </div>
      </div>
      <div className={styles['episode-description']}>
        <TextTruncated maxLength={92} content={description} readMore={false} />
      </div>
    </div>
  );
}

export default EpisodeItem;
