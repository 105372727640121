import axios from 'axios';
import { getValue } from 'firebase/remote-config';
import gcpPaths from './config/gcp-paths';
import { errorCode } from './constants';
import { getRefreshToken, logout, pruneSession } from './redux/actions/auth';
import { configKeys, remoteConfig } from './service/firebase';
import { generateDeviceInfo } from './utils';

let isRefreshing = false;
let requestQueue = [];

const nodeEnv = process.env.REACT_APP_NODE_ENV;

const processQueue = (error, token = null) => {
  requestQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  requestQueue = [];
};

let getInfoRequestCount = 0;
let refreshCount = 0;
let pruningCount = 0;

export default {
  setupInterceptors: (store) => {
    // add a request interceptor
    axios.interceptors.request.use((config) => {
      const { url, method } = config;

      const isGetInfoRequest =
        url.includes('/user/v1.1/accounts') && method === 'get';
      if (isGetInfoRequest) {
        getInfoRequestCount++;
      }
      if (isGetInfoRequest && getInfoRequestCount > 1) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (url.includes('/user/v1.2/sessions/refresh') && refreshCount > 1) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (url.includes('/user/v1.2/sessions/pruning') && pruningCount > 3) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (nodeEnv === 'development') {
        const getConfigUrl = getValue(remoteConfig, configKeys.baseURL.user);
        const BASE_CONFIG_URL = getConfigUrl.asString();
        const oldURL = new URL(config.url);
        const newHost = new URL(BASE_CONFIG_URL);
        oldURL.hostname = newHost.hostname;

        config.url = oldURL.href;
      } else {
        const reqPath = gcpPaths.find((item) => url.includes(`/${item}/`));
        if (reqPath) {
          const getConfigUrl = getValue(
            remoteConfig,
            configKeys.baseURL[reqPath]
          );
          const BASE_CONFIG_URL = getConfigUrl.asString();
          const requestURL = new URL(url);
          const configURL = new URL(BASE_CONFIG_URL);
          if (configURL.pathname.slice(-1) === '/') {
            configURL.pathname = configURL.pathname.slice(0, -1);
          }

          requestURL.hostname = configURL.hostname;
          configURL.pathname = configURL.pathname.replace(/\/$/, ''); // Remove trailing slash
          let remainingRequestPath = requestURL.pathname.replace(
            `/${reqPath}`,
            ''
          );
          remainingRequestPath = remainingRequestPath.replace(/^\//, ''); // Remove leading slash

          requestURL.pathname =
            configURL.pathname === '/'
              ? requestURL.pathname
              : `${configURL.pathname}/${remainingRequestPath}`;
          config.url = requestURL.href;
        }
      }

      // if (url.includes('/user')) {
      //   config.url = config.url.replace(
      //     /.+?(?=\/user)/,
      //     BASE_CONFIG_URL.asString()
      //   )
      // }

      config.headers['Accept-Language'] = 'en';
      return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        const { config } = response;
        const { url, method } = config;
        const isGetInfoRequest =
          url.includes('/user/v1.1/accounts') && method === 'get';
        // reset get non-retriable requests when there has response
        if (isGetInfoRequest) {
          getInfoRequestCount = 0;
        }

        if (url.includes('/user/v1.2/sessions/refresh')) {
          refreshCount = 0;
        }

        if (url.includes('/user/v1.2/sessions/pruning')) {
          pruningCount = 0;
        }

        return response;
      },
      (err) => {
        if (!err?.response) {
          throw err;
        }
        const error = err?.response?.data?.error;

        if (
          (error?.code === errorCode.MODEL_UNIQUE_ERROR ||
            error?.message === 'Model UniqueConstraintError') &&
          error?.details?.fields?.uuid
        ) {
          localStorage.removeItem('device_uuid');

          const originalRequest = err.config;
          const originalPayload = JSON.parse(originalRequest.data);
          const newDeviceInfo = generateDeviceInfo();
          originalPayload.device = newDeviceInfo;
          originalRequest.data = JSON.stringify(originalPayload);

          return axios(originalRequest);
        }

        if (
          (error?.code === errorCode.MODEL_UNIQUE_ERROR ||
            error?.message === 'Model UniqueConstraintError') &&
          error?.details?.fields?.uuid
        ) {
          localStorage.removeItem('device_uuid');

          const originalRequest = err.config;
          const originalPayload = JSON.parse(originalRequest.data);
          const newDeviceInfo = generateDeviceInfo();
          originalPayload.device = newDeviceInfo;
          originalRequest.data = JSON.stringify(originalPayload);

          return axios(originalRequest);
        }

        if (
          error?.code === errorCode.INVALID_SESSION_TOKEN ||
          error?.message === 'Session InvalidSessionToken'
        ) {
          const state = store.getState();
          const authState = state.auth;
          const { token, refreshToken } = authState;

          if (!token || !refreshToken) throw err;

          // console.log('token expired, need refreshing')
          const originalRequest = err.config;
          const { dispatch } = store;
          if (!originalRequest._retry) {
            // when request refresh token is process, push parallel request to queue
            if (isRefreshing) {
              return new Promise((resolve, reject) => {
                requestQueue.push({ resolve, reject });
              })
                .then((tokenAuth) => {
                  // axios.defaults.headers.common['Authorization'] = token
                  originalRequest.headers.Authorization = tokenAuth;
                  return axios(originalRequest);
                })
                .catch((errorAuth) => {
                  return errorAuth;
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve) => {
              dispatch(getRefreshToken())
                .then((data) => {
                  // error or refresh token
                  if (data.error) {
                    // logout
                    dispatch(logout());
                    return (window.location.href = '/login');
                  }

                  const newToken = data.data.accessToken;
                  // axios.defaults.headers.common['Authorization'] = newToken
                  originalRequest.headers.Authorization = newToken;
                  processQueue(null, newToken);
                  resolve(axios(originalRequest));
                  dispatch(pruneSession());
                })
                .catch(() => {
                  dispatch(logout());
                  return (window.location.href = '/login');
                })
                .finally(() => {
                  isRefreshing = false;
                });
            });
          }
        } else {
          throw err;
        }
      }
    );
  }
};
