import React from 'react';
import SubscriptionPackage from '../SubscriptionPackage/SubscriptionPackage';
import styles from './PackagePayment.module.css';

const PackagePayment = ({ packages, paymentGateway, showTitle = true }) => {
  const renderPackageList = (itemPackages) => {
    const telcoPacks = itemPackages.filter((item) => {
      return Object.entries(item.payments).some(
        ([key, value]) => key.startsWith('telco') && value.description
      );
    });

    const result = [];

    if (telcoPacks.length > 0) {
      result.push(
        <div key='telco'>
          <p className={styles['title']}>Subscribe Via Telco Plan</p>
          {renderPaymentOptions(telcoPacks, 'telco')}
        </div>
      );
    }

    return result;
  };

  const renderPaymentOptions = (packagesData, gateway) => {
    return (
      <>
        {showTitle && (
          <p className={styles['title']}>
            {!packagesData.length
              ? 'Please use Sushiroll Mobile App for purchase package'
              : 'Please use Sushiroll Mobile App for more package'}
          </p>
        )}
        <div className={styles['list-plan']}>
          {packagesData.map((item) => {
            if (item.name) item.title = item.name;

            return (
              <SubscriptionPackage
                key={item.id}
                {...item}
                gateway={gateway}
                paymentGateway={paymentGateway}
              />
            );
          })}
        </div>
      </>
    );
  };
  return paymentGateway
    ? renderPaymentOptions(packages)
    : renderPackageList(packages);
};

export default PackagePayment;
