import { v4 as uuidv4 } from 'uuid';
import Platform from 'platform';
import { isSupported } from 'firebase/messaging';

import { CAROUSEL_ITEM_THUMBNAIL } from './config/Thumbnail';
import phoneCodes from './config/phoneCodes';
import { COOKIES_DOMAIN } from './config/URL';

const PLATFORM = ['chrome', 'firefox', 'safari', 'android', 'ios'];

export const parseQuery = (queryString) => {
  return queryString
    .slice(1)
    .split('&')
    .map((q) => q.split('='))
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    }, {});
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (cname) => {
  const d = new Date();
  d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}= ; ${expires}; domain=${COOKIES_DOMAIN}; path=/`;
};

export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

export const getDeviceId = () => {
  const deviceId = localStorage.getItem('device_uuid');

  if (deviceId) {
    return deviceId;
  }

  const uid = uuidv4();

  localStorage.setItem('device_uuid', uid);
  return uid;
};

export const generateDeviceInfo = ({ fcmCheck = false } = {}) => {
  let platform = Platform.name.toLowerCase().split(' ')[0];

  if (PLATFORM.indexOf(platform) === -1) {
    const alternativePlatform = Platform.os.family.toLowerCase();

    if (PLATFORM.indexOf(alternativePlatform) !== -1) {
      platform = alternativePlatform;
    } else {
      platform = 'chrome';
    }
  }

  const deviceInfo = {
    uuid: getDeviceId(),
    name: Platform.os.family || 'Unknown',
    platform,
    osVersion: Platform.os.version || 'Unknown',
    screen: 'web'
  };

  const fsToken = localStorage.getItem('fireMessagingToken');
  if (fsToken) {
    deviceInfo.firebaseToken = fsToken;
  }
  if (fcmCheck && !fsToken) {
    localStorage.setItem('isFcmTokenSent', 'false');
  }

  return deviceInfo;
};

export const generateDetailUrl = (data) => {
  let type;

  switch (data.type) {
    case 'season':
    case 'featured':
    case 'editorial':
      type = 'playlists';
      break;
    case 'webview':
      type = 'live';
      break;
    case 'stream':
      if (data.interactive) {
        type = 'stream';
      } else {
        type = 'live';
      }
      break;

    default:
      type = data.type;
  }
  return `/${type}/${data.id}`;
};

export const generatePlaylistUrl = (id) => {
  return `/playlists/${id}`;
};

export const generateGenres = (tags) => {
  const genres = tags && tags.genres;

  return genres && genres.length && genres.join('/');
};

export const generateCasts = (tags) => {
  const casts = tags && tags.casts;

  return casts && casts.length && casts.join(', ');
};

export const formatDuration = (num) => {
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}h${rminutes}m`;
};

export const getThumbnail = (item, type) => {
  if (type === CAROUSEL_ITEM_THUMBNAIL) {
    if (window.innerWidth <= 500) {
      return (
        item.attachments &&
        item.attachments.banner_portrait &&
        item.attachments.banner_portrait.fileUrl
      );
    }
  }

  return (
    item.attachments && item.attachments[type] && item.attachments[type].fileUrl
  );
};

export const getDetailPoster = (item) => {
  if (item.type === 'episode') {
    return (
      item.attachments &&
      item.attachments.thumbnail_landscape &&
      item.attachments.thumbnail_landscape.fileUrl
    );
  }

  return (
    item.attachments &&
    item.attachments.poster_landscape &&
    item.attachments.poster_landscape.fileUrl
  );
};

export const mergeArrayObjects = (firstArray, secondArray) => {
  return firstArray.map((first) => {
    const selected = secondArray.find((second) => second.id === first.id);
    if (selected) {
      return {
        ...first,
        ...selected
      };
    }
    return first;
  });
};

export const detectAgent = (type) => {
  if (window.navigator) {
    const isSuitableAgent = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent
    );
    const deviceWidth = window.innerWidth;
    if (type === 'mobile') return isSuitableAgent && deviceWidth <= 500;
    if (type === 'tablet') return isSuitableAgent && deviceWidth >= 768;
  }
  return false;
};

export const detectOS = () => {
  return Platform.os && Platform.os.family && Platform.os.family.toLowerCase();
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const secondsToTime = (length) => {
  const hours = Math.floor(length / 3600);
  let minutes = Math.floor((length - hours * 3600) / 60);
  const seconds = Math.floor(length - hours * 3600 - minutes * 60);
  let time = '';

  if (hours !== 0) {
    time = `${hours}:`;
  }
  if (minutes !== 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? `0${minutes}` : String(minutes);
    time += `${minutes}:`;
  }
  if (time === '') {
    time = `${seconds}s`;
  } else {
    time += seconds < 10 ? `0${seconds}` : String(seconds);
  }
  return time;
};

export const getInitial = (name) => {
  return name
    .split(' ')
    .slice(0, 2)
    .map((n) => n.charAt(0).toUpperCase())
    .reduce((n, space) => n + space);
};

export const generateErrorDetail = (error) => {
  if (error?.response?.data?.error) {
    const errorDetail = error?.response?.data?.error;

    return {
      code: errorDetail?.code || null,
      message: errorDetail?.message || null,
      details: errorDetail?.details || ['An error occured. Please try again.']
    };
  }

  return {
    code: null,
    message: 'Network Error',
    details: ['Please check your internet connection and try again']
  };
};

export const convertToSnakeCase = (word) => {
  return word
    .replace(/\.?([A-Z]+)/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, '');
};

export const formatObject = (object, { customKeyName, excludeKeys } = {}) => {
  const newData = {};

  for (const key in object) {
    if (object[key] && !excludeKeys?.find((item) => item === key)) {
      const newKeyName = customKeyName
        ? `${customKeyName}_${convertToSnakeCase(key)}`
        : convertToSnakeCase(key);

      if (object[key] instanceof Array || object[key] instanceof Object) {
        newData[newKeyName] = JSON.stringify(object[key]);
      } else {
        newData[newKeyName] = object[key];
      }
    }
  }

  return newData;
};

export const generateUserData = (
  user,
  { separateSensitiveData = false } = {}
) => {
  const { account, profile, telco } = user || {};
  const deviceInfo = generateDeviceInfo();

  const formatDeviceInfo = formatObject(deviceInfo, {
    customKeyName: 'device'
  });
  const formatTelcoData =
    telco &&
    formatObject(
      {
        telcoOperator: telco.msisdn,
        telcoMsisdn: telco.operator
      },
      {
        customKeyName: 'account'
      }
    );
  const formatUserAccount =
    account &&
    formatObject(account, {
      customKeyName: 'account'
    });
  const formatUserProfile =
    profile &&
    formatObject(profile, {
      customKeyName: 'profile',
      excludeKeys: ['topicArn']
    });

  const userData = {
    ...formatDeviceInfo,
    ...formatTelcoData,
    ...formatUserAccount,
    ...formatUserProfile
  };

  if (separateSensitiveData) {
    const sensitiveDataKeys = [
      'account_email',
      'account_id',
      'account_name',
      'account_user_id',
      'device_uuid',
      'profile_account_id',
      'profile_id',
      'profile_name',
      'user_id'
    ];
    const sensitiveData = {};
    const regularData = {};

    for (const key in userData) {
      if (sensitiveDataKeys?.includes(key)) sensitiveData[key] = userData[key];
      else regularData[key] = userData[key];
    }

    return {
      sensitiveData,
      regularData
    };
  }

  return userData;
};

export const isFirebaseMessagingSupported = () => {
  const isMobile = detectAgent('mobile');

  return window.location.protocol === 'https:' && !isMobile && isSupported();
};

export const currencyFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumIntegerDigits: 1,
  maximumFractionDigits: 0
});

export const numberFormat = new Intl.NumberFormat('id-ID', {
  // style: 'currency',
  // currency: 'IDR',
  minimumIntegerDigits: 1,
  maximumFractionDigits: 0
});

export const formatDateV2 = (date, format = 'DD dd mmm yyyy') => {
  const dateNow = new Date();
  const tomorrow = new Date(dateNow);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const currentYear = dateNow.getFullYear();
  const currentMonth = dateNow.toLocaleString('default', { month: 'long' });
  const currrentday = dateNow.toLocaleString('default', { day: '2-digit' });
  const tomorrowDay = tomorrow.toLocaleString('default', { day: '2-digit' });

  const dateString = new Date(date);
  const year = dateString.getFullYear();
  const month = dateString.toLocaleString('default', { month: 'long' });
  const day = dateString.toLocaleString('default', { day: '2-digit' });
  let weekday = dateString.toLocaleString('default', { weekday: 'long' });
  const hourMinutes = dateString.toLocaleString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  });

  if (currentYear === year && currentMonth === month && currrentday === day) {
    weekday = 'Today';
  }

  if (currentYear === year && currentMonth === month && tomorrowDay === day) {
    weekday = 'Tomorrow';
  }

  const generateDate = () => {
    let formattedDate = '';
    if (format.includes('DD')) {
      formattedDate += ` ${weekday}`;
    }
    if (format.includes('dd')) {
      formattedDate += ` ${day}`;
    }
    if (format.includes('mmm')) {
      formattedDate += ` ${month}`;
    }
    if (format.includes('yyyy')) {
      formattedDate += ` ${year}`;
    }
    if (format.includes(',')) {
      formattedDate += ',';
    }
    if (format.includes('hh:mm')) {
      formattedDate += ` ${hourMinutes}`;
    }

    return formattedDate;
  };

  return generateDate();
};

const wait = (ms) =>
  new Promise((r) => {
    setTimeout(r, ms);
  });

export const retryOperation = (operation, delay, retries) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-promise-executor-return
    return operation()
      .then(resolve)
      .catch((reason) => {
        if (retries > 0) {
          return wait(delay)
            .then(retryOperation.bind(null, operation, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });

export const replacePhoneCountryCode = (phone) => {
  if (phone?.startsWith('8')) return phone;
  if (phone?.startsWith('0')) return phone?.replace(/^0/g, '');

  const findCode = phoneCodes.find(({ code }) => {
    const codeReplaced = code.replace(/\+|-/g, '');
    return phone?.startsWith(codeReplaced) || phone?.startsWith(code);
  });

  if (findCode?.code) {
    const removedCode = phone
      ?.replace(
        new RegExp(`\\${findCode.code}|${findCode.code.replace(/\+/g, '')}`),
        ''
      )
      .replace(/^0/g, '');
    return removedCode;
  }

  return phone;
};
