const initialState = {
  show: false,
  type: 'success',
  message: '',
  showNotifications: [],
  fcmToken: localStorage.getItem('fireMessagingToken') || ''
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SHOW_TOAST_NOTIF':
      return {
        ...state,
        show: payload.show,
        type: payload.type,
        message: payload.message
      };
    case 'SET_FCM_TOKEN':
      localStorage.setItem('fireMessagingToken', payload);
      return {
        ...state,
        fcmToken: payload
      };
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        showNotifications: [...state.showNotifications, payload]
      };
    case 'HIDE_NOTIFICATION':
      return {
        ...state,
        showNotifications: state.showNotifications.filter(
          (item) => item.fcmMessageId !== payload.fcmMessageId
        )
      };
    default:
      return state;
  }
}
