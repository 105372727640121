import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loading } from '..';
import {
  forgotPassword,
  loginByEmail,
  registerByEmail,
  selectConsent
} from '../../redux/actions/auth';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generateErrorDetail } from '../../utils';
import ConsentCheckbox from './ConsentCheckbox';
import styles from './EmailAuthentication.module.css';
import TNCFooter from './TNCFooter';
import icView from '../../assets/views-icon.svg';
import icViewDisabled from '../../assets/view-disabled-icon.svg';

function RenderForm({
  formType,
  setName,
  setEmail,
  setPassword,
  setRepassword
}) {
  const [showPass, setShowPass] = useState(false);

  const toggleShowPass = () => {
    setShowPass((prev) => !prev);
  };

  const handleAutofill = () => {
    setEmail(localStorage.getItem('autoFillUserId'));
    return localStorage.getItem('autoFillUserId');
  };

  switch (formType) {
    case 'login':
      return (
        <>
          <input
            type='text'
            name='email'
            id='email'
            placeholder='Email/UserID'
            defaultValue={
              localStorage.getItem('autoFillUserId') ? handleAutofill() : ''
            }
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email/UserID</span>

          <input
            type='password'
            name='password'
            id='password'
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Password</span>
        </>
      );
    case 'register':
      return (
        <>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Full Name'
            onChange={(e) => setName(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Full Name</span>

          <input
            type='email'
            name='email'
            id='email'
            placeholder='Email Address'
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email Address</span>

          <div className={styles['inputGroup']}>
            <input
              type={showPass ? 'text' : 'password'}
              name='password'
              id='password'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className={styles['input-placeholder']}>Password</span>
            <button
              type='button'
              onClick={toggleShowPass}
              className={styles['showPasswordBtn']}
            >
              <img src={showPass ? icView : icViewDisabled} alt='view' />
            </button>
          </div>

          <div className={styles['inputGroup']}>
            <input
              type={showPass ? 'text' : 'password'}
              name='repassword'
              id='repassword'
              placeholder='Confirm Password'
              onChange={(e) => setRepassword(e.target.value)}
            />
            <span className={styles['input-placeholder']}>
              Confirm Password
            </span>
            <button
              type='button'
              onClick={toggleShowPass}
              className={styles['showPasswordBtn']}
            >
              <img src={showPass ? icView : icViewDisabled} alt='view' />
            </button>
          </div>
          <ConsentCheckbox language='id' />
        </>
      );
    case 'reset-password':
      return (
        <>
          <input
            type='email'
            name='email'
            id='email'
            placeholder='Email Address'
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email Address</span>
        </>
      );
    default:
      return null;
  }
}

function EmailAuthentication({
  type,
  onCloseModal,
  showResetPassword,
  setShowResetPassword,
  onClickTNC
}) {
  const [err, setErr] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const auth = useSelector((state) => state.auth);
  const { loading, success, consentSelection } = auth;
  const dispatch = useDispatch();
  const isLogin = type === 'login';

  const isFormFilled = () => {
    if (showResetPassword) {
      return email;
    }
    if (type === 'register') {
      return (
        name && email && password && repassword && consentSelection !== null
      );
    }
    if (type === 'login') {
      return email && password;
    }
    return false;
  };

  useEffect(() => {
    if (showResetPassword) {
      analytic(analyticTypes.trackPage, 'ForgotPassword');
    } else {
      analytic(
        analyticTypes.event,
        isLogin
          ? analyticEvents.AUTH.START_LOGIN_BY_EMAIL
          : analyticEvents.AUTH.START_REGISTER_BY_EMAIL
      );
      analytic(
        analyticTypes.trackPage,
        isLogin ? 'LoginMobile' : 'RegisterMobile'
      );
    }
  }, [isLogin, showResetPassword]);

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setErr('');

    const emailLogin = e.target.elements.email.value;
    const passwordLogin = e.target.elements.password.value;

    if (!emailLogin) {
      return setErr('Invalid email!');
    }

    if (!passwordLogin) {
      return setErr('Invalid password!');
    }

    try {
      selectConsent(null);
      await dispatch(loginByEmail(email, password));
      onCloseModal();
    } catch (error) {
      const errorData = generateErrorDetail(error);
      return errorData;
    }
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    setErr('');

    const nameRegis = e.target.elements.name.value;
    const emailRegis = e.target.elements.email.value;
    const passwordRegis = e.target.elements.password.value;
    const repasswordRegis = e.target.elements.repassword.value;

    if (!nameRegis) {
      return setErr('Invalid name!');
    }

    if (!emailRegis) {
      return setErr('Invalid email!');
    }

    if (!passwordRegis) {
      return setErr('Invalid password!');
    }

    if (!repasswordRegis || repasswordRegis !== passwordRegis) {
      return setErr('Invalid repassword!');
    }

    try {
      await dispatch(registerByEmail(name, email, password));

      onCloseModal();
    } catch (error) {
      const errorData = generateErrorDetail(error);
      return errorData;
    }
  };

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    setErr('');

    const emailForgot = e.target.elements.email.value;

    if (!emailForgot) {
      return setErr('Invalid email!');
    }

    try {
      await dispatch(forgotPassword(email));
    } catch (error) {
      const errorData = generateErrorDetail(error);
      return errorData;
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setErr('');

    if (!isFormFilled()) return;

    if (showResetPassword) {
      await handleSubmitForgotPassword(e);
    } else if (type === 'login') {
      await handleSubmitLogin(e);
    } else if (type === 'register') {
      await handleSubmitRegister(e);
    }
  };

  const getButtonLabel = () => {
    if (showResetPassword) {
      return 'Reset Password';
    }
    if (isLogin) {
      return 'Login';
    }
    return 'Register';
  };

  return (
    <div className={styles['form-wrapper']}>
      <form className={styles['form']} onSubmit={handleSubmitForm}>
        <RenderForm
          formType={showResetPassword ? 'reset-password' : type}
          setName={setName}
          setEmail={setEmail}
          setPassword={setPassword}
          setRepassword={setRepassword}
        />
        {loading ? (
          <Loading height='80px' />
        ) : (
          <Button
            buttonType='submit'
            onSubmit={handleSubmitForm}
            style={{ width: '100%', padding: '1rem', fontWeight: 600 }}
            bgColor='sushiroll'
            disabled={!isFormFilled()}
          >
            {getButtonLabel()}
          </Button>
        )}
        {!isLogin && <TNCFooter dialogstate={type} onClick={onClickTNC} />}
        {!!success && <div className='success'>Please check your email!</div>}
        {(!!err || !!auth.err) && (
          <div className='error'>{err || auth.err}</div>
        )}
      </form>

      {isLogin && !showResetPassword && (
        <div
          className={styles['remind']}
          data-reset
          onClick={() => setShowResetPassword(true)}
        >
          <span>Forgot your password?</span>
        </div>
      )}
    </div>
  );
}

export default memo(EmailAuthentication);
