/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loading } from '..';
import { errorCode } from '../../constants';
import {
  loginByPhone,
  requestOtp,
  resetAuthState,
  selectConsent
} from '../../redux/actions/auth';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generateErrorDetail, replacePhoneCountryCode } from '../../utils';
import ConsentCheckbox from './ConsentCheckbox';
import styles from './PhoneAuthentication.module.css';
import StartAddOn from '../InputForm/InputFormComponents/StartAddOn';
import TNCFooter from './TNCFooter';
import getPages from '../../redux/actions/page';

const cencorMobileNumber = (number) => {
  const numStr = number.toString();
  const showFirstNumber = 4;
  const censor = '*****';

  return (
    numStr.substring(0, showFirstNumber) +
    censor +
    numStr.substring(showFirstNumber + censor.length, numStr.length)
  );
};

function PhoneAuthentication({
  type,
  msisdn,
  onCloseModal,
  onGoBack,
  onRedirectLogin,
  onClickTNC
}) {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState();
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(59);
  const interval = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { authDialogConfigs, consentSelection } = auth;
  const { showFooter } = authDialogConfigs;
  const isLogin = type === 'login';
  const startAddonRef = useRef();
  const addonSelectRef = useRef();
  const inputRef = useRef();
  const placeholderRef = useRef();
  const [phoneCode, setPhoneCode] = useState('+62');

  useEffect(() => {
    analytic(
      analyticTypes.event,
      isLogin
        ? analyticEvents.AUTH.START_LOGIN_BY_PHONE
        : analyticEvents.AUTH.START_REGISTER_BY_PHONE
    );
    analytic(
      analyticTypes.trackPage,
      isLogin ? 'LoginMobile' : 'RegisterMobile'
    );
  }, [isLogin]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthState());
      resetCountdown();
    };
  }, [dispatch]);

  useEffect(() => {
    if (startCountdown) {
      interval.current = setInterval(() => {
        setResendCountdown((state) => state - 1);
      }, 1000);
    }
  }, [startCountdown]);

  useEffect(() => {
    if (resendCountdown === 0) {
      resetCountdown();
    }
  }, [resendCountdown]);

  const resetCountdown = () => {
    clearInterval(interval.current);
    setResendCountdown(59);
    setStartCountdown(false);
  };

  const handleSendOtp = useCallback(
    async (e) => {
      e && e.preventDefault();

      try {
        setErr('');
        let phone = inputRef.current.value;
        phone = phone.startsWith('0') ? phone.replace(/^0/g, '') : phone;
        phone = phoneCode + phone;
        setLoading(true);
        if (phoneCode !== '+62') {
          localStorage.setItem('autoFillUserId', phone.replace(/\+/g, ''));
          onRedirectLogin('email');
        } else {
          await requestOtp(phone);

          setLoading(false);
          setStartCountdown(true);
          setMobile(phone);
        }

        if (isLogin) {
          analytic(analyticTypes.trackPage, 'LoginMobileOTP');
        } else {
          analytic(analyticTypes.trackPage, 'RegisterMobileOTP');
        }

        return setStep(2);
      } catch (error) {
        const errorData = generateErrorDetail(error);
        handleErrorCode(errorData);
      }
    },
    [isLogin, onRedirectLogin, phoneCode]
  );

  const handleResendOtp = async () => {
    try {
      setLoading(true);

      await requestOtp(mobile);

      setStartCountdown(true);
      setLoading(false);
    } catch (error) {
      const errorData = generateErrorDetail(error);
      const mes = errorData.message || 'Internal Server Error!';
      setLoading(false);
      setErr(mes);
    }
  };

  const handleErrorCode = (errorData) => {
    const { code } = errorData;
    let mes = '';
    if (
      code === errorCode.INVALID_CREDENTIAL ||
      code === errorCode.INVALID_NUMBER
    ) {
      mes = 'Incorrect mobile number';
    } else if (
      code === errorCode.INVALID_PARAMETERS ||
      code === errorCode.INVALID_OTP
    ) {
      mes = 'Incorrect OTP code';
    } else if (
      code === errorCode.TOO_MANY_ATTEMPTS ||
      code === errorCode.TOO_QUICK_RETRY
    ) {
      mes = 'Too many attempts, please try again later';
    } else {
      mes = errorData.message || 'General Login Error';
    }

    setLoading(false);
    setErr(mes);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setErr('');

    try {
      const otp = e.target.elements.otp.value;
      if (isLogin) {
        dispatch(selectConsent(null));
      }
      await dispatch(loginByPhone(mobile, otp));
      msisdn && dispatch(getPages());
      onCloseModal();
    } catch (error) {
      const errorData = generateErrorDetail(error);
      handleErrorCode(errorData);
    }
  };

  useLayoutEffect(() => {
    const startAddonEl = startAddonRef.current;
    const startAddonWidth = startAddonEl?.offsetWidth;
    const initialPos = `${startAddonWidth + 26}px`;
    const extendedPos = `${startAddonWidth + 10}px`;
    if (startAddonWidth < 52) {
      inputRef.current.style.paddingLeft = initialPos;
      placeholderRef.current.style.left = initialPos;
    } else {
      inputRef.current.style.paddingLeft = extendedPos;
      placeholderRef.current.style.left = extendedPos;
    }
  }, [phoneCode]);

  useEffect(() => {
    if (msisdn && inputRef.current) {
      const num = replacePhoneCountryCode(msisdn);
      inputRef.current.value = num;

      setTimeout(() => handleSendOtp(), 100);
    }
  }, [handleSendOtp, msisdn]);

  return (
    <div className={`${styles['form-wrapper']}`}>
      {step === 1 ? (
        <form id='otp-form' className={styles['form']} onSubmit={handleSendOtp}>
          <input
            type='number'
            name='phone'
            id='phone'
            placeholder='Mobile Number'
            ref={inputRef}
          />
          <span className={styles['input-placeholder']} ref={placeholderRef}>
            Mobile Number
          </span>
          <span
            className={styles['addon-code-number']}
            ref={startAddonRef}
            data-addon='start'
          >
            <StartAddOn
              ref={addonSelectRef}
              startAddOn={{ type: 'phone-code', defaultValue: '+62' }}
              inputValue='+62'
              onChange={setPhoneCode}
            />
          </span>
          {!isLogin && <ConsentCheckbox language='id' />}
          {loading ? (
            <Loading height='100px' />
          ) : (
            <Button
              buttonType='submit'
              style={{ width: '100%', padding: '1rem', fontWeight: 600 }}
              bgColor='sushiroll'
              disabled={consentSelection === null && !isLogin}
            >
              {isLogin ? 'Login' : 'Register'}
            </Button>
          )}
        </form>
      ) : (
        <form id='login-form' className={styles['form']} onSubmit={handleLogin}>
          <span className={styles['verification']}>
            Verification code sent to
          </span>
          <span className={styles['verification-number']}>
            {cencorMobileNumber(mobile)}
          </span>
          <input
            type='number'
            name='otp'
            id='otp'
            maxLength='6'
            placeholder='Enter OTP Code'
          />
          <span className={styles['input-placeholder']}>Enter OTP Code</span>

          {(!!err || !!auth.err) && (
            <div className='error'>{err || auth.err}</div>
          )}
          {loading ? (
            <Loading height='100px' />
          ) : (
            <>
              <Button
                buttonType='submit'
                style={{ width: '100%', padding: '1rem', fontWeight: 600 }}
                bgColor='sushiroll'
              >
                Verify
              </Button>
              <span className={styles['resend-otp']}>
                {startCountdown ? (
                  <>
                    Wait{' '}
                    <span className={styles['countdown']}>
                      {resendCountdown}
                    </span>
                    s before requesting another verification code
                  </>
                ) : (
                  <span
                    className={styles['resend-button']}
                    onClick={handleResendOtp}
                  >
                    Resend verification code
                  </span>
                )}
              </span>
            </>
          )}
        </form>
      )}
      {showFooter && !isLogin && <TNCFooter onClick={onClickTNC} />}
    </div>
  );
}

export default PhoneAuthentication;
