export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_DEV;

export const BASE_SOCKET_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_SOCKET_URL
    : process.env.REACT_APP_BASE_SOCKET_URL_DEV;

export const JWT_APP_ID = '82ace74a-9e1a-4337-bacb-cb3afe8a090b';
export const PAYMENT_GATEWAY_URL = process.env.REACT_APP_PAYMENT_GATEWAY_URL;
export const COOKIES_DOMAIN = window.location.hostname.includes(
  'sushiroll.co.id'
)
  ? 'sushiroll.co.id'
  : window.location.hostname;
