function stringToArray(string) {
  const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
  const array = new Uint16Array(buffer);
  for (let i = 0, strLen = string.length; i < strLen; i++) {
    array[i] = string.charCodeAt(i);
  }
  return array;
}

function arrayToString(array) {
  const uint16array = new Uint16Array(array.buffer);
  return String.fromCharCode.apply(null, uint16array);
}

function base64DecodeUint8Array(input) {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) array[i] = raw.charCodeAt(i);

  return array;
}

function base64EncodeUint8Array(input) {
  const keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';
  let chr1;
  let chr2;
  let chr3;
  let enc1;
  let enc2;
  let enc3;
  let enc4;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    // eslint-disable-next-line no-bitwise
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      // eslint-disable-next-line no-multi-assign
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output +=
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4);
  }
  return output;
}

function waitForEvent(name, action, target) {
  target.addEventListener(
    name,
    () => {
      // eslint-disable-next-line prefer-rest-params
      action(arguments[0]);
    },
    false
  );
}

const pallyCon = {
  keySystem: null,
  certificate: null,
  player: null,
  contentUrl: null,
  contentId: null,
  licenseUrl: null,
  pallyconCustomData: null,
  fairplay(option) {
    if (option.player !== undefined) {
      pallyCon.player = option.player;
    }
    if (option.content_url !== undefined) {
      pallyCon.contentUrl = option.content_url;
    }
    if (option.pallycon_custom_data !== undefined) {
      pallyCon.pallyconCustomData = option.pallycon_custom_data;
    }
    if (option.license_url !== undefined) {
      pallyCon.licenseUrl = option.license_url;
    }
    const request = new XMLHttpRequest();
    // request.responseType = 'arraybuffer';
    request.responseType = 'text';
    request.addEventListener('load', pallyCon.onCertificateLoaded, false);
    request.addEventListener('error', pallyCon.onCertificateError, false);
    request.open('GET', option.certificate_uri, true);
    request.setRequestHeader('Pragma', 'Cache-Control: no-cache');
    request.setRequestHeader('Cache-Control', 'max-age=0');
    request.send();
  },
  onCertificateLoaded(event) {
    const request = event.target;
    // console.log(`request.response =>${request.response}`)
    // console.log(`request.response length=>${request.response.length}`)
    // console.log(`request.response type =>${typeof request.response}`)
    // console.log("request.response to String =>" + String.fromCharCode.apply(null, new Uint8Array(request.response)));
    pallyCon.certificate = base64DecodeUint8Array(request.response);
    // certificate = new Uint8Array(request.response);
    pallyCon.player.src({
      src: pallyCon.contentUrl
    });
    pallyCon.player.tech_.el_.addEventListener(
      'webkitneedkey',
      pallyCon.onNeedKey,
      false
    );
    pallyCon.player.tech_.el_.addEventListener(
      'error',
      pallyCon.onError,
      false
    );
  },
  onCertificateError(event) {
    window.console.error('Failed to retrieve the server certificate.', event);
  },
  extractContentId(initData) {
    pallyCon.contentId = arrayToString(initData);
    pallyCon.contentId = pallyCon.contentId.substring(
      pallyCon.contentId.indexOf('skd://') + 6
    );
    return pallyCon.contentId;
  },
  concatInitDataIdAndCertificate(initData, id, cert) {
    if (typeof id === 'string') id = stringToArray(id);
    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    let offset = 0;
    // var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
    const buffer = new ArrayBuffer(
      initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength
    );
    const dataView = new DataView(buffer);

    const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, id.byteLength, true);
    offset += 4;

    const idArray = new Uint16Array(buffer, offset, id.length);
    idArray.set(id);
    offset += idArray.byteLength;

    dataView.setUint32(offset, cert.byteLength, true);
    offset += 4;

    const certArray = new Uint8Array(buffer, offset, cert.byteLength);
    certArray.set(cert);

    return new Uint8Array(buffer, 0, buffer.byteLength);
  },
  selectKeySystem() {
    if (
      window.WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')
    ) {
      pallyCon.keySystem = 'com.apple.fps.1_0';
    } else {
      throw new Error({ message: 'Key System not supported' });
    }
  },
  onError(event) {
    window.console.error('A video playback error occurred', event);
  },
  onNeedKey(event) {
    const video = event.target;
    let { initData } = event;
    const contentId = pallyCon.extractContentId(initData);
    initData = pallyCon.concatInitDataIdAndCertificate(
      initData,
      contentId,
      pallyCon.certificate
    );
    if (!video.webkitKeys) {
      pallyCon.selectKeySystem();
      video.webkitSetMediaKeys(new window.WebKitMediaKeys(pallyCon.keySystem));
    }

    if (!video.webkitKeys)
      throw new Error({ message: 'Could not create MediaKeys' });

    const keySession = video.webkitKeys.createSession('video/mp4', initData);
    if (!keySession)
      throw new Error({ message: 'Could not create key session' });

    keySession.contentId = contentId;
    waitForEvent('webkitkeymessage', pallyCon.licenseRequestReady, keySession);
    waitForEvent('webkitkeyadded', pallyCon.onKeyAdded, keySession);
    waitForEvent('webkitkeyerror', pallyCon.onKeyError, keySession);
  },
  licenseRequestReady(event) {
    const session = event.target;
    const { message } = event;
    const request = new XMLHttpRequest();
    // var sessionId = event.sessionId
    request.responseType = 'text';
    request.session = session;
    request.addEventListener('load', pallyCon.licenseRequestLoaded, false);
    request.addEventListener('error', pallyCon.licenseRequestFailed, false);
    // console.log(`spc=${base64EncodeUint8Array(message)}`)
    const params = `spc=${base64EncodeUint8Array(
      message
    )}&assetId=${encodeURIComponent(session.contentId)}`;
    request.open('POST', pallyCon.licenseUrl, true);
    request.setRequestHeader(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    // pallycon add
    request.setRequestHeader(
      'pallycon-customdata-v2',
      pallyCon.pallyconCustomData
    );
    request.send(params);
  },
  licenseRequestLoaded(event) {
    const request = event.target;
    const { session } = request;
    // response can be of the form: '\n<ckc>base64encoded</ckc>\n'
    // so trim the excess:
    let keyText = request.responseText.trim();
    keyText = base64DecodeUint8Array(keyText);
    session.update(keyText);
  },
  licenseRequestFailed(event) {
    window.console.error('The license request failed.', event);
  },
  onKeyError(event) {
    window.console.error('A decryption key error was encountered', event);
  },
  onKeyAdded(event) {
    window.console.log('Decryption key was added to session.', event);
  }
};

export default pallyCon;
