/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* global videojs */
import React from 'react';
import { withRouter } from '../../hooks';
import Loading from '../Loading/Loading';
import VideoInterval from './VideoInterval';

import './CustomControlButton/CustomControlButton';
import PlayerSubscription from './PlayerSubscription';
import styles from './VideoPlayer.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generateErrorDetail } from '../../utils';

class LivePlayer extends React.Component {
  state = {
    showDissmissIcon: false,
    runInterval: false,
    mediaStart: false,
    loading: false,
    error: null
  };

  updateHistoryCallback = () => {
    const { handleUpdateHistory } = this.props;
    if (this.player && this.state.mediaStart) {
      handleUpdateHistory(this.player.currentTime(), this.player.duration());
    }
  };

  addEventListener = () => {
    const { channelID, contentType, handleUpdateHistory, user, channelDetail } =
      this.props;

    const paramsEvent = {
      contentType,
      channelID
    };
    if (channelDetail.titleLocalized)
      paramsEvent.channelName = channelDetail.titleLocalized;
    if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
    if (channelDetail.contentRights)
      paramsEvent.channelContentRights = channelDetail.contentRights;

    this.player.on('play', (e) => {
      console.log('video started', e);
      this.setState({ mediaStart: true });
      this.setState({ runInterval: true });
      if (!this.pausing) handleUpdateHistory(0);
      if (this.pausing) {
        this.pausing = false;

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
          {
            params: paramsEvent,
            user
          }
        );
      }

      this.setState(() => ({
        showDissmissIcon: true
      }));
    });

    this.player.on('pause', (e) => {
      if (!this.player.seeking()) {
        this.pausing = true;
        this.setState({ runInterval: false });

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE,
          {
            params: paramsEvent,
            user
          }
        );
      }
    });

    this.player.on('useractive', (e) => {
      this.setState(() => ({
        showDissmissIcon: true
      }));
    });

    this.player.on('ended', (e) => {
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
        params: paramsEvent,
        user
      });
      this.setState({ mediaStart: false });
    });

    this.player.on('userinactive', (e) => {
      this.setState(() => ({
        showDissmissIcon: false
      }));
    });

    this.player.on('error', (e) => {
      console.log('error', e);
      return this.setState(() => ({
        error: {
          code: 'player-error',
          message: 'General Player Error!'
        }
      }));
    });
  };

  async componentDidMount() {
    const { stream, contentType, channelID, user, channelDetail } = this.props;
    if (!stream || (stream && stream.error)) return;

    const videoOptions = {
      controls: true,
      autoplay: true,
      fluid: true,
      liveui: true,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      aspectRatio: '16:9',
      sources: [
        {
          src: stream,
          type: 'application/x-mpegURL'
        }
      ],
      html5: {
        nativeCaptions: false
      },
      // make the text track settings dialog not initialize
      textTrackSettings: false
    };

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoOptions,
      function onPlayerReady() {
        console.log('onPlayerReady', this);
        const _player = this.player();
        // auto play
        _player.autoplay('any');
      }
    );

    this.player.src(stream);
    const paramsEvent = {
      contentType,
      channelID
    };
    if (channelDetail.titleLocalized)
      paramsEvent.channelName = channelDetail.titleLocalized;
    if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
    if (channelDetail.contentRights)
      paramsEvent.channelContentRights = channelDetail.contentRights;

    analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH, {
      params: paramsEvent,
      user
    });
    analytic(analyticTypes.trackPage, 'LiveTVPlayer', { user });

    this.addEventListener();
  }

  // destroy player on unmount
  componentWillUnmount() {
    const { contentType, channelID, user, channelDetail } = this.props;

    if (this.player) {
      const paramsEvent = {
        contentType,
        channelID
      };
      if (channelDetail.titleLocalized)
        paramsEvent.channelName = channelDetail.titleLocalized;
      if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
      if (channelDetail.contentRights)
        paramsEvent.channelContentRights = channelDetail.content;
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT, {
        params: paramsEvent,
        user
      });
      this.player.dispose();
    }
  }

  renderError = (error) => {
    const { onDismissPlayer } = this.props;
    const errorDetails = generateErrorDetail(error);
    const content = (
      <p>{`${errorDetails.message}}} (# ${errorDetails.code})`}</p>
    );

    return (
      <div className={styles['errorWrapper']}>
        <div className='vjs-dismiss-btn-wrapper' onClick={onDismissPlayer}>
          <i className='vjs-dismiss-player-icon' />
        </div>
        {content}
      </div>
    );
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  // isRelative flag for the interactive live stream
  render() {
    const {
      subtitles,
      onDismissPlayer,
      stream,
      handleUpdateHistory,
      viewers,
      isRelative,
      ...otherProps
    } = this.props;
    const { loading, error, runInterval } = this.state;

    if (stream && stream.error) {
      return (
        <PlayerSubscription
          isInteractive={isRelative} // Give flag is relative when the player is for interactive
          onDismissPlayer={onDismissPlayer}
          otherProps={otherProps}
          error={stream.error}
        />
      );
    }

    if (error) {
      return this.renderError(error);
    }

    return loading ? (
      <Loading height='100vh' />
    ) : (
      <div className='live-player'>
        {runInterval && (
          <VideoInterval updateHistoryCallback={this.updateHistoryCallback} />
        )}
        {!!this.state.showDissmissIcon && (
          <div
            className={`live-dismiss-btn-wrapper ${
              isRelative ? 'relative' : ''
            } `}
            onClick={onDismissPlayer}
          >
            <i className='vjs-dismiss-player-icon' />
          </div>
        )}
        {!!this.state.showDissmissIcon && isRelative && viewers && (
          <div className='live-viewer-btn-wrapper' onClick={onDismissPlayer}>
            <i className='vjs-viewer-player-icon' />
            <span>{viewers} </span>
          </div>
        )}

        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className={`video-js ${
              isRelative && 'video-js-relative'
            } vjs-big-play-centered`}
          >
            {subtitles &&
              subtitles.map((item, index) => (
                <track
                  key={index}
                  src={item.url}
                  kind='subtitles'
                  srcLang={item.language}
                  label={item.label}
                />
              ))}
          </video>
        </div>
      </div>
    );
  }
}

export default withRouter(LivePlayer);
