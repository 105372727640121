/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { getInitial } from '../../utils';
import editIcon from '../../assets/edit_dark.svg';
import styles from './ProfileDialog.module.css';
import { dialogPaths } from '../../constants';
import premiumIcn from '../../assets/premium_icon.svg';
import analytic, { analyticTypes } from '../../service/analytic';
import Button from '../Button/Button';
import { withStandbyLoader } from '../../routes';

function ProfileDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const profile = user?.profile;
  const account = user?.account;
  const initial = useMemo(
    () => profile && getInitial(profile?.name),
    [profile]
  );
  const isPremium = account?.subscriptions?.find(
    (item) => item.packageGroup.includePremium
  );

  useEffect(() => {
    analytic(analyticTypes.trackPage, 'Profile', { user });
  }, [user]);

  if (!user) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logout());

    if (withStandbyLoader.includes(location.pathname)) {
      navigate('/');
    }
  };

  return (
    profile && ( // hiding the profile dialog when there's no profile object when refreshing token info
      <div className={styles['profile-wrapper']}>
        {/* <p className={styles['profile-name']}>
        {user.account.name || user.account.username}
      </p>
      <p className={styles['profile-email']}>{user.account.email}</p> */}
        <div className={styles['avatarBox']}>
          {profile.avatar && (
            <img
              className={styles['avatar']}
              alt='profile_pic'
              src={profile.avatar}
              width='100'
              height='100'
            />
          )}
          {!profile.avatar && (
            <span className={styles['avatar']}>{initial}</span>
          )}
          {isPremium && (
            <img
              className={styles['premiumIcon']}
              src={premiumIcn}
              width='30'
              alt='premium-icon'
            />
          )}
        </div>
        <div className={styles['profile-detail']}>
          <h3>{profile.name}</h3>
          <p className={styles['profile-email']}>
            {user.account.email || user.account.mobile || user.account.username}
          </p>
          <Button
            onClick={() => {
              navigate('/profile');
            }}
            textColor='dark'
            shape='rounded'
            bgColor='sushiroll'
          >
            <img src={editIcon} style={{ marginRight: '0.5rem' }} alt='icon' />
            Edit Profile
          </Button>
        </div>
        <div className={styles['profile-settings']}>
          {dialogPaths.map((item) => {
            return (
              <Link to={item.path} key={item.path}>
                <p>{item.name}</p>
              </Link>
            );
          })}
        </div>
        <p className={styles['logout']} onClick={handleLogout}>
          Log out
        </p>
      </div>
    )
  );
}

export default ProfileDialog;
