/* eslint-disable no-console */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
/* global videojs */
import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, usePrevious } from '../../hooks';
import Loading from '../Loading/Loading';
import ResumePopup from '../ResumePopup';
import { getDetailPoster, secondsToTime } from '../../utils';
import PlayerSubscription from './PlayerSubscription';
import VideoInterval from './VideoInterval';

import './CustomControlButton/CustomControlButton';
import pallyCon from '../../videojs-fairplay-support';
import videoPlaying from './utils';

import styles from './VideoPlayer.module.css';
import { errorCode } from '../../constants';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

const VideoPlayer = ({
  videoInfo,
  access,
  onDismissPlayer,
  handlePlayNext,
  handleUpdateHistory,
  setShouldGetHistory,
  historyData,
  autoplay = false,
  handleEndHistory,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const [requireRedraw, setRequireRedraw] = useState(false);
  const [showBackIcon, setShowIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [showResumePopup, setShowResumePopup] = useState(false);
  const [watchedLength, setWatchedLength] = useState();
  const [mediaStart, setMediaStart] = useState(false);
  const [runInterval, setRunInterval] = useState(false);
  const lockResumePopup = useRef(false);
  const player = useRef();
  const videoNode = useRef();
  const pausing = useRef();
  const bitrateLoger = useRef();
  const prevVideoID = usePrevious(videoInfo && videoInfo.id);
  const user = useSelector((state) => state.auth.user);

  const updateHistoryCallback = useCallback(() => {
    if (player.current && mediaStart) {
      handleUpdateHistory(
        player.current.currentTime(),
        player.current.duration()
      );
    }
  }, [handleUpdateHistory, mediaStart]);

  const handleResume = useCallback(() => {
    player.current.autoplay('any');
    setShowResumePopup(false);
    lockResumePopup.current = true;
    player.current.currentTime(watchedLength);
  }, [watchedLength]);

  const handleStartOver = useCallback(() => {
    player.current.autoplay('any');
    setShowResumePopup(false);
    lockResumePopup.current = true;
    player.current.currentTime(0);
  }, []);

  const handleMediaStart = useCallback(
    (e) => {
      console.log('video started', e);
      setMediaStart(true);
      setRunInterval(true);

      if (pausing.current) {
        console.log('resume');
        pausing.current = false;

        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id
        };
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
          {
            params: paramsEvent,
            user
          }
        );
      }

      setShowIcon(true);
    },
    [videoInfo, user]
  );

  const handleMediaPause = useCallback(() => {
    if (!player.current.seeking()) {
      pausing.current = true;
      setRunInterval(false);

      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id
      };
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE, {
        params: paramsEvent,
        user
      });
    }
  }, [videoInfo, user]);

  const handleDurationChange = useCallback(() => {
    if (historyData && historyData.mediaId === videoInfo.id) {
      const isPlaying = videoPlaying.call(player.current);
      if (isPlaying) return;

      const mediaDuration = player.current.duration();
      const historyPercentage = historyData.position.percentage;
      const historyPosition = (mediaDuration * historyPercentage) / 100;

      if (!isNaN(historyPosition)) {
        if (player.current.currentTime() > historyPosition) return;

        // force start over
        if (historyPercentage <= 5 || historyPercentage >= 95) {
          player.current.autoplay('any');
          return setWatchedLength(0);
        }

        setWatchedLength(historyPosition);
        if (!lockResumePopup.current) {
          return setShowResumePopup(true);
        }
      }
    } else {
      player.current.autoplay('any');
    }
  }, [historyData, videoInfo]);

  const handleLoadedMetadata = useCallback(() => {
    const levels = player.current.qualityLevels();
    const bitrates =
      player.current.dash.mediaPlayer.getBitrateInfoListFor('video');
    console.log('dash player', bitrates);
    const toggleQuality = function (enable) {
      console.log(this.id, enable);
      if (enable === undefined) {
        return this.isEnabled_;
      }

      if (enable === this.isEnabled_) {
        return;
      }

      if (enable === true || enable === false) {
        this.isEnabled_ = enable;
      }
    };
    const availableQualities = bitrates.map(({ height, bitrate, width }) => {
      return {
        id: height.toString(),
        height,
        width,
        bandwidth: bitrate,
        bitrate,
        isEnabled_: true,
        enabled: toggleQuality
      };
    });

    availableQualities.forEach((quality) => {
      levels.addQualityLevel(quality);
    });

    const mplayer = player.current.dash.mediaPlayer;
    const mediaState = player.current.vhs;
    console.log('media vhs state', mediaState);
    bitrateLoger.current = setInterval(() => {
      const streamp = mplayer.getActiveStream();
      const streamInfo = streamp.getStreamInfo();
      const dashMetric = mplayer.getDashMetrics();
      const dashAdapter = mplayer.getDashAdapter();

      if (dashMetric && streamInfo) {
        const periodIdx = streamInfo.index;
        const repSwitch = dashMetric.getCurrentRepresentationSwitch(
          'video',
          true
        );
        const bufferLevel = dashMetric.getCurrentBufferLevel('video', true);
        const bitrate = repSwitch
          ? Math.round(
              dashAdapter.getBandwidthForRepresentation(
                repSwitch.to,
                periodIdx
              ) / 1000
            )
          : NaN;
        console.log(`bufferLevel ${bufferLevel} secs`);
        console.log(`reportedBitrate ${bitrate} Kbps`);
      }
    }, 1000);
  }, []);

  const handleMediaEnd = useCallback(
    async (e) => {
      const durationInSecond = videoInfo.duration * 60;
      setMediaStart(false);

      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id
      };
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
        params: paramsEvent,
        user
      });

      // send last history when duration greater than position
      handleEndHistory &&
        handleEndHistory(
          durationInSecond,
          player.current.currentTime(),
          player.current.duration()
        );

      if (!handlePlayNext) {
        setRunInterval(false);
      }

      const canPlayNext = await handlePlayNext();
      if (!canPlayNext) {
        setRunInterval(false);
      }
    },
    [handlePlayNext, videoInfo, handleEndHistory, user]
  );

  const handleMediaSeek = useCallback(() => {
    const paramsEvent = {
      contentType: videoInfo.type,
      videoID: videoInfo.id,
      elapsedTime: player.current.currentTime()
    };
    analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_SEEK, {
      params: paramsEvent,
      user
    });
  }, [videoInfo, user]);

  const handleUserActive = useCallback(() => {
    setShowIcon(true);
  }, []);

  const handleUserInActive = useCallback(() => {
    setShowIcon(false);
  }, []);

  const handleResolutionChange = useCallback(() => {
    const mediaDuration = player.current.duration();
    const historyPercentage = historyData.position.percentage;
    const historyPosition = (mediaDuration * historyPercentage) / 100;
    setWatchedLength(historyPosition);
    player.current.autoplay('any');
  }, [historyData]);

  const handleError = useCallback((e) => {
    console.log('error', e);
    setError({
      code: 'player-error',
      message: 'General Player Error!'
    });
  }, []);

  const addEventListener = useCallback(async () => {
    player.current.on('play', handleMediaStart);
    player.current.on('pause', handleMediaPause);
    // metadata has fully loaded, better than loadedmetadata
    player.current.on('durationchange', handleDurationChange);
    player.current.on('seeked', handleMediaSeek);
    player.current.on('ended', handleMediaEnd);
    player.current.on('useractive', handleUserActive);
    player.current.on('userinactive', handleUserInActive);
    player.current.on('error', handleError);
    player.current.on('resolutionchange', handleResolutionChange); // Deprecated listener
    player.current.on('loadedmetadata', handleLoadedMetadata);
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleUserActive,
    handleUserInActive,
    handleMediaEnd,
    handleResolutionChange, // Deprecated listener
    handleError,
    handleLoadedMetadata
  ]);

  const removeEventListener = useCallback(() => {
    if (player.current) {
      player.current.off('play', handleMediaStart);
      player.current.off('pause', handleMediaPause);
      player.current.off('durationchange', handleDurationChange);
      player.current.off('seeked', handleMediaSeek);
      player.current.off('ended', handleMediaEnd);
      player.current.off('useractive', handleUserActive);
      player.current.off('userinactive', handleUserInActive);
      player.current.off('error', handleError);
      player.current.off('resolutionchange', handleResolutionChange);
      player.current.off('loadedmetadata', handleLoadedMetadata);
    }
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleMediaEnd,
    handleUserActive,
    handleUserInActive,
    handleError,
    handleLoadedMetadata,
    handleResolutionChange
  ]);

  const createPlayerInstance = useCallback(
    async (videoInfo) => {
      const poster = getDetailPoster(videoInfo);

      setLoading(false);

      if (!access || access.error) throw new Error();
      const { drmToken, stream, streamType } = access;
      const updateKeySystemSource = (source) => {
        source.keySystemOptions = [
          {
            name: 'com.widevine.alpha',
            options: {
              serverURL: 'https://license.pallycon.com/ri/licenseManager.do',
              httpRequestHeaders: {
                'pallycon-customdata-v2': drmToken
              }
            }
          }
        ];
        return source;
      };
      const dashCallback = (player, mediaPlayer) => {
        if (videojs && videojs.log) {
          mediaPlayer.on('log', (event) => {
            // videojs.log(event.message) // Uncomment if you need the dash log
          });
        }
      };
      const videoOptions = {
        controls: true,
        controlBar: {
          pictureInPictureToggle: false
        },
        autoplay,
        poster,
        fluid: true,
        responsive: true,
        aspectRatio: '16:9',
        sources: streamType === 'dash' && [
          {
            src: stream,
            type: 'application/dash+xml'
          }
        ],
        html5: {
          nativeCaptions: false,
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          vhs: {
            withCredential: true,
            useCueTags: true,
            overrideNative: true,
            useDevicePixelRatio: true
          }
        },
        // make the text track settings dialog not initialize
        textTrackSettings: false,
        plugins: {}
      };

      videojs.Html5DashJS.hook('updatesource', updateKeySystemSource);
      videojs.Html5DashJS.hook('beforeinitialize', dashCallback);

      // instantiate Video.js
      player.current = videojs(
        videoNode.current,
        videoOptions,
        function onPlayerReady() {
          console.log('onPlayerReady', this);
          setPlayerReady(true);
          const _player = this.player();

          // hls drm setup
          if (streamType === 'hls') {
            pallyCon.fairplay({
              license_url: 'https://license.pallycon.com/ri/licenseManager.do',
              player: _player,
              pallycon_custom_data: drmToken,
              certificate_uri:
                'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=LZ9U',
              content_url: stream
            });
          }

          const paramsEvent = {
            contentType: videoInfo.type,
            videoID: videoInfo.id
          };
          analytic(
            analyticTypes.event,
            analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH,
            {
              params: paramsEvent,
              user
            }
          );
        }
      );

      player.current.httpSourceSelector();

      player.current.qualityLevels().on('change', function () {
        const qualityLevels = player.current.qualityLevels();
        const quality = qualityLevels[qualityLevels.selectedIndex];
        const mediaPlayer = player.current.dash.mediaPlayer;

        // if quality selected is autoplay
        if (!quality) {
          mediaPlayer.updateSettings({
            streaming: {
              flushBufferAtTrackSwitch: true,
              stableBufferTime: 5,
              bufferTimeAtTopQualityLongForm: 5,
              abr: {
                autoSwitchBitrate: true,
                initialBitrate: 0,
                initialRepresentationRatio: 0
              }
            }
          });
          mediaPlayer.setQualityFor('video', qualityLevels.length - 1);
          return false;
        }

        // if user select any resolution bitrate
        mediaPlayer.updateSettings({
          streaming: {
            flushBufferAtTrackSwitch: true,
            stableBufferTime: 5,
            bufferTimeAtTopQualityLongForm: 5,
            abr: {
              autoSwitchBitrate: false,
              initialBitrate: 0,
              initialRepresentationRatio: 0
            }
          }
        });
        mediaPlayer.setQualityFor('video', qualityLevels.selectedIndex);
      });

      handleResolutionChange();
      player.current.hlsQualitySelector();
      player.current.getChild('controlBar').addChild('RewindButton', {}, 1);
      player.current
        .getChild('controlBar')
        .addChild('FastForwardButton', {}, 2);
    },
    [access, autoplay, handleResolutionChange, user]
  );

  // setup player
  useEffect(() => {
    const setupPlayer = async () => {
      try {
        await createPlayerInstance(videoInfo);
      } catch (error) {
        setLoading(false);
        if (!videoInfo) {
          return setError({
            code: errorCode.MISSING_VIDEO,
            message: 'Missing video asset to play.'
          });
        }
        if (
          access.error &&
          access.error.response &&
          access.error.response.data &&
          access.error.response.data.error
        ) {
          return setError(access.error.response.data.error);
        }
        return setError(error.response.data.error);
      }
    };

    setupPlayer();
    analytic(analyticTypes.trackPage, 'VODPlayer', { user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // add and update event listener
  useEffect(() => {
    if (isPlayerReady) {
      addEventListener && addEventListener();
    }
    return () => {
      removeEventListener();
    };
  }, [isPlayerReady, addEventListener, removeEventListener]);

  // handle play video when source change
  useEffect(() => {
    const playNextSource = async (videoInfo) => {
      try {
        await createPlayerInstance(videoInfo);
      } catch (error) {
        setLoading(false);
        if (error.response) return setError(error.response.data.error);
      }
    };

    if (videoInfo) {
      if (prevVideoID && videoInfo.id !== prevVideoID) {
        // remove player node for re init again
        setRequireRedraw(true);
        setPlayerReady(false);
        setMediaStart(false);
        playNextSource(videoInfo);
      }
    }
  }, [videoInfo, prevVideoID, createPlayerInstance]);

  /* https://github.com/videojs/video.js/issues/4970 */
  useEffect(() => {
    if (requireRedraw) {
      setRequireRedraw(false);
    }
  }, [requireRedraw]);

  // track on unmount
  useEffect(() => {
    return () => {
      clearInterval(bitrateLoger);
      if (player.current && videoInfo) {
        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          elapsedTime: player.current.currentTime()
        };
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT,
          {
            params: paramsEvent,
            user
          }
        );
      }
    };
  }, [videoInfo, user]);

  useEffect(() => {
    return async () => {
      if (player.current) {
        const currentTime = player.current.currentTime();
        const duration = player.current.duration();
        clearInterval(bitrateLoger.current);
        await handleUpdateHistory(currentTime, duration);
        setShouldGetHistory(true);
      }
    };
  }, [handleUpdateHistory, setShouldGetHistory]);

  // destroy player on unmount
  useEffect(() => {
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  // send heartbeat when user start media for the first time
  useEffect(() => {
    const durationInSecond = videoInfo.duration * 60;
    if (mediaStart && isPlayerReady && player.current && !pausing.current) {
      handleUpdateHistory(0, durationInSecond);
    }
  }, [
    mediaStart,
    player,
    pausing,
    isPlayerReady,
    videoInfo,
    handleUpdateHistory
  ]);

  if (error) {
    return (
      <PlayerSubscription
        onDismissPlayer={onDismissPlayer}
        otherProps={otherProps}
        error={error}
      />
    );
  }

  return loading ? (
    <Loading height='100vh' />
  ) : (
    <div className={showResumePopup ? styles['showResumePopup'] : ''}>
      {runInterval && (
        <VideoInterval updateHistoryCallback={updateHistoryCallback} />
      )}
      {!!showBackIcon && (
        <div className='vjs-dismiss-btn-wrapper' onClick={onDismissPlayer}>
          <i className='vjs-dismiss-player-icon' />
        </div>
      )}
      {!requireRedraw && (
        <div data-vjs-player>
          <video
            id='video'
            ref={videoNode}
            className='video-js vjs-default-skin vjs-big-play-centered'
          />
        </div>
      )}
      {showResumePopup && (
        <ResumePopup
          watchedLength={secondsToTime(watchedLength)}
          onResume={handleResume}
          onStartOver={handleStartOver}
        />
      )}
    </div>
  );
};

export default memo(withRouter(VideoPlayer));
