/* eslint-disable no-unused-vars */
import { messaging } from '../../service/firebase';
import { generateErrorDetail } from '../../utils';

const timeout = 3000;
export const showToastNotif = (type, message) => (dispatch, _getState) => {
  dispatch({
    type: 'SHOW_TOAST_NOTIF',
    payload: {
      show: true,
      type,
      message
    }
  });

  setTimeout(() => {
    dispatch({
      type: 'SHOW_TOAST_NOTIF',
      payload: {
        show: false,
        type: '',
        message: ''
      }
    });
  }, timeout);
};

export const requestFCMToken = () => async (dispatch, getState) => {
  try {
    const token = await messaging.getToken();

    dispatch({
      type: 'SET_FCM_TOKEN',
      payload: token
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch(
      showToastNotif(
        'error',
        errorData || 'Some error occured, please refresh the page!'
      )
    );
  }
};

export const showNotification = (data) => (dispatch, getSTate) => {
  dispatch({
    type: 'SHOW_NOTIFICATION',
    payload: data
  });

  setTimeout(() => {
    dispatch({
      type: 'HIDE_NOTIFICATION',
      payload: data
    });
  }, 5000);
};

export const hideNotification = (data) => (dispatch, getSTate) => {
  dispatch({
    type: 'HIDE_NOTIFICATION',
    payload: data
  });
};
