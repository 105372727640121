/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import {
  TelegramShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TelegramIcon,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon
} from 'react-share';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ShareModal.module.css';

import icClose from '../../assets/ic-close.svg';
import { showToastNotif } from '../../redux/actions/notification';
import Button from '../Button/Button';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

const shareItems = [
  {
    title: 'Twitter',
    icon: TwitterIcon,
    component: TwitterShareButton
  },
  {
    title: 'Facebook',
    icon: FacebookIcon,
    component: FacebookShareButton
  },
  {
    title: 'Telegram',
    icon: TelegramIcon,
    component: TelegramShareButton
  },
  {
    title: 'WhatsApp',
    icon: WhatsappIcon,
    component: WhatsappShareButton
  }
];

function ShareModal({ onCloseModal, type, id, open }) {
  const dispatch = useDispatch();
  const linkRef = useRef();
  const [shareUrl, setShareUrl] = useState('');
  const profileId = useSelector((state) => state?.auth?.user?.profile?.id);
  // eslint-disable-next-line no-shadow
  const getTitle = (type) => {
    switch (type) {
      case 'profile':
        return profileId === id ? 'Share My Profile' : 'Share This Profile';
      default:
        return 'Share';
    }
  };

  const handleCopyToClipboard = () => {
    const element = linkRef?.current?.value;
    if (element) {
      navigator.clipboard.writeText(element);
      dispatch(showToastNotif('success', 'Successfully copied to clipboard'));
    }
  };

  useEffect(() => {
    // Update the share URL when the component mounts or when 'id' changes
    setShareUrl(id ? `${window.location.origin}/${type}/${id}` : '');
  }, [id, type]);

  return (
    <GeneralNotifModal open={open} contentClass={styles['modalWrapper']}>
      <div className={styles['modalWrapper']}>
        <div className={styles['modalBox']}>
          <div className={styles['modalHeader']}>
            <h3>{getTitle(type)}</h3>
            <img src={icClose} alt='close' onClick={onCloseModal} />
          </div>
          <div className={styles['shareSection']}>
            <div className={styles['shareItems']}>
              {shareItems.map((item, i) => {
                const { icon: Icon, component: ShareComponent } = item;

                return (
                  <ShareComponent
                    key={i}
                    className={styles['shareItem']}
                    url={`${window.location.origin}/${type}/${id}`}
                  >
                    <Icon size={50} round />
                  </ShareComponent>
                );
              })}
            </div>
          </div>
          <div className={styles['shareUrl']}>
            <input
              className={styles['editInput']}
              ref={linkRef}
              value={shareUrl}
              onChange={() => {}}
            />
            <Button
              className={styles['copyButton']}
              onClick={handleCopyToClipboard}
              width='50px'
            >
              Copy
            </Button>
          </div>
        </div>
      </div>
    </GeneralNotifModal>
  );
}

export default ShareModal;
