const initialState = {
  data: null,
  meta: null,
  dataCategoryPlaylist: null,
  loading: false,
  err: null
};

// eslint-disable-next-line default-param-last
export default function playlistReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'GET_PLAYLIST_DETAIL':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_PLAYLIST_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data:
          page === 1
            ? payload.data
            : {
                ...state.data,
                items: [...state.data.items, ...payload.data]
              },
        meta: {
          ...state.meta,
          ...payload.meta
        },
        page,
        dataCategoryPlaylist: {
          ...state.dataCategoryPlaylist,
          [payload?.data?.titleLocalized?.toLowerCase()]: payload
        }
      };

    case 'GET_PLAYLIST_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload?.data,
        meta: payload?.meta,
        page
      };

    case 'GET_PLAYLIST_DETAIL_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'RESET_PLAYLIST_DETAIL':
      return {
        ...initialState
      };

    default:
      return state;
  }
}
