/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './SmartBanner.module.css';
import { detectAgent } from '../../utils';
import Button from '../Button/Button';
import logo from '../../assets/app-logo.svg';
import icClose from '../../assets/ic-close.svg';
import { getAppSettings } from '../../redux/actions/settings';

function SmartBanner() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = detectAgent('mobile');
  const currentDate = new Date();
  const appSettings = useSelector((state) => state.appSettings?.settings);
  const title = appSettings?.['smartbanner.title']
    ? appSettings?.['smartbanner.title']
    : 'Placeholder Title';
  const description = appSettings?.['smartbanner.description']
    ? appSettings?.['smartbanner.description']
    : 'Placeholder Description';
  const buttonLabel = appSettings?.['smartbanner.buttonlabel']
    ? appSettings?.['smartbanner.buttonlabel']
    : 'Placeholder Button';
  const campaignName = appSettings?.['smartbanner.campaignName']
    ? appSettings?.['smartbanner.campaignName']
    : 'default';
  const [showBanner, toggleDialog] = useState(false);

  const closeBanner = () => {
    toggleDialog((open) => !open);
    const enabledTime = currentDate.setHours(currentDate.getHours() + 1);
    localStorage.setItem('disableBanner', enabledTime);
  };

  useEffect(() => {
    if (appSettings) {
      const exclusionArray = appSettings?.['smartbanner.exclusion'].split(',');
      const excludedPage = exclusionArray.includes(location.pathname);
      if (!excludedPage) {
        toggleDialog(appSettings?.['smartbanner.active'] === 'true');
      } else {
        toggleDialog(false);
      }
    }
  }, [appSettings, location]);
  useEffect(() => {
    dispatch(
      getAppSettings([
        'smartbanner.campaignName',
        'smartbanner.title',
        'smartbanner.description',
        'smartbanner.buttonlabel',
        'smartbanner.active',
        'smartbanner.exclusion'
      ])
    );
  }, [dispatch]);
  useEffect(() => {
    const enabledTime = new Date(0).setUTCSeconds(
      localStorage.getItem('disableBanner')
    ); // The 0 there is the key, which sets the date to the epoch
    const currentTime = new Date(0).setUTCSeconds(currentDate);
    if (enabledTime && currentTime) {
      toggleDialog(false);
      if (enabledTime < currentTime) {
        toggleDialog(true);
        localStorage.removeItem('disableBanner');
      }
    }
  }, [currentDate]);

  useEffect(() => {
    if (window?.airbridge) {
      window.airbridge.setDownloads({
        buttonID: 'app_download',
        defaultParams: {
          campaign: campaignName
        }
        // for additional params enable line below
        // ctaParams: {
        //   cta_param_1: '1',
        // },
      });
    }
  });

  if (isMobile && showBanner) {
    return (
      <div className={styles['wrapper']}>
        <section className={styles['section1']}>
          <img src={logo} alt='logo' className={styles['logo']} />
          <div className={styles['contentContainer']}>
            <span className={styles['title']}>{title}</span>
            <span className={styles['description']}>{description}</span>
          </div>
          <div onClick={closeBanner}>
            <img src={icClose} className={styles['btnClose']} alt='close' />
          </div>
        </section>
        <section>
          <Button className={styles['btn']} id='app_download'>
            {buttonLabel}
          </Button>
        </section>
      </div>
    );
  }
  return <div />;
}

export default SmartBanner;
