import React from 'react';

import styles from './Notification.module.css';

function NotificationPermission({ onAllow, onDeny }) {
  const handleDeny = () => {
    localStorage.setItem('isNotifPermissionEnabled', 'false');
    onDeny();
  };

  return (
    <div className={styles['wrapper']}>
      <h3>Click "Allow" to get notification from Sushiroll.</h3>
      <span>You can disable notifications anytime from your browser.</span>
      <div className={styles['actions']}>
        <div className={styles['deny']} onClick={handleDeny}>
          Deny
        </div>
        <div className={styles['allow']} onClick={onAllow}>
          Allow
        </div>
      </div>
    </div>
  );
}

export default NotificationPermission;
