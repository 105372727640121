const initialState = {
  data: null,
  continueWatching: [],
  noDataContinueWatching: false,
  meta: null,
  loading: false,
  page: null,
  err: null,
  dataComplete: false
};

export default function historiesReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'GET_HISTORIES':
    case 'GET_CONTINUE_WATCHING':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_HISTORIES_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };

    case 'GET_HISTORIES_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload.data,
        meta: payload.meta,
        page
      };

    case 'GET_HISTORIES_COMPLETE':
      return {
        ...state,
        loading: false,
        dataComplete: true
      };

    case 'GET_HISTORIES_FAILURE':
    case 'GET_CONTINUE_WATCHING_ERROR':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_CONTINUE_WATCHING_SUCCESS':
      return {
        ...state,
        loading: false,
        continueWatching: payload,
        meta: payload?.length,
        noDataContinueWatching: !payload?.length
      };

    case 'GET_CONTINUE_WATCHING_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false
      };

    case 'RESET_CONTINUE_WATCHING_STATE':
      return {
        ...initialState
      };

    default:
      return state;
  }
}
