import React from 'react';
import get from 'lodash/get';

import { Link } from 'react-router-dom';
import styles from './FeaturedCollectionItem.module.css';

function FeaturedCollectionItem({ item }) {
  const thumbnail = get(item, 'attachments.thumbnail_portrait.fileUrl');

  return (
    <div className={styles['wrapper']}>
      <Link
        to={`/playlists/${item.id}`}
        key={item.id}
        className={styles['featuredItem']}
      >
        <div className={styles['imgWrapper']}>
          {!!thumbnail && <img alt={item.id} src={thumbnail} />}
        </div>
        <div className={styles['featureName']}>{item.titleLocalized}</div>
      </Link>
    </div>
  );
}

export default FeaturedCollectionItem;
