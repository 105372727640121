export const errorCode = {
  INVALID_SESSION_TOKEN: 'gfu4010104',
  MISSING_VIDEO: 'missing_video',
  INVALID_PARAMETERS: 'gfx4000003',
  EMAIL_ALREADY_EXIST: 'gfu4000202',
  INVALID_CREDENTIAL: 'gfu4000201',
  NO_SUBSCRIPTION: 'gfc4030205',
  INVALID_OTP: 'gfu4010501',
  INVALID_NUMBER: 'gfx4000601',
  TOO_MANY_ATTEMPTS: 'gfu4030503',
  TOO_QUICK_RETRY: 'gfu4030502',
  INVALID_OTP_CODE: 'gfu4000504'
};

export const social = {
  SUSHIROLL_FACEBOOK: 'https://www.facebook.com/HelloSushiroll',
  SUSHIROLL_INSTAGRAM: 'https://www.instagram.com/sushiroll_anime/',
  SUSHIROLL_WHATSAPP: 'https://wa.me/6289513634343',
  SUSHIROLL_TWITTER: 'https://twitter.com/sushiroll_anime',
  SUSHIROLL_EMAIL: 'hello@sushiroll.co.id',
  SUSHIROLL_NUMBER: '+62 895-1363-4343'
};

export const appURL = {
  APP_STORE: '//apps.apple.com/id/app/sushiroll/id1467850665',
  GOOGLE_PLAY:
    '//play.google.com/store/apps/details?id=com.application.sushiroll'
};

export const dialogPaths = [
  {
    name: 'My Subscription',
    path: '/subscription-history/ongoing'
  },
  {
    name: 'Redeem Voucher',
    path: '/voucher'
  },
  {
    name: 'My List',
    path: '/my-list'
  },
  {
    name: 'Watch History',
    path: '/history'
  },
  {
    name: 'Transaction History',
    path: '/transaction-history'
  },
  {
    name: 'Privacy and Data Settings',
    path: '/profile/privacy'
  }
];
