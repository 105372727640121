/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-continue */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CSSTransition from 'react-transition-group/CSSTransition';
import ProfileDialog from '../ProfileDialog/ProfileDialog';
import UserConsent from '../LoginDialog/UserConsent';
import Button from '../Button/Button';
import { useOutsideHandle } from '../../hooks';
import {
  authPopup,
  getAccountInfo,
  loginByEmail,
  logout
} from '../../redux/actions/auth';
import { detectAgent, generateErrorDetail, parseQuery } from '../../utils';
import AnimatedModal from '../Modal/AnimatedModal';
import LoginDialog from '../LoginDialog/LoginDialog';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import logo from '../../assets/sushiroll_logo.svg';
import icSearch from '../../assets/ic-search.svg';
import icUser from '../../assets/ic-user.svg';
import ValidateSessionNotification from '../Notification/ValidateSessionNotification';
import styles from './Header.module.css';
import ToastNotification from '../Notification/toast';
import MobileProfileDialog from '../ProfileDialog/MobileProfileDialog';
import DefaultPassword from '../LoginDialog/DefaultPassword';

const pageOrderCondition = ['movies', 'series', 'animation'];
const sortPage = (page1, page2) => {
  for (let i = 0; i < pageOrderCondition.length; i++) {
    const item = pageOrderCondition[i];
    if (page1 === item) return -1;
    if (page2 === item) return 1;
    continue;
  }
};

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, authPopupStatus, token, sessionStatus, showConsent } =
    useSelector((state) => state.auth);
  const {
    msisdn,
    type: authType,
    email,
    isNewUser,
    password
  } = parseQuery(location?.search);
  const pageData = useSelector((state) => state.page.data);
  const dispatch = useDispatch();
  const authenticated = !!user;
  const profile = user && user.profile;
  const [showProfile, toggleDialog] = useState(false);
  const [showSearchField, toggleSearch] = useState(false);
  const [showDefaultPassword, setShowDefaultPassword] = useState(false);
  const [showSearchError, setSearchError] = useState(null);
  const dialogRef = useRef();
  const formRef = useRef();
  const searchRef = useRef();
  const [defaultPassword, setDefaultPassword] = useState(password);

  const toggleProfile = () => {
    toggleDialog((open) => !open);
  };
  const menu = useMemo(() => {
    return pageData
      .filter((item) => item.friendlyId !== 'home')
      .sort((firstPage, secondPage) => {
        const { friendlyId: firstId } = firstPage;
        const { friendlyId: secondId } = secondPage;
        const sortValue = sortPage(firstId, secondId);
        return sortValue || 0;
      })
      .map((item) => {
        const name = item.titleLocalized;
        const { friendlyId } = item;
        return {
          name,
          path: `/${friendlyId}`
        };
      });
  }, [pageData]);
  const isMobile = detectAgent('mobile');
  const handleClickOutsideDialog = useCallback(
    (target) => {
      // ignore user-profile-icon to use its onClick func
      if (target.dataset.iconName === 'user-profile-icon') {
        return;
      }
      if (showProfile) {
        toggleProfile();
      }
    },
    [showProfile]
  );

  const handleInputChange = (event) => {
    setSearchError(false);
    const inputValue = event.target.value;
    // Remove non-alphanumeric characters
    const sanitizedInput = inputValue.replace(/[^a-z0-9\s]/gi, '');
    searchRef.current.value = sanitizedInput;
  };

  const handleClickOutsideSearchField = useCallback(() => {
    if (showSearchField) {
      toggleSearch(!showSearchField);
    }
  }, [showSearchField]);

  useOutsideHandle(dialogRef, handleClickOutsideDialog);
  useOutsideHandle(formRef, handleClickOutsideSearchField);

  useLayoutEffect(() => {
    if (showSearchField) {
      searchRef.current.focus();
    }
  }, [showSearchField]);

  const handleClickSearch = () => {
    toggleSearch(!showSearchField);
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    if (searchRef.current.value.length < 5) {
      setSearchError('Input must be at least 5 characters');
      return;
    }
    navigate(`/search?keyword=${searchRef.current.value}`);
  };

  useEffect(() => {
    const handleAutoLogin = async (email, password) => {
      try {
        await dispatch(loginByEmail(email, decodeURIComponent(password)));
      } catch (err) {
        const errorData = generateErrorDetail(err);
        return errorData;
      }
    };

    const formatMobileNumber = (number) => {
      // Remove all spaces
      let mobile = number.replace(/ /g, '');

      // If string starts with +, drop first 3 characters
      if (number.slice(0, 1) === '+') {
        mobile = mobile.substring(3);
      }

      // If string starts with 62, drop first 2 characters
      if (number.slice(0, 2) === '62') {
        mobile = mobile.substring(2);
      }

      // If string starts with 0, drop first 1 characters
      if (number.slice(0, 1) === '0') {
        mobile = mobile.substring(1);
      }
      return mobile;
    };
    if (isNewUser && (msisdn || email)) {
      if (isNewUser === 'true' && email && password) {
        setDefaultPassword(password);
        if (!showDefaultPassword) {
          handleAutoLogin(email, decodeURIComponent(password));
          setShowDefaultPassword(true);
        } else {
          const queryParams = '';
          navigate({
            search: queryParams,
            replace: true
          });
        }
      } else if (msisdn) {
        if (token) {
          if (
            user?.account?.mobile &&
            formatMobileNumber(user.account.mobile) ===
              formatMobileNumber(msisdn)
          ) {
            const queryParams = '';
            navigate({
              search: queryParams,
              replace: true
            });
            dispatch(getAccountInfo({ refreshAccountInfo: true }));
          } else {
            dispatch(logout());
          }
        } else if (!token) {
          dispatch(
            authPopup.show({
              showClose: true,
              state: authType,
              type: msisdn ? 'phone' : 'email',
              showFooter: false
            })
          );
        } else {
          const queryParams = '';
          navigate({
            search: queryParams,
            replace: true
          });
        }
      } else if (isNewUser === 'false' && email) {
        if (!token) {
          dispatch(
            authPopup.show({
              showClose: false,
              state: authType,
              type: 'email',
              showFooter: false
            })
          );
        } else if (user?.account?.email && email) {
          dispatch(getAccountInfo({ refreshAccountInfo: true }));
        } else {
          dispatch(logout());
        }
      }
    }
  }, [isNewUser, msisdn, email, dispatch, authType, token, password, navigate]);

  return (
    <div className={styles['header']}>
      <ToastNotification />
      {/* <SideMenu open={showSideMenu} menu={menu} /> */}
      {/* <img */}
      {/*  className={`${styles['side-menu-toggle']} show-mobile`} */}
      {/*  src={showSideMenu ? icClose : icSideMenu} */}
      {/*  alt='side-menu-toggle' */}
      {/*  onClick={() => toggleSideMenu(!showSideMenu)} */}
      {/* /> */}
      {isMobile && showSearchField ? null : (
        <Link to='/'>
          <img src={logo} alt='logo' className={styles['logo']} />
        </Link>
      )}
      <ul className={`${styles['menu']} hide-mobile`}>
        {menu.map((item) => {
          const selected = location.pathname === item.path;
          return (
            <li key={item.name} className={selected ? styles['selected'] : ''}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          );
        })}
        {menu.length > 0 && (
          <li
            className={location.pathname === '/live' ? styles['selected'] : ''}
          >
            <Link to='/live'>Live</Link>
          </li>
        )}
      </ul>

      <ul className={styles['sub-menu']}>
        <li
          className={
            showSearchField
              ? `${styles['search']} ${styles['search-opened']}`
              : styles['search']
          }
        >
          <img
            src={icSearch}
            alt='icon'
            onClick={handleClickSearch}
            className={styles['search-img']}
          />
          <CSSTransition
            in={showSearchField}
            timeout={400}
            classNames='expand-rtl'
            unmountOnExit
          >
            <form
              className={styles['search-form']}
              ref={formRef}
              onSubmit={handleSubmitSearch}
            >
              <i
                className={styles['search-media-icon']}
                onClick={handleSubmitSearch}
              />
              <input
                ref={searchRef}
                placeholder='Search Term'
                className={`${styles['search-media-field']} ${
                  showSearchError && styles['error']
                }`}
                onChange={handleInputChange}
                onBlur={() => {
                  setSearchError(false);
                }}
              />
              {showSearchError && (
                <div className={styles['error-message']}>{showSearchError}</div>
              )}
            </form>
          </CSSTransition>
        </li>
        {isMobile && showSearchField ? null : authenticated ? (
          <li>
            <img
              src={profile && profile.avatar ? profile.avatar : icUser}
              width={24}
              height={24}
              className={styles['user-profile-icon']}
              alt='icon'
              data-icon-name='user-profile-icon'
              onClick={toggleProfile}
            />
          </li>
        ) : (
          <li>
            <img
              src={profile && profile.avatar ? profile.avatar : icUser}
              width={24}
              height={24}
              className={styles['user-profile-icon']}
              alt='icon'
              data-icon-name='user-profile-icon'
              onClick={() => dispatch(authPopup.show())}
            />
          </li>
        )}
        {isMobile && showSearchField && (
          <li>
            <Button
              small
              width='80px'
              style={{ margin: '0px' }}
              outlined
              onClick={() => toggleSearch()}
            >
              Cancel
            </Button>
          </li>
        )}
      </ul>

      <AnimatedModal duration={10} open={showConsent} opacity={0.5}>
        <GeneralNotifModal open={showConsent}>
          <UserConsent />
        </GeneralNotifModal>
      </AnimatedModal>

      <AnimatedModal duration={10} open={showDefaultPassword} opacity={0.5}>
        <GeneralNotifModal open={showDefaultPassword}>
          <DefaultPassword
            onClose={() => setShowDefaultPassword(false)}
            password={defaultPassword}
          />
        </GeneralNotifModal>
      </AnimatedModal>

      <AnimatedModal duration={10} open={authPopupStatus} opacity={0.5}>
        <GeneralNotifModal open={authPopupStatus}>
          <LoginDialog onClose={() => dispatch(authPopup.close())} />
        </GeneralNotifModal>
      </AnimatedModal>

      <CSSTransition
        in={showProfile}
        timeout={300}
        classNames='fade'
        unmountOnExit
      >
        <div className={styles['profile-dialog-wrapper']} ref={dialogRef}>
          {isMobile ? (
            <MobileProfileDialog closeDialog={toggleProfile} />
          ) : (
            <ProfileDialog />
          )}
        </div>
      </CSSTransition>

      <ValidateSessionNotification open={sessionStatus === 'failed'} />
    </div>
  );
}

export default Header;
