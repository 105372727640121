import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from '../Slider/Slider';
import SliderItem from '../Slider/Item';
import { getMediaInfo } from '../../redux/actions/media';
import { detectAgent, generatePlaylistUrl } from '../../utils';
import ItemDetail from '../MediaDetail/ItemDetail';

import styles from './Section.module.css';
import FeaturedCollectionItem from '../CollectionItem/FeaturedCollectionItem';

function Section({
  id,
  name,
  sectionData,
  allowSeeAll = true,
  onChannelSelected,
  itemType = 'default',
  ...props
}) {
  const isMobile = detectAgent('mobile');
  const data = sectionData;
  const preloadTimeout = useRef();
  const dispatch = useDispatch();
  /* -- enable those code if want to support fetch more feature -- */
  /* --start--
  const [data, setData] = useState(sectionData)
  const [currentPage, setPage] = useState(1)
  const [fetchComplete, setFetchComplete] = useState(false)
  const dispatch = useDispatch()
  const fetchConfig = useMemo(() => {
    return dispatch(getPlaylistItemsConfig(id, 1))
  }, [dispatch, id])
  const [response, , fetchSource] = useDidMountFetch(fetchConfig)

  useEffect(() => {
    if (response && response.data) {
      setData(response.data)
    }
  }, [response])

  const fetchMore = async () => {
    fetchSource.current = axios.CancelToken.source()
    const fetchConfig = dispatch(getPlaylistItemsConfig(id, currentPage + 1))
    const response = await axios({
      ...fetchConfig,
      cancelToken: fetchSource.current.token
    })
    const totalPages = response.data.meta.totalPages
    if (currentPage === totalPages - 1) {
      setFetchComplete(true)
    }
    setPage(currentPage + 1)
    setData(data.concat(response.data.entries))
  } 
  --end-- */

  const preloadDetail = (media) => () => {
    // eslint-disable-next-line no-shadow
    const { id, type } = media;
    preloadTimeout.current = setTimeout(
      () => dispatch(getMediaInfo(id, type, true)),
      500
    );
  };

  const clearPreLoadDetail = () => {
    if (preloadTimeout.current) {
      clearTimeout(preloadTimeout.current);
    }
  };

  return data && data.length ? (
    <div
      className={
        props.topSection
          ? `${styles['section-container']} ${styles['top-section']}`
          : styles['section-container']
      }
    >
      {itemType !== 'featured-list' && (
        <div className={styles['section-info']}>
          <p className={styles['section-name']}>{name}</p>
          {allowSeeAll && (
            <Link to={generatePlaylistUrl(id)} state={{ titleLocalized: name }}>
              <p className={styles['see-all']}>See All</p>
            </Link>
          )}
        </div>
      )}
      <Slider
        hasArrows
        slidesToShow={isMobile ? 4 : 'auto'}
        slidesToScroll='auto'
        itemWidth={isMobile ? 115 : 185} // item 165, padding 10
        skipTrack
        exactWidth={!isMobile || (isMobile && itemType === 'featured-list')}
        /* fetchMore={fetchMore}
      fetchComplete={fetchComplete} */
        sliderId={`slider-section-${id}`}
        itemType={itemType}
      >
        {data.map((item, index) => {
          if (itemType === 'featured-list') {
            item.type = 'category';
            return (
              <ItemDetail
                key={item.id}
                item={item}
                component={FeaturedCollectionItem}
                clearPreLoadDetail={clearPreLoadDetail}
                onChannelSelected={onChannelSelected}
                preloadDetail={preloadDetail}
              />
            );
          }
          return (
            <div key={index} style={{ minWidth: isMobile ? '110px' : 'unset' }}>
              <ItemDetail
                key={item.id}
                item={item}
                component={SliderItem}
                clearPreLoadDetail={clearPreLoadDetail}
                onChannelSelected={onChannelSelected}
                preloadDetail={preloadDetail}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  ) : null;
}

export default Section;
