/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { TextContent } from '..';
import { social } from '../../constants';

import styles from './LoginDialog.module.css';
import icClose from '../../assets/ic-close.svg';
import privacyPolicyList from './TNCText';

const termUGCList = {
  id: {
    languageTitle: 'Indonesian',
    nextLanguage: 'English',
    header: ['Terms & Conditions'],
    contentList: [
      {
        title: 'Service Description',
        contents: [
          `Sushiroll is a paid service over the top that is done through wap, website, or application. Monthly subscription fees will be auto-debited, and if you will unsubscribe please contact our customer care through  ${social.SUSHIROLL_EMAIL}`,
          'Content can be accessed through the sushiroll.co.id website or through applications available on the Play Store and App Store.'
        ]
      },
      {
        title: 'Access to Sushiroll',
        contents: [
          'Account and Membership. You must register and create an account to access Sushiroll. By reading and accepting the Terms and Conditions when creating an account, you have become a member of Sushiroll. You must provide accurate and complete information during registration, and maintain the confidentiality of your password.',
          'Termination of Service. Sushiroll reserves the right at any time without prior notice, deleting, restricting or stopping user access to services if Sushiroll according to its policy discovers violations of Terms and Conditions, violations of law, regulations, or engaging in other inappropriate behavior in connection with user access to improper service.',
          'Internet use. To be able to enjoy Sushiroll, you need a minimum of 500kbps stable internet access for a small screen (mobile phone, PC / laptop) and for a big screen 5 Mbps (Chromecast, OTT box). You are fully responsible for internet costs arising from access to Sushiroll services.'
        ]
      },
      {
        title: 'Subscription Service Billing',
        contents: [
          'By starting a Sushiroll membership and providing or designating a Payment Method, you authorize Sushiroll to charge a subscription fee that is charged in connection with using the Sushiroll service. You understand that the amount charged can vary for different reasons such as promotional offers, price changes, and other things.',
          'Price changes. Sushiroll has the right to adjust prices for our services or components in any way and this is the sole and absolute policy of Sushiroll.',
          'Billing Cycle. Your membership fee will be billed automatically at the beginning of the billing period (varies depending on the membership billing plan and the Payment Method you choose).'
        ]
      },
      {
        title: 'Use of Content',
        contents: [
          'The Sushiroll Service and the Content contained therein are for personal and non-commercial purposes. During your membership period, Sushiroll provides unlimited, non-exclusive, non-transferable licenses to access and watch Content via the Sushiroll service in streaming.',
          'You are strictly prohibited from combining parts or all of Content, copying, duplicating, publishing, modifying, distributing commercially, including removing Sushiroll trademarks and re-emitting Content through application hardware or software, unless expressly permitted by Sushiroll in writing.'
        ]
      },
      {
        title: 'Applicable provision',
        contents: [
          'The applicable legal provisions are based on legal regulations in the territory of the Republic of Indonesia. These terms of use and your relationship with Sushiroll are regulated according to the laws of the Republic of Indonesia, and you agree to be bound by the jurisdiction of the courts of the Republic of Indonesia.'
        ]
      },
      {
        title: 'Changes',
        contents: [
          'Sushiroll will regularly update the site and its contents; including the Terms and Conditions of this service. We can do this change at any time without prior notice and do not bear the responsibility for notifying information and changes that we make. We advise you to always update information by checking this page and the terms of our service from time to time.'
        ]
      }
    ]
  }
};

function TNCDialog({ onClose, type }) {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div className={styles['container-tnc']}>
      <div className={styles['header-tnc']}>
        <img src={icClose} alt='close' onClick={handleCloseModal} />
      </div>
      <div className={styles['scrollable']}>
        <TextContent
          data={
            type === 'ugc'
              ? termUGCList
              : type === 'privacy' && privacyPolicyList
          }
          showLanguageOption={false}
        />
      </div>
    </div>
  );
}

export default TNCDialog;
