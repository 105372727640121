import React from 'react';
import { useSelector } from 'react-redux';
import styles from './toast.module.css';
import checkmark from '../../assets/Check-white.svg';

function ToastNotification() {
  const notification = useSelector((state) => state.notification);
  const { show, type, message } = notification;
  return (
    <div
      className={`${styles['snackbar']} ${show ? styles['show'] : ''} ${
        type === 'success' ? styles['success'] : styles['error']
      }`}
    >
      <img
        src={checkmark}
        alt='checkmark'
        className={`${styles['checkmark-icon']}`}
      />
      {message}
    </div>
  );
}

export default ToastNotification;
