import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  generateGenres,
  formatDuration,
  getThumbnail,
  generateDetailUrl
} from '../../utils';
import Loading from '../Loading/Loading';
import { CAROUSEL_ITEM_THUMBNAIL } from '../../config/Thumbnail';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

function Carousel({ data, onChannelSelected, isHomePage }) {
  const campaignData = useSelector((state) => state.campaign.data);
  const slider = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth && state.auth.user);
  const styles = (thumbnail) => ({
    background: thumbnail
      ? `url(${thumbnail}) no-repeat center / cover`
      : 'white'
  });

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    centerPadding: '30%',
    speed: 500,
    focusOnSelect: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '20%'
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '5%'
        }
      }
    ]
  };

  if (!data || !data.items) {
    return <Loading height='50vh' />;
  }

  const handleClickDetail = (item) => () => {
    const paramsEvent = {
      itemID: item.id,
      type: item.type,
      title: item.titleLocalized
    };
    analytic(analyticTypes.event, analyticEvents.CATALOGUE.SELECT_CAROUSEL, {
      params: paramsEvent,
      user
    });
    if (item.type === 'stream' || item.type === 'webview') {
      onChannelSelected(item);
    } else {
      navigate(generateDetailUrl(item));
    }
  };

  const handleClickDetailCampaign = (item) => () => {
    navigate(`campaign/${item.id}`);
  };

  if (data.items.length === 1) {
    const item = data.items[0];
    const thumbnail =
      item?.props?.heroImageWeb || getThumbnail(item, CAROUSEL_ITEM_THUMBNAIL);
    const { rating } = item;
    const { year } = item;
    const genres = generateGenres(item.tags);
    const { duration } = item;

    return (
      <div
        className='carousel-item only'
        style={styles(thumbnail)}
        onClick={handleClickDetail(item, 0)}
      >
        <div className='content big'>
          <div className='watch'>Watch Now</div>
          <div className='title'>{item.titleLocalized}</div>
          <div className='description'>
            {!!year && <span>{year}</span>}
            {!!genres && <span>{genres}</span>}
            {!!duration && <span>{formatDuration(item.duration)}</span>}
            {!!rating && <span className='rating'>{rating}</span>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='carousel-container'>
      <Slider ref={slider} {...settings}>
        {isHomePage &&
          !!campaignData?.length &&
          campaignData?.map((item, index) => {
            const thumbnail = item.props.heroImageWeb;
            return (
              <div
                key={item.id}
                className='carousel-wrapper'
                onClick={handleClickDetailCampaign(item, index)}
              >
                <div className='carousel-item' style={styles(thumbnail)}>
                  <div className='content'>
                    <div className='title'>{item.props.header}</div>
                    <div className='description' />
                  </div>
                </div>
              </div>
            );
          })}
        {data.items.map((item, index) => {
          const thumbnail = getThumbnail(item, CAROUSEL_ITEM_THUMBNAIL);
          const { rating } = item;
          const { year } = item;
          const genres = generateGenres(item.tags);
          const { duration } = item;

          return (
            <div
              key={item.id}
              className='carousel-wrapper'
              onClick={handleClickDetail(
                item,
                // eslint-disable-next-line no-unsafe-optional-chaining
                isHomePage ? campaignData?.length + index : index
              )}
            >
              <div className='carousel-item' style={styles(thumbnail)}>
                <div className='content'>
                  <div className='watch'>Watch Now</div>
                  <div className='title'>{item.titleLocalized}</div>
                  <div className='description'>
                    {!!year && <span>{year}</span>}
                    {!!genres && <span>{genres}</span>}
                    {!!duration && <span>{formatDuration(item.duration)}</span>}
                    {!!rating && <span className='rating'>{rating}</span>}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Carousel;
