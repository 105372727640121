/* eslint-disable react/no-deprecated */
/* global videojs */
import React from 'react';
import ReactDOM from 'react-dom';
import RewindButton from './RewindButton';
import FastForwardButton from './FastForwardButton';

const ButtonComponent = videojs.getComponent('Button');

class CustomControlButton extends ButtonComponent {
  constructor(player, options) {
    super(player, options);
    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);
    if (options.name === 'RewindButton') {
      this.renderComponent = RewindButton;
      this._handleClick = () => RewindButton.handleClick(player);
    } else {
      this.renderComponent = FastForwardButton;
      this._handleClick = () => FastForwardButton.handleClick(player);
    }

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    const Component = this.renderComponent;
    ReactDOM.render(<Component />, this.el());
  }

  handleClick() {
    this._handleClick();
  }
}

const isExist = Boolean(videojs.getComponent('RewindButton'));
if (!isExist) {
  videojs.registerComponent('RewindButton', CustomControlButton);
  videojs.registerComponent('FastForwardButton', CustomControlButton);
}

export default CustomControlButton;
