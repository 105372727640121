import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Section from '../Section/Section';
import { searchRelated } from '../../redux/actions/search';

const itemPerPage = 20;
function RelatedSection({ data, ...otherProps }) {
  const dispatch = useDispatch();
  const [relatedList, setRelatedList] = useState();
  useEffect(() => {
    const getRelatedData = async (genre, type, page) => {
      const relatedData = await dispatch(
        searchRelated(genre, type, page, itemPerPage)
      );
      if (relatedData && relatedData.data) {
        const list = relatedData.data.filter((item) => item.id !== data.id);
        if (list.length) {
          setRelatedList({
            ...relatedData,
            data: list
          });
        }
      }
    };

    if (data) {
      const genres = data.tags && data.tags.genres;
      const selectedGenre = genres && genres.length && genres[0];
      if (selectedGenre) {
        getRelatedData(selectedGenre, data.type, 1);
      }
    }
  }, [dispatch, data]);

  if (!relatedList || !relatedList.data.length) return null;
  return (
    <Section
      {...otherProps}
      sectionData={relatedList.data}
      topSection
      allowSeeAll={false}
    />
  );
}

export default RelatedSection;
