import React from 'react';

import styles from './styles.module.css';

function ResumePopup({ watchedLength, onResume, onStartOver }) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['content']}>
        <div className={styles['header']}>Resume Watching</div>
        <p>{`Do you want to resume from ${watchedLength}?`}</p>
      </div>
      <div className={styles['action']}>
        <button type='button' className={styles['resume']} onClick={onResume}>
          Resume
        </button>
        <button
          type='button'
          className={styles['startOver']}
          onClick={onStartOver}
        >
          Start Over
        </button>
      </div>
    </div>
  );
}

export default ResumePopup;
