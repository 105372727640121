import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

const CAMPAIGN_CAROUSEL_URL = `${BASE_URL}/catalog/v1.0/banners`;
const CAMPAIGN_DETAIL_URL = `${BASE_URL}/catalog/v1.0/banner`;

// eslint-disable-next-line no-unused-vars
export const getCampaignCarousel = () => async (dispatch, getState) => {
  dispatch({
    type: 'GET_CAMPAIGN_CAROUSEL'
  });

  try {
    const res = await axios.get(CAMPAIGN_CAROUSEL_URL, {
      headers: {
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    dispatch({
      type: 'GET_CAMPAIGN_CAROUSEL_SUCCESS',
      payload: res.data?.data
        ? res.data?.data?.map((item) => ({ ...item, type: 'campaign' }))
        : []
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_CAMPAIGN_CAROUSEL_FAILED',
      err: errorData
    });
  }
};

export const getCampaignDetailById =
  (campaignId) => async (dispatch, getState) => {
    const state = getState();
    const { data } = state.campaign;

    dispatch({
      type: 'GET_CAMPAIGN_DETAIL'
    });

    const getResCampaignDetailById = async () => {
      const res = await axios.get(`${CAMPAIGN_DETAIL_URL}/${campaignId}`, {
        headers: {
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      });
      return res;
    };

    try {
      const findCampaignId = data && data.filter((el) => el.id === campaignId);
      if (!data.length) {
        const res = await getResCampaignDetailById();
        dispatch({
          type: 'GET_CAMPAIGN_DETAIL_SUCCESS',
          payload: res.data.data
        });
      } else {
        dispatch({
          type: 'GET_CAMPAIGN_DETAIL_SUCCESS_FROM_STATE',
          payload: findCampaignId?.[0]?.props
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(showToastNotif('error', errorData.message));
      dispatch({
        type: 'GET_CAMPAIGN_DETAIL_FAILED',
        err: errorData
      });
    }
  };
