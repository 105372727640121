import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '..';
import ConsentCheckbox from './ConsentCheckbox';
import styles from './LoginDialog.module.css';
import TNCFooter from './TNCFooter';

function TNCConfirmDialog({
  onClose,
  onClickFacebook,
  onClickGoogle,
  onClickTNC,
  type,
  dialogstate
}) {
  const consentSelection = useSelector((state) => state.auth.consentSelection);

  return (
    <div className={styles['container']}>
      <h2>
        {dialogstate} With {type}
      </h2>

      {dialogstate !== 'Login' ? (
        <ConsentCheckbox language='id' />
      ) : (
        <TNCFooter dialogstate={dialogstate} onClick={onClickTNC} type={type} />
      )}
      <div className={styles['buttonWrapper']}>
        <Button
          onClick={() => {
            onClose();
            if (type === 'Google') {
              onClickGoogle();
            } else {
              onClickFacebook();
            }
          }}
          shape='rounded'
          size='large'
          width='100%'
          bgColor='sushiroll'
          disabled={consentSelection === null && dialogstate !== 'Login'}
        >
          Agree
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          shape='rounded'
          bgColor='dark'
          size='large'
          width='100%'
          textColor='dark'
        >
          Cancel
        </Button>
        {dialogstate !== 'Login' && (
          <TNCFooter
            dialogstate={dialogstate}
            onClick={onClickTNC}
            type={type}
          />
        )}
      </div>
    </div>
  );
}

export default TNCConfirmDialog;
