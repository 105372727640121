/* eslint-disable func-names */
/* eslint-disable react/display-name */
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// because in react-router-dom v6 withRouter has been removed
const withRouter = (Component) => {
  return function (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  };
};

export default withRouter;
