import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

// type = movie || series
export const searchMedia =
  (title, type, page, perPage) => async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios({
        url: `${BASE_URL}/catalog/v1.0/searches?locale=en&search=${title}&page=${page}&type=${type}&perPage=${perPage}`,
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      });

      const { data } = response.data;

      return dispatch({
        type: 'SEARCH_MEDIA_SUCCESS',
        payload: {
          type,
          data,
          meta: response.data.meta
        }
      });
    } catch (error) {
      if (error && error.response) {
        const errorDetails = generateErrorDetail(error);
        dispatch({
          type: 'SEARCH_MEDIA_ERROR',
          error: {
            type,
            error: errorDetails
          }
        });
      }
    }
  };

export const searchRelated =
  (genre, type, page, perPage) => async (dispatch, getState) => {
    const state = getState();
    const response = await axios({
      url: `${BASE_URL}/catalog/v1.0/searches?locale=en&genres=${genre}&page=${page}&type=${type}&perPage=${perPage}`,
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });
    return response.data;
  };
