/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/throttle';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { getChannelUrl, getChannelDetail } from '../../redux/actions/channel';
import { withRouter } from '../../hooks';
import ChannelPlayer from '../VideoPlayer/ChannelPlayer';
import PlaylistViewContent from './PlaylistViewContent';
import { authPopup } from '../../redux/actions/auth';
import styles from './PlaylistPageView.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

const findItemsByWidth = () => {
  if (window) {
    const width = window.innerWidth;

    if (width > 1024) {
      return 6;
    }

    if (width > 926) {
      return 5;
    }

    if (width > 742) {
      return 4;
    }

    if (width > 328) {
      return 3;
    }

    return 2;
  }
};

function PlaylistPageView({
  title,
  data,
  fetchNextData,
  completed,
  searchView,
  handleViewAll,
  allowSeeAll = true,
  noDataMessage
}) {
  const navigate = useNavigate();
  const params = useParams();
  const [maxItemsPerRow, setMax] = useState(6);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const { channelID } = params;
  const handleResize = throttle(() => {
    setMax(findItemsByWidth);
  }, 500);
  const [sessionId, setSessionId] = useState();

  useLayoutEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
      setMax(findItemsByWidth());
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleChannel = useCallback(
    (item) => async () => {
      setSessionId(uuidv4());

      if (item.contentRights === 'premium' && !userToken) {
        localStorage.setItem('gfRedirectPath', window.location.pathname);
        return dispatch(authPopup.show());
      }

      const paramsEvent = {
        channelID: item.id
      };
      analytic(analyticTypes.event, analyticEvents.CATALOGUE.SELECT_CHANNEL, {
        params: paramsEvent,
        user
      });

      item.url = await dispatch(getChannelUrl(item));
      item.type === 'webview' ? setOpenIframe(item) : setOpenPlayer(item);
    },
    [dispatch, navigate, userToken, user]
  );

  const autoplay = useCallback(async () => {
    const channel = await dispatch(getChannelDetail(channelID));
    if (!channel) {
      return navigate('/live');
    }
    await handleChannel(channel)();
  }, [channelID, dispatch, navigate, handleChannel]);

  useEffect(() => {
    channelID && autoplay();
  }, [channelID, autoplay]);

  const handleSelectChannel = useCallback(
    (item) => {
      if (item && item.interactive) return navigate(`/stream/${item.id}`);
      if (item && !item.interactive) return navigate(`/live/${item.id}`);
    },
    [navigate]
  );

  const dismissPlayer = () => {
    setOpenPlayer(false);
    setOpenIframe(false);
    return navigate(-1);
  };

  return (
    <div className={styles['wrapper']}>
      {!channelID && (
        <PlaylistViewContent
          data={data}
          title={title}
          searchView={searchView}
          allowSeeAll={allowSeeAll}
          handleViewAll={handleViewAll}
          completed={completed}
          fetchNextData={fetchNextData}
          handleSelectChannel={handleSelectChannel}
          maxItemsPerRow={maxItemsPerRow}
          noDataMessage={noDataMessage}
        />
      )}
      <ChannelPlayer
        openPlayer={openPlayer}
        openIframe={openIframe}
        dismissPlayer={dismissPlayer}
        sessionId={sessionId}
      />
    </div>
  );
}

export default withRouter(PlaylistPageView);
