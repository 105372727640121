import React from 'react';
import styles from './Loading.module.css';

function Loading({ height }) {
  const style = {
    height: height || '100%'
  };

  return (
    <div className={styles['loading-wrapper']} style={style}>
      <div className={styles['lds-ring']}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loading;
