import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuth } from '../../redux/actions/interactive';
import CommentList from './CommentList';
import CommentInput from './CommentInput';

import styles from './Comments.module.css';

function Comments({ socket }) {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);

  useEffect(() => {
    dispatch(sendAuth(socket, userToken));
  }, [socket, dispatch, userToken]);

  return (
    <div className={styles['commentWrapper']}>
      <div className={styles['titleWrapper']}>
        <h1 className={styles['title']}>Live Chat</h1>
      </div>
      <CommentList />
      <CommentInput socket={socket} />
    </div>
  );
}

export default Comments;
