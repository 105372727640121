/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import analytic, { analyticTypes } from '../../service/analytic';
import Tab from './tab';

function TabWrapper({ tabs = [], activeStyle }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const selectedTab = query.get('tab');
    // eslint-disable-next-line radix
    selectedTab && setTab(parseInt(selectedTab));
  }, [location]);

  const Component = useMemo(() => {
    return tabs[tab].component;
  }, [tab, tabs]);

  useEffect(() => {
    analytic(analyticTypes.trackPage, 'Live', { user });
  }, [user]);

  useEffect(() => {
    analytic(analyticTypes.trackPage, tabs[tab].label, { user });
  }, [user, tab, tabs]);

  return (
    <>
      {Component && (
        <>
          <Tab
            links={tabs}
            activeTab={tab}
            onTabChange={setTab}
            activeStyle={activeStyle}
          />
          <Component />
        </>
      )}
    </>
  );
}

export default TabWrapper;
