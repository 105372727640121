/* eslint-disable react/no-danger */
/* eslint-disable no-shadow */
import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { parseQuery } from '../../utils';
import styles from './TextContent.module.css';

const isHTML = (str) => {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
};
const generateContent = (contents) => {
  if (contents instanceof Array && contents?.length) {
    return contents.map((item) => {
      return generateContent(item);
    });
  }

  if (contents instanceof Object && !contents?.length) {
    if (contents?.list) {
      const title = contents?.list?.title;
      const items = contents?.list?.items;
      const order = contents?.list?.order;
      const type = contents?.list?.type;

      return (
        <React.Fragment key={uuidv4()}>
          {title && <p>{title}</p>}
          {items &&
            (order === 'ol' ? (
              <ol type={type || '1'}>
                {items?.map((list, index) => (
                  <li key={index}>{generateContent(list)}</li>
                ))}
              </ol>
            ) : (
              <ul>
                {items?.map((list, index) => (
                  <li key={index}>{generateContent(list)}</li>
                ))}
              </ul>
            ))}
        </React.Fragment>
      );
    }

    if (contents?.contentUrl) {
      const label = contents?.contentUrl?.label || '';
      const url = contents?.contentUrl?.url || '';
      return (
        <p>
          {label} : {url}
        </p>
      );
    }
  }

  if (typeof contents === 'string') {
    if (isHTML(contents)) {
      return (
        <p key={uuidv4()}>
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </p>
      );
    }
    return <p key={uuidv4()}>{contents}</p>;
  }

  return null;
};

const generateLanguages = (language = 'id', data) => {
  const languages = [];

  // eslint-disable-next-line guard-for-in
  for (const i in data) {
    languages.push(i);
  }

  const findLang = languages.findIndex((i) => i === language);
  const verifyLang = findLang >= 0 ? findLang : 0;

  const selectedLanguage = languages[verifyLang];
  const selectedData = data[selectedLanguage];
  const nextLanguage = languages[(verifyLang + 1) % languages.length];

  return {
    selectedData,
    nextLanguage,
    languages
  };
};

function TextContent({ data, showLanguageOption, mobileStyle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = parseQuery(location.search);
  const langQuery = params?.lang;
  const { selectedData, nextLanguage, languages } = generateLanguages(
    langQuery,
    data
  );

  const header = useMemo(() => selectedData?.header, [selectedData]);
  const languageTitle = useMemo(
    () => selectedData?.nextLanguage,
    [selectedData]
  );
  const contentList = useMemo(() => selectedData?.contentList, [selectedData]);

  const handleChangeLanguage = () => {
    navigate(`${location.pathname}?lang=${nextLanguage}`);
  };

  return (
    <div className={styles['wrapper']} data-mobile={mobileStyle}>
      <div className={styles['title-wrapper']}>
        {header instanceof Array ? (
          <div>
            {header?.map((item, i) => (
              <h1 key={i}>{item}</h1>
            ))}
          </div>
        ) : (
          <h1>{header}</h1>
        )}
        {showLanguageOption && languages?.length && languageTitle && (
          <span className={styles['language']} onClick={handleChangeLanguage}>
            {languageTitle}
          </span>
        )}
      </div>

      {contentList.map((data, index) => {
        const { title, contents } = data;

        return (
          <div key={index} className={styles['content-item']}>
            <h2 className={styles['content-title']}>{title}</h2>
            <div className={styles['content-data']}>
              {contents && generateContent(contents)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TextContent;
